<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 441.675 441.675"
    style="enable-background: new 0 0 441.675 441.675"
    xml:space="preserve"
		fill="currentColor"
  >
    <g>
      <path
        d="M394.439,193.801c-5.606-18.627-16.804-34.626-32.381-46.267c-15.496-11.581-35.057-17.959-55.081-17.959h-116.16
		c-55.385,0-100.445,45.06-100.445,100.445v38.614c0,23.967,19.499,43.465,43.465,43.465h195.807
		c23.48,0,45.789-10.861,59.676-29.053c14.575-19.096,18.968-43.229,12.051-66.211L394.439,193.801z M375.013,272.125
		c-10.503,13.76-27.463,21.975-45.368,21.975h-76.1l50.284-79.547c2.656-4.202,1.403-9.761-2.798-12.417
		c-4.201-2.656-9.761-1.403-12.417,2.798L232.25,294.1h-24.914l8.969-14.189c2.656-4.202,1.403-9.761-2.798-12.417
		c-4.201-2.657-9.76-1.403-12.417,2.798L186.042,294.1h-52.204c-14.042,0-25.465-11.424-25.465-25.465v-38.614
		c0-45.46,36.985-82.445,82.445-82.445h87.846l-59.31,93.827c-2.656,4.202-1.403,9.761,2.798,12.417
		c1.493,0.944,3.156,1.394,4.801,1.394c2.984,0,5.903-1.483,7.616-4.192l65.39-103.445h7.019c5.671,0,11.285,0.642,16.725,1.858
		L306.402,176.8c-2.656,4.201-1.403,9.76,2.798,12.417c1.493,0.943,3.156,1.394,4.801,1.394c2.984,0,5.903-1.483,7.616-4.192
		l19.459-30.782c3.589,1.82,7.01,3.927,10.206,6.316c12.469,9.318,21.433,22.125,25.92,37.036l6.932,23.034
		C389.369,239.412,386.043,257.673,375.013,272.125z"
      />
      <path
        d="M436.875,206.15l-6.933-23.034c-7.944-26.394-23.836-49.037-45.958-65.481S335.601,92.5,308.037,92.5h-117.22
		c-75.83,0-137.521,61.692-137.521,137.521v22.369H9c-4.971,0-9,4.029-9,9v78.786c0,4.971,4.029,9,9,9h321.559
		c35.468,0,67.946-16.326,89.106-44.791C440.824,275.919,447.097,240.114,436.875,206.15z M405.218,293.646
		c-17.729,23.85-44.941,37.529-74.66,37.529H18v-60.786h44.296c4.971,0,9-4.029,9-9v-31.369
		c0-65.904,53.617-119.521,119.521-119.521h117.22c23.667,0,46.215,7.463,65.209,21.582c18.994,14.119,32.639,33.561,39.459,56.223
		l6.933,23.034C428.204,239.795,422.947,269.795,405.218,293.646z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>