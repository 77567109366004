<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 417.649 417.649"
    style="enable-background: new 0 0 417.649 417.649"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M382.348,361.639c-19.466,0-35.302-15.836-35.302-35.302v-82.21h-45.078v82.21c0,19.465-15.836,35.302-35.302,35.302
		s-35.302-15.836-35.302-35.302v-82.21h-45.079v82.21c0,19.465-15.836,35.302-35.302,35.302s-35.302-15.836-35.302-35.302v-82.21
		h-80.38C15.836,244.126,0,228.29,0,208.825V91.313c0-19.465,15.836-35.302,35.302-35.302s35.302,15.836,35.302,35.302v82.21h45.079
		v-82.21c0-19.465,15.836-35.302,35.302-35.302s35.302,15.836,35.302,35.302v82.21h45.079v-82.21
		c0-19.465,15.836-35.302,35.302-35.302s35.302,15.836,35.302,35.302v82.21h45.078v-82.21c0-19.465,15.836-35.302,35.302-35.302
		s35.302,15.836,35.302,35.302v235.024C417.649,345.802,401.813,361.639,382.348,361.639z M292.968,226.126h63.078
		c4.971,0,9,4.029,9,9v91.21c0,9.54,7.762,17.302,17.302,17.302s17.302-7.762,17.302-17.302V91.313
		c0-9.54-7.762-17.302-17.302-17.302s-17.302,7.762-17.302,17.302v91.21c0,4.971-4.029,9-9,9h-63.078c-4.971,0-9-4.029-9-9v-91.21
		c0-9.54-7.762-17.302-17.302-17.302s-17.302,7.762-17.302,17.302v91.21c0,4.971-4.029,9-9,9h-63.079c-4.971,0-9-4.029-9-9v-91.21
		c0-9.54-7.762-17.302-17.302-17.302s-17.302,7.762-17.302,17.302v91.21c0,4.971-4.029,9-9,9H61.604c-4.971,0-9-4.029-9-9v-91.21
		c0-9.54-7.762-17.302-17.302-17.302S18,81.772,18,91.313v117.512c0,9.54,7.762,17.302,17.302,17.302h89.38c4.971,0,9,4.029,9,9
		v91.21c0,9.54,7.762,17.302,17.302,17.302s17.302-7.762,17.302-17.302v-91.21c0-4.971,4.029-9,9-9h63.079c4.971,0,9,4.029,9,9
		v91.21c0,9.54,7.762,17.302,17.302,17.302s17.302-7.762,17.302-17.302v-91.21C283.968,230.156,287.997,226.126,292.968,226.126z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>