<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 475.993 475.993"
    style="enable-background: new 0 0 475.993 475.993"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M237.997,451.276c13.39,0,24.283-10.894,24.283-24.283s-10.894-24.283-24.283-24.283s-24.283,10.894-24.283,24.283
		S224.607,451.276,237.997,451.276z M237.997,420.71c3.465,0,6.283,2.819,6.283,6.283s-2.818,6.283-6.283,6.283
		s-6.283-2.819-6.283-6.283S234.532,420.71,237.997,420.71z"
      />
      <path
        d="M286.56,229.463c1.124,0.464,2.288,0.684,3.433,0.684c3.535,0,6.889-2.096,8.322-5.566
		c1.897-4.594-0.288-9.856-4.882-11.754l-14.216-5.873v-31.182l7.343,3.033c1.124,0.464,2.288,0.684,3.433,0.684
		c3.535,0,6.889-2.096,8.322-5.566c1.897-4.594-0.288-9.856-4.882-11.754l-14.216-5.873v-50.292h9.796c4.971,0,9-4.029,9-9V62.993
		c0-4.971-4.029-9-9-9h-19.862c3.073-5.299,4.842-11.443,4.842-17.997C273.993,16.148,257.845,0,237.997,0S202,16.148,202,35.997
		c0,6.553,1.769,12.698,4.842,17.997H186.98c-4.971,0-9,4.029-9,9v34.011c0,4.971,4.029,9,9,9h9.795v16.236l-7.342-3.033
		c-4.596-1.898-9.857,0.289-11.755,4.882s0.288,9.856,4.882,11.754l14.215,5.872v31.182l-7.342-3.033
		c-4.596-1.899-9.857,0.289-11.755,4.882s0.288,9.856,4.882,11.754l14.215,5.872v31.182l-7.342-3.033
		c-4.596-1.899-9.857,0.289-11.755,4.882s0.288,9.856,4.882,11.754l14.215,5.872v31.182l-7.342-3.033
		c-4.596-1.899-9.857,0.289-11.755,4.882s0.288,9.856,4.882,11.754l14.215,5.872v50.292h-9.795c-4.971,0-9,4.029-9,9v34.011
		c0,4.971,4.029,9,9,9h13.102c-6.923,8.452-11.085,19.248-11.085,31c0,27.019,21.981,49,49,49s49-21.981,49-49
		c0-11.752-4.162-22.548-11.085-31h13.102c4.971,0,9-4.029,9-9v-34.011c0-4.971-4.029-9-9-9h-9.796v-16.237l7.343,3.033
		c1.124,0.464,2.288,0.684,3.433,0.684c3.535,0,6.889-2.096,8.322-5.566c1.897-4.594-0.288-9.856-4.882-11.754l-14.216-5.872
		v-31.181l7.343,3.033c1.124,0.464,2.288,0.684,3.433,0.684c3.535,0,6.889-2.096,8.322-5.566c1.897-4.594-0.288-9.856-4.882-11.754
		l-14.216-5.873V226.43L286.56,229.463z M220,35.997C220,26.073,228.073,18,237.997,18c9.923,0,17.996,8.073,17.996,17.997
		s-8.073,17.997-17.996,17.997C228.073,53.993,220,45.92,220,35.997z M195.98,71.993h84.033v16.011H195.98V71.993z M214.775,106.004
		h46.442v42.856l-46.442-19.185V106.004z M214.775,149.152l46.442,19.185v31.182l-46.442-19.185V149.152z M268.997,426.993
		c0,17.093-13.906,31-31,31s-31-13.907-31-31s13.906-31,31-31S268.997,409.9,268.997,426.993z M280.013,377.993H195.98v-16.011
		h9.795h64.442h9.796V377.993z M261.217,343.982h-46.442v-42.857l46.442,19.185V343.982z M261.217,300.834l-46.442-19.185v-31.182
		l46.442,19.185V300.834z M261.217,250.176l-46.442-19.185V199.81l46.442,19.185V250.176z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>