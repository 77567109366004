<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 478.072 478.072"
    style="enable-background: new 0 0 478.072 478.072"
    xml:space="preserve"
    fill="currentColor"
  >
    <path
      d="M464.301,147.426c-10.456-11.878-25.536-18.69-41.373-18.69H271.887c-37.079,0-72.166,14.214-98.798,40.025L2.737,333.874
	c-2.633,2.551-3.454,6.444-2.078,9.841c1.377,3.398,4.676,5.621,8.342,5.621h440.57c4.532,0,8.357-3.37,8.929-7.866l19.132-150.646
	C479.629,175.123,474.769,159.303,464.301,147.426z M68.468,331.336l59.246-57.423v57.423H68.468z M145.714,331.336v-74.869
	l57.951-56.168c18.388-17.822,42.617-27.637,68.225-27.637h64.883v73.386c0,3.069,1.564,5.927,4.149,7.581
	c2.584,1.654,5.833,1.878,8.622,0.59c16.233-7.492,34.552-8.623,51.583-3.185l23.619,7.542l-9.239,72.76H145.714z M434.053,185.287
	l-6.127,48.25l-60.874-60.875h55.878c3.266,0,6.254,1.35,8.413,3.8S434.465,182.047,434.053,185.287z M354.773,185.838
	l46.534,46.535c-15.38-3.893-31.401-3.612-46.534,0.746V185.838z M459.775,188.555l-18.134,142.781h-7.989l18.258-143.782
	c1.056-8.319-1.518-16.699-7.062-22.991s-13.533-9.901-21.919-9.901H271.89c-30.31,0-58.987,11.617-80.752,32.712L42.606,331.336
	H31.217l154.399-149.65c23.255-22.538,53.894-34.95,86.27-34.95h151.041c10.667,0,20.822,4.586,27.865,12.587
	C457.846,167.326,461.121,177.98,459.775,188.555z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>