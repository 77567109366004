<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 441.563 441.563"
    style="enable-background: new 0 0 441.563 441.563"
    xml:space="preserve"
		fill="currentColor"
  >
    <g>
      <path
        d="M333.764,139.87c3.022-3.946,2.274-9.595-1.672-12.618c-3.945-3.022-9.595-2.273-12.617,1.672l-42.693,55.735h-73.67
		l-42.693-55.735c-3.021-3.946-8.671-4.694-12.617-1.672c-3.946,3.022-4.694,8.672-1.672,12.618l43.539,56.84v101.68l-43.539,56.84
		c-3.022,3.946-2.274,9.595,1.672,12.618c1.633,1.251,3.557,1.856,5.466,1.856c2.705,0,5.38-1.215,7.151-3.528l42.694-55.736h73.668
		l42.694,55.736c1.771,2.313,4.446,3.528,7.151,3.528c1.909,0,3.833-0.605,5.466-1.856c3.946-3.022,4.694-8.672,1.672-12.618
		l-43.54-56.841V196.711L333.764,139.87z M272.224,292.439H207.67v-89.78h64.555V292.439z"
      />
      <path
        d="M354.891,0H202.215c-22.359,0-40.551,18.191-40.551,40.55v12.986h-13.17c-3.343,0-6.618,0.292-9.812,0.827l-20.716-20.717
		l5.567-5.567c3.515-3.515,3.515-9.213,0-12.728c-3.516-3.514-9.213-3.515-12.729,0L82.172,43.984L65.47,60.686
		c0,0-0.001,0.001-0.001,0.001l-11.93,11.93c-3.515,3.515-3.515,9.213,0,12.728c1.758,1.758,4.061,2.636,6.364,2.636
		c2.303,0,4.606-0.879,6.364-2.636l5.566-5.566L90.907,98.85c-1.084,4.475-1.673,9.142-1.673,13.946v269.506
		c0,32.676,26.584,59.26,59.261,59.26h182.903c32.677,0,59.261-26.584,59.261-59.26V35.768C390.659,16.045,374.614,0,354.891,0z
		 M179.665,40.55c0-12.434,10.116-22.55,22.551-22.55h77.479c-4.283,3.662-8.177,7.821-11.571,12.436v0
		c-10.636,14.464-27.703,23.099-45.656,23.099h-42.803V40.55z M84.563,67.05l20.676-20.676l14.583,14.583
		c-8.66,4.809-15.987,11.726-21.301,20.05L84.563,67.05z M372.659,382.302c0,22.751-18.51,41.26-41.261,41.26H148.495
		c-22.751,0-41.261-18.51-41.261-41.26V112.796c0-22.751,18.51-41.26,41.261-41.26h73.973c23.655,0,46.145-11.378,60.158-30.437
		C293.261,26.635,310.329,18,328.282,18h26.609c9.797,0,17.768,7.971,17.768,17.768V382.302z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>