<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 430.479 430.479"
    style="enable-background: new 0 0 430.479 430.479"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M242.584,101.996V50.998c0-4.971-4.029-9-9-9H80.591c-4.971,0-9,4.029-9,9v50.998c0,4.971,4.029,9,9,9h152.993
		C238.555,110.996,242.584,106.966,242.584,101.996z M224.584,92.996H89.591V59.998h134.993V92.996z"
      />
      <path
        d="M203.755,149.629c-25.732-25.733-67.603-25.731-93.335,0c-25.731,25.732-25.731,67.602,0,93.333
		c12.866,12.866,29.767,19.299,46.668,19.299c16.9,0,33.801-6.433,46.667-19.299C229.487,217.23,229.487,175.361,203.755,149.629z
		 M191.026,162.356c16.538,16.539,18.45,42.237,5.757,60.907L130.119,156.6c8.107-5.512,17.534-8.279,26.968-8.279
		C169.379,148.321,181.67,153,191.026,162.356z M123.148,230.235c-16.538-16.539-18.45-42.237-5.757-60.907l66.664,66.664
		C165.386,248.684,139.686,246.773,123.148,230.235z"
      />
      <path
        d="M381.388,43.756V9c0-4.971-4.029-9-9-9s-9,4.029-9,9v34.756c-8.786,3.568-14.999,12.19-14.999,22.241v73.497
		c0,4.971,4.029,9,9,9h5.999v181.087c0,45.71-37.188,82.899-82.899,82.899c-44.568,0-81.019-35.359-82.812-79.495h32.792v5.192
		c0,26.084,21.222,47.305,47.306,47.305s47.305-21.221,47.305-47.305V148.494h6c4.971,0,9-4.029,9-9V65.997
		c0-10.051-6.214-18.673-15-22.241V9c0-4.971-4.029-9-9-9s-9,4.029-9,9v34.756c-8.786,3.568-14.999,12.19-14.999,22.241v73.497
		c0,4.971,4.029,9,9,9h5.999v189.683c0,16.159-13.146,29.305-29.305,29.305c-16.159,0-29.306-13.146-29.306-29.305v-6.209
		c18.076-4.107,31.613-20.294,31.613-39.595V40.613C280.082,18.219,261.863,0,239.469,0H74.706
		C52.312,0,34.093,18.219,34.093,40.613v251.759c0,22.394,18.219,40.613,40.613,40.613h104.948
		c1.802,54.065,46.34,97.495,100.836,97.495c55.636,0,100.898-45.263,100.898-100.899V148.494h5.999c4.971,0,9-4.029,9-9V65.997
		C396.387,55.946,390.173,47.324,381.388,43.756z M322.079,65.997v64.497H310.08V65.997c0-3.308,2.691-5.999,5.999-5.999
		C319.388,59.998,322.079,62.689,322.079,65.997z M52.093,292.372V40.613C52.093,28.144,62.237,18,74.706,18h164.763
		c12.469,0,22.613,10.144,22.613,22.613v251.759c0,12.469-10.145,22.613-22.613,22.613H74.706
		C62.237,314.984,52.093,304.84,52.093,292.372z M378.387,65.997v64.497h-11.998V65.997c0-3.308,2.691-5.999,5.999-5.999
		S378.387,62.689,378.387,65.997z"
      />
      <path
        d="M244.083,302.985v-2.25h2.25c4.971,0,9-4.029,9-9s-4.029-9-9-9h-2.25v-2.25c0-4.971-4.029-9-9-9s-9,4.029-9,9v2.25h-2.249
		c-4.971,0-9,4.029-9,9s4.029,9,9,9h2.249v2.25c0,4.971,4.029,9,9,9S244.083,307.956,244.083,302.985z"
      />
      <path
        d="M201.885,282.736h-22.499c-4.971,0-9,4.029-9,9s4.029,9,9,9h22.499c4.971,0,9-4.029,9-9S206.855,282.736,201.885,282.736z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>