<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 478.374 478.374"
    style="enable-background: new 0 0 478.374 478.374"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M375.416,155.053c-34.844-35.697-81.461-56.06-131.262-57.338c-3.09-0.075-6.038,1.452-7.748,4.049
		c-1.71,2.598-1.957,5.895-0.654,8.719l14.311,31.014l-24.654,16.809c-2.14,1.458-3.547,3.767-3.863,6.336s0.489,5.15,2.21,7.084
		l12.442,13.979c1.638,1.84,3.958,2.928,6.419,3.011c25.819,0.87,49.955,11.586,67.963,30.173
		c18.047,18.628,27.986,43.178,27.986,69.125c0,54.792-44.581,99.369-99.378,99.369c-54.792,0-99.369-44.577-99.369-99.369
		c0-22.838,7.938-45.139,22.352-62.792c14.22-17.416,34.076-29.605,55.91-34.322c3.169-0.685,5.725-3.021,6.69-6.116
		c0.967-3.095,0.193-6.47-2.024-8.835l-10.333-11.026l18.413-16.368c2.31-2.053,3.404-5.147,2.899-8.196
		c-0.504-3.048-2.537-5.625-5.386-6.825l-19.741-8.315l2.976-12.88c0.688-2.983-0.187-6.112-2.322-8.305
		c-2.137-2.193-5.243-3.151-8.242-2.54c-42.371,8.624-80.959,31.837-108.654,65.365c-28.069,33.98-43.527,77.007-43.527,121.155
		c0,104.964,85.395,190.359,190.359,190.359c104.964,0,190.358-85.395,190.358-190.359
		C429.546,238.033,410.322,190.814,375.416,155.053z M148.227,213.838c-17.03,20.858-26.409,47.201-26.409,74.176
		c0,64.718,52.651,117.369,117.369,117.369c64.723,0,117.378-52.651,117.378-117.369c0-30.648-11.74-59.645-33.059-81.65
		c-20.355-21.011-47.348-33.507-76.369-35.442l-3.128-3.514l7.055-4.81c30.593,2.863,58.928,16.748,80.072,39.311
		c21.952,23.424,34.041,54.012,34.041,86.13c0,69.477-56.519,126-125.99,126s-125.99-56.523-125.99-126
		c0-28.984,9.567-56.226,27.667-78.781c13.814-17.215,32.004-30.499,52.344-38.505l6.349,6.774
		C179.623,184.699,161.759,197.265,148.227,213.838z M239.187,460.374c-95.039,0-172.359-77.32-172.359-172.359
		c0-39.974,13.994-78.929,39.405-109.691c22.023-26.661,51.646-46.12,84.503-55.753l-1.381,5.978
		c-0.984,4.265,1.242,8.621,5.276,10.32l13.569,5.715l-4.324,3.844c-30.168,7.616-57.459,25.15-77.052,49.566
		c-20.396,25.417-31.628,57.396-31.628,90.047c0,79.402,64.594,144,143.99,144s143.99-64.598,143.99-144
		c0-36.707-13.817-71.666-38.907-98.438c-20.134-21.484-45.979-36.079-74.273-42.244c0.658-2.077,0.57-4.384-0.4-6.486
		l-11.147-24.159c39.483,4.375,76.003,22.144,104.086,50.913c31.604,32.378,49.01,75.133,49.01,120.388
		C411.546,383.054,334.226,460.374,239.187,460.374z"
      />
      <path
        d="M185.923,92.085c3.638,3.385,9.333,3.181,12.72-0.458c3.386-3.639,3.181-9.333-0.458-12.72l-19.741-18.37l15.48,2.58
		c2.612,0.435,5.278-0.3,7.297-2.01c2.019-1.71,3.183-4.222,3.183-6.867V39.114l15.464,19.055c1.563,1.926,3.853,3.122,6.326,3.304
		c2.474,0.18,4.914-0.665,6.743-2.341l21.973-20.139l-6.014,18.177c-1.018,3.073-0.306,6.457,1.864,8.859
		c2.17,2.403,5.461,3.457,8.624,2.756l23.737-5.252l-12.422,16.563c-2.982,3.977-2.176,9.618,1.801,12.6
		c1.618,1.214,3.514,1.8,5.393,1.8c2.736,0,5.438-1.243,7.207-3.601l26.998-35.999c2.259-3.012,2.404-7.111,0.364-10.276
		c-2.039-3.165-5.832-4.722-9.509-3.912l-31.994,7.079l11.898-35.962c1.287-3.89-0.215-8.158-3.655-10.384
		c-3.439-2.224-7.948-1.847-10.97,0.922l-40.412,37.04L202.391,8.07c-2.4-2.958-6.405-4.084-9.997-2.811
		c-3.592,1.274-5.991,4.672-5.991,8.482v29.874l-34.519-5.753c-3.92-0.653-7.802,1.341-9.555,4.904
		c-1.754,3.564-0.964,7.856,1.944,10.563L185.923,92.085z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>