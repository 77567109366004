<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 462.933 462.933"
    style="enable-background: new 0 0 462.933 462.933"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M291.87,297.992c4.971,0,9-4.029,9-9s-4.029-9-9-9h-8.534v-11.121c0-4.971-4.029-9-9-9h-52.559c-4.971,0-9,4.029-9,9v2.077
		h-7.914l-16.805-48.273l35.271-44.856c1.852-2.355,2.411-5.477,1.49-8.328L172.117,6.235c-0.733-2.271-2.339-4.159-4.464-5.246
		c-2.125-1.088-4.595-1.287-6.866-0.553L28.742,43.063c-2.271,0.733-4.159,2.339-5.246,4.464c-1.088,2.125-1.287,4.594-0.554,6.866
		l52.704,163.255c0.92,2.851,3.199,5.057,6.079,5.885l55.2,15.868l10.988,31.547h-29.068c-9.149,0-17.561,3.938-23.394,10.438
		H65.597c-4.971,0-9,4.029-9,9s4.029,9,9,9h21.125l-49.997,151.73c-0.904,2.742-0.437,5.752,1.256,8.091
		c1.692,2.34,4.404,3.725,7.292,3.725c40.809,0,76.895-26.012,89.796-64.727l17.9-53.717c2.205-6.619,7.658-11.454,14.239-12.99
		c2.185,24.578,12.804,47.398,30.463,65.057l5.44,5.44c1.757,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636
		c3.515-3.515,3.515-9.213,0-12.728l-5.44-5.44c-14.381-14.381-23.131-32.89-25.159-52.853h27.537v2.078c0,4.971,4.029,9,9,9h52.559
		c4.971,0,9-4.029,9-9v-11.121h8.534c4.971,0,9-4.029,9-9s-4.029-9-9-9h-8.534v-5.941H291.87z M157.752,20.33l4.109,12.729
		L46.946,70.157l-4.109-12.729L157.752,20.33z M91.303,207.557L52.476,87.286l114.915-37.098l38.827,120.271l-33.661,42.808
		l-28.827,9.361L91.303,207.557z M155.413,237.76l16.924-5.496l13.466,38.683h-18.832L155.413,237.76z M117.992,392.515
		c-9.075,27.234-32.315,46.706-59.926,51.351l37.199-112.89h44.272c-1.485,2.423-2.717,5.039-3.645,7.822L117.992,392.515z
		 M101.196,312.976l4.873-14.787c1.821-5.527,6.955-9.241,12.775-9.241h93.934v24.028H101.196z M265.336,324.054h-34.559v-46.183
		h34.559V324.054z"
      />
      <path
        d="M436.482,369.968L334.89,300.962l101.591-69.006c4.112-2.793,5.181-8.39,2.388-12.502
		c-2.793-4.111-8.391-5.181-12.502-2.388l-112.552,76.451c-2.466,1.675-3.943,4.463-3.943,7.445s1.477,5.77,3.943,7.445
		l112.552,76.451c1.549,1.052,3.308,1.556,5.049,1.556c2.881,0,5.712-1.381,7.453-3.944
		C441.662,378.358,440.594,372.761,436.482,369.968z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>