<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 408.987 408.987"
    style="enable-background: new 0 0 408.987 408.987"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M305.299,328.853c-11.836-10.152-27.46-14.615-42.872-12.241l-78.524,12.076l-22.293-93.446
		c-5.72-24.02-18.182-45.729-36.037-62.782c-10.551-10.083-22.653-18.193-35.79-24.11c13.655-9.651,20.502-27.188,15.776-44.201
		l-9.268-33.361c-6.015-21.647-28.516-34.37-50.168-28.353c-13.048,3.625-20.714,17.189-17.09,30.238l22.099,79.545
		c0.106,0.38,42.998,179.748,42.998,179.748c-1.783,4.351-2.774,9.108-2.774,14.094c0,9.567,3.625,18.302,9.569,24.911v5.197
		c0,18.097,14.723,32.82,32.819,32.82h157.36c18.097,0,32.82-14.723,32.82-32.82v-6.8
		C323.925,353.773,317.135,339.007,305.299,328.853z M50.942,59.778c2.029-0.563,4.071-0.833,6.081-0.833
		c9.962,0,19.131,6.605,21.924,16.661l9.268,33.361c3.357,12.084-3.742,24.647-15.827,28.005l-6.321,1.756L46.377,67.855
		C45.408,64.37,47.457,60.746,50.942,59.778z M113.138,185.476c15.341,14.651,26.047,33.302,30.962,53.939l21.953,92.018
		l-2.923,0.45c-5.591-13.55-18.939-23.114-34.484-23.114c-7.412,0-14.319,2.182-20.131,5.925L71.766,160.607
		C87.183,165.702,101.292,174.155,113.138,185.476z M109.355,346.059c0-10.637,8.654-19.291,19.291-19.291
		s19.291,8.654,19.291,19.291s-8.654,19.291-19.291,19.291S109.355,356.697,109.355,346.059z M305.925,376.167
		c0,8.172-6.648,14.82-14.82,14.82h-157.36c-5.942,0-11.066-3.524-13.426-8.586c2.68,0.614,5.465,0.949,8.328,0.949
		c19.338,0,35.28-14.796,37.112-33.661l99.407-15.288c10.209-1.571,20.57,1.384,28.415,8.113s12.345,16.517,12.345,26.852V376.167z"
      />
      <path
        d="M377.903,146.943l-17.238-70.179c-13.259-53.974-67.957-87.1-121.931-73.843c-26.146,6.423-48.228,22.643-62.175,45.672
		c-13.946,23.03-18.091,50.112-11.668,76.259l17.238,70.179c6.423,26.146,22.643,48.227,45.672,62.174
		c15.953,9.661,33.849,14.618,52.032,14.618c8.067,0,16.191-0.976,24.227-2.95c26.146-6.422,48.228-22.642,62.175-45.672
		C380.181,200.172,384.326,173.09,377.903,146.943z M350.838,213.877c-11.456,18.917-29.594,32.241-51.07,37.516
		c-44.333,10.889-89.266-16.318-100.157-60.656l-17.238-70.179c-5.275-21.477-1.872-43.724,9.585-62.641
		c11.456-18.917,29.594-32.241,51.07-37.516c6.608-1.623,13.228-2.4,19.747-2.4c37.224,0,71.143,25.328,80.41,63.056l17.238,70.179
		C365.698,172.714,362.295,194.96,350.838,213.877z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>