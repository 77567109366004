<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 424.305 424.305"
    style="enable-background: new 0 0 424.305 424.305"
    xml:space="preserve"
    fill="currentColor"
  >
    <path
      d="M405.542,345.758c-1.213-1.692-2.975-2.911-4.985-3.45l-32.256-8.644c-0.949-0.254-1.915-0.329-2.867-0.272L255.701,223.66
	c-6.346-6.345-6.346-16.67,0-23.015L365.427,90.92c0.182,0.011,0.364,0.027,0.546,0.027c0.776,0,1.559-0.101,2.328-0.307
	l32.256-8.643c2.011-0.539,3.772-1.758,4.985-3.45l17.077-23.823c2.566-3.581,2.164-8.493-0.95-11.607l-40.48-40.48
	c-3.115-3.114-8.027-3.518-11.607-0.951l-23.823,17.077c-1.692,1.213-2.911,2.975-3.45,4.986l-8.643,32.256
	c-0.254,0.948-0.329,1.914-0.272,2.866L223.659,168.604c-3.073,3.074-7.16,4.767-11.507,4.767s-8.434-1.693-11.507-4.767
	L90.912,58.87c0.057-0.952-0.018-1.917-0.272-2.866l-8.643-32.256c-0.539-2.011-1.758-3.773-3.45-4.986L54.724,1.686
	c-3.579-2.567-8.491-2.165-11.607,0.951l-40.48,40.48c-3.114,3.115-3.517,8.027-0.95,11.607l17.076,23.823
	c1.212,1.692,2.975,2.912,4.985,3.45l32.257,8.643c0.77,0.207,1.552,0.307,2.328,0.307c0.183,0,0.364-0.016,0.545-0.027
	l109.725,109.725c3.074,3.074,4.767,7.161,4.767,11.507c0,4.347-1.692,8.434-4.767,11.508L58.87,333.393
	c-0.952-0.057-1.918,0.018-2.866,0.272l-32.257,8.644c-2.011,0.539-3.773,1.758-4.985,3.45L1.686,369.582
	c-2.566,3.58-2.164,8.492,0.95,11.607l40.48,40.48c1.743,1.743,4.047,2.636,6.367,2.636c1.826,0,3.663-0.555,5.24-1.685
	l23.823-17.077c1.692-1.213,2.911-2.975,3.45-4.986l8.643-32.256c0.254-0.948,0.329-1.914,0.272-2.866l109.734-109.734
	c3.073-3.074,7.16-4.767,11.507-4.767s8.434,1.693,11.507,4.767l109.734,109.734c-0.057,0.952,0.018,1.917,0.272,2.866l8.643,32.256
	c0.539,2.011,1.758,3.773,3.45,4.986l23.823,17.077c1.577,1.131,3.413,1.685,5.24,1.685c2.319,0,4.624-0.894,6.367-2.636
	l40.48-40.48c3.114-3.115,3.517-8.027,0.95-11.607L405.542,345.758z M355.66,380.842l-3.263-12.179l16.265-16.266l12.18,3.264
	L355.66,380.842z M355.66,43.464l25.181,25.181l-12.18,3.264l-16.265-16.265L355.66,43.464z M373.861,20.765l29.68,29.68
	l-6.448,8.995l-32.227-32.227L373.861,20.765z M71.908,55.644L55.644,71.909l-12.18-3.264l25.181-25.181L71.908,55.644z
	 M50.445,20.765l8.995,6.448L27.212,59.44l-6.448-8.995L50.445,20.765z M68.645,380.841L43.464,355.66l12.18-3.264l16.265,16.266
	L68.645,380.841z M50.445,403.541l-29.681-29.68l6.448-8.995l32.228,32.227L50.445,403.541z M212.153,232.935
	c-9.155,0-17.763,3.565-24.235,10.039l-108.12,108.12l-6.586-6.586l108.12-108.12c6.474-6.473,10.038-15.08,10.038-24.235
	c0-9.155-3.564-17.762-10.038-24.235L73.211,79.797l6.586-6.586l108.12,108.12c6.473,6.474,15.08,10.039,24.235,10.039
	c9.154,0,17.762-3.565,24.235-10.039l108.12-108.12l6.585,6.585L242.973,187.917c-13.363,13.363-13.363,35.107,0,48.47
	l108.121,108.121l-6.585,6.586l-108.12-108.12C229.914,236.501,221.307,232.935,212.153,232.935z M373.861,403.541l-8.996-6.448
	l32.227-32.227l6.448,8.995L373.861,403.541z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>