<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 445.944 445.944"
    style="enable-background: new 0 0 445.944 445.944"
    xml:space="preserve"
    fill="currentColor"
  >
    <path
      d="M445.898,85.856l-3.711-37.085c-0.237-2.375-1.409-4.559-3.256-6.07c-1.848-1.511-4.221-2.229-6.595-1.989l-62.355,6.241
	c-28.195,2.822-54.757,15.41-74.793,35.447l-47.907,47.908l-0.816-0.816c-2.601-2.601-6.521-3.357-9.902-1.912l-125.406,53.612
	c-1.055,0.451-2.014,1.1-2.826,1.912l-51.692,51.691c-2.696,2.696-3.401,6.798-1.759,10.239c1.505,3.154,4.681,5.125,8.12,5.125
	c0.313,0,0.629-0.017,0.946-0.05l3.609-0.38L2.636,314.646c-3.515,3.515-3.515,9.213,0,12.728l66.044,66.044
	c1.688,1.688,3.977,2.636,6.364,2.636c2.387,0,4.676-0.948,6.364-2.636l60.972-60.972l33.225,33.225l-24.244,24.244
	c-3.515,3.515-3.515,9.213,0,12.728c1.757,1.758,4.06,2.636,6.364,2.636c2.303,0,4.607-0.879,6.364-2.636l80.803-80.803
	c26.501-26.501,42.404-61.369,45.088-98.624c0.108-1.505,2.433-43.532,2.433-43.532c0.143-2.557-0.811-5.054-2.622-6.864
	l-3.431-3.431l49.955-49.955c9.721-9.721,22.608-15.828,36.286-17.197l65.237-6.529C442.785,95.212,446.393,90.802,445.898,85.856z
	 M119.829,197.059l118.211-50.536l36.178,36.178l-1.522,27.335L87.342,229.546L119.829,197.059z M75.044,374.326L21.728,321.01
	l74.275-74.275l119.178-12.545L75.044,374.326z M232.165,309.111l-43.832,43.832l-33.225-33.225l88.523-88.523l27.785-2.925
	C267.793,258.804,254.08,287.195,232.165,309.111z M292.448,110.593l7.034,20.218l-9.513,9.513l-7.034-20.218L292.448,110.593z
	 M268.796,134.246l7.034,20.218l-2.196,2.196l-13.626-13.626L268.796,134.246z M370.81,84.325
	c-17.801,1.781-34.571,9.729-47.222,22.38l-9.967,9.967l-7.035-20.219l1.328-1.328c17.107-17.106,39.786-27.854,63.858-30.264
	l53.4-5.344l1.919,19.175L370.81,84.325z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>