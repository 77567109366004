<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 463.522 463.522"
    style="enable-background: new 0 0 463.522 463.522"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M293.115,108.353h-11.056c-17.306-19.162-41.016-30.937-66.588-33.185v-6.578h14.803c2.559,0,4.997-1.089,6.704-2.995
		c1.707-1.906,2.522-4.449,2.241-6.992l-5.236-47.448c-0.504-4.561-4.357-8.013-8.946-8.013h-37.133
		c-4.588,0-8.442,3.452-8.946,8.013l-5.237,47.448c-0.281,2.543,0.535,5.086,2.242,6.992c1.708,1.906,4.146,2.995,6.704,2.995
		h14.803v6.578c-25.572,2.248-49.281,14.023-66.588,33.185h-11.056c-4.971,0-9,4.029-9,9v328.009c0,4.971,4.029,9,9,9h6.472v0.16
		c0,4.971,4.029,9,9,9h142.344c4.971,0,9-4.029,9-9v-0.16h6.472c4.971,0,9-4.029,9-9V117.353
		C302.115,112.382,298.085,108.353,293.115,108.353z M195.966,21.143h21.01l3.25,29.448h-27.511L195.966,21.143z M128.827,126.353
		h155.288v310.009H128.827V126.353z M206.471,92.769c17.698,0,34.644,5.533,48.712,15.584H157.76
		C171.828,98.302,188.773,92.769,206.471,92.769z"
      />
      <path
        d="M346.377,54.69l-59.356-18.548l59.356-18.548c4.744-1.482,7.389-6.53,5.906-11.275c-1.483-4.745-6.534-7.389-11.275-5.906
		l-86.847,27.139c-3.757,1.174-6.315,4.654-6.315,8.59s2.559,7.416,6.315,8.59l86.847,27.139c0.894,0.279,1.798,0.412,2.688,0.412
		c3.832,0,7.384-2.467,8.588-6.318C353.766,61.22,351.121,56.172,346.377,54.69z"
      />
      <path
        d="M263.916,135.865c-4.971,0-9,4.029-9,9v272.986c0,4.971,4.029,9,9,9s9-4.029,9-9V144.865
		C272.916,139.894,268.887,135.865,263.916,135.865z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>