<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 445.989 445.989"
    style="enable-background: new 0 0 445.989 445.989"
    xml:space="preserve"
    fill="currentColor"
  >
    <path
      d="M445.989,58.753c0-4.971-4.029-9-9-9h-5.999V25.754c0-4.971-4.029-9-9-9s-9,4.029-9,9v23.999h-17.998V25.754
	c0-4.971-4.029-9-9-9s-9,4.029-9,9v23.999h-6c-4.971,0-9,4.029-9,9c0,4.458,3.245,8.149,7.5,8.865v27.133
	c0,15.362,10.096,28.407,23.999,32.86v249.697c0,18.707-15.22,33.927-33.927,33.927H235.91c-18.707,0-33.927-15.22-33.927-33.927
	v-15.57h71.022v8.258c0,13.103,10.66,23.763,23.764,23.763h36.604c13.104,0,23.764-10.66,23.764-23.763v-19.68
	c8.53-7.293,14.509-17.564,16.174-29.298l6.392-45.067c1.878-13.242-2.646-26.792-12.103-36.248l-16.241-16.241l16.104-1.945
	c10.549-1.274,18.503-10.248,18.503-20.873c0-11.593-9.432-21.024-21.024-21.024h-13.611c-7.71,0-14.446,4.185-18.104,10.39
	l-17.55-38.417c-7.279-15.947-21.826-27.805-38.913-31.722c-28.188-6.46-139.373-6.46-167.561,0
	c-17.086,3.917-31.633,15.775-38.912,31.719l-17.552,38.417c-3.66-6.204-10.394-10.387-18.104-10.387H21.024
	C9.431,179.619,0,189.051,0,200.643c0,10.625,7.955,19.598,18.503,20.873l16.104,1.945l-16.241,16.241
	c-9.457,9.457-13.981,23.007-12.103,36.248l6.392,45.068c1.664,11.733,7.641,22.002,16.17,29.296v19.683
	c0,13.103,10.66,23.763,23.763,23.763h36.614c13.103,0,23.763-10.66,23.763-23.763v-8.258h71.017v15.57
	c0,28.632,23.294,51.927,51.927,51.927h123.654c28.633,0,51.927-23.294,51.927-51.927V128.419
	c15.427-3.435,26.998-17.221,26.998-33.668V67.618C442.744,66.902,445.989,63.211,445.989,58.753z M31.237,322.121h323.493
	c-3.454,12.548-15.036,21.617-28.323,21.617H59.559C46.272,343.738,34.691,334.67,31.237,322.121z M303.185,304.121l-6.385-20.438
	h63.625l-2.899,20.438H303.185z M277.942,283.683l6.385,20.438h-6.606l-6.385-20.438H277.942z M252.477,283.683l6.385,20.438H127.1
	l6.385-20.438H252.477z M108.242,304.121h-6.604l6.385-20.438h6.604L108.242,304.121z M82.781,304.121H28.439l-2.899-20.438h63.626
	L82.781,304.121z M339.137,369.996c0,3.178-2.586,5.763-5.764,5.763h-36.604c-3.178,0-5.764-2.585-5.764-5.763v-8.258h35.401
	c4.388,0,8.661-0.614,12.73-1.763V369.996z M348.307,200.643c0-1.667,1.356-3.024,3.023-3.024h13.611
	c1.668,0,3.024,1.356,3.024,3.024c0,1.528-1.145,2.819-2.661,3.002l-16.998,2.053V200.643z M113.224,137.415
	c25.345-5.808,134.173-5.808,159.518,0c3.751,0.86,7.319,2.287,10.604,4.181c-39.614-4.727-141.132-4.726-180.725,0
	C105.905,139.702,109.473,138.275,113.224,137.415z M83.917,165.081c4.24-2.087,8.809-3.618,13.632-4.545
	c31.213-6.021,159.651-6.022,190.88,0.001c4.815,0.925,9.38,2.455,13.618,4.541l21.276,46.565H62.647L83.917,165.081z M18,200.643
	c0-1.667,1.356-3.024,3.024-3.024h13.611c1.667,0,3.024,1.356,3.024,3.024v5.055l-16.998-2.053
	C19.144,203.463,18,202.172,18,200.643z M53.881,229.643h278.204l22.787,22.787c3.624,3.625,5.988,8.29,6.863,13.253H24.23
	c0.875-4.963,3.239-9.628,6.864-13.253L53.881,229.643z M94.966,369.996c0,3.178-2.585,5.763-5.763,5.763H52.589
	c-3.178,0-5.763-2.585-5.763-5.763v-10.021c4.07,1.149,8.344,1.763,12.733,1.763h35.407V369.996z M420.489,94.751
	c0,9.098-7.401,16.499-16.498,16.499c-9.098,0-16.499-7.401-16.499-16.499V67.753h32.997V94.751z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>