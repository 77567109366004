<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 359.959 359.959"
    style="enable-background: new 0 0 359.959 359.959"
    xml:space="preserve"
		fill="currentColor"
  >
    <g>
      <path
        d="M345.529,266.109h-10.252V130.712c0-4.971-4.029-9-9-9h-18.549V39.219c0-2.387-0.948-4.676-2.636-6.364L274.873,2.636
		C273.185,0.948,270.896,0,268.509,0h-57.18c-4.971,0-9,4.029-9,9v112.712h-44.697V9c0-4.971-4.029-9-9-9H91.453
		c-2.387,0-4.676,0.948-6.364,2.636L54.87,32.854c-1.688,1.688-2.636,3.977-2.636,6.364v82.493H33.683c-4.971,0-9,4.029-9,9v135.397
		H14.431c-4.971,0-9,4.029-9,9v75.851c0,4.971,4.029,9,9,9h331.098c4.971,0,9-4.029,9-9v-75.851
		C354.529,270.138,350.5,266.109,345.529,266.109z M220.329,18h44.452l24.947,24.947v78.765h-69.398V18z M70.233,42.947L95.181,18
		h44.451v103.712H70.233V42.947z M42.683,139.712h274.594v126.397H42.683V139.712z M336.529,341.959H23.431v-57.851h313.098V341.959
		z"
      />
      <path
        d="M61.233,145.636c-4.971,0-9,4.029-9,9v94.044c0,4.971,4.029,9,9,9s9-4.029,9-9v-94.044
		C70.233,149.665,66.204,145.636,61.233,145.636z"
      />
      <path
        d="M211.329,145.636c-4.971,0-9,4.029-9,9v66.983h-17.488c-1.416,0-2.069-0.86-2.368-1.582
		c-0.299-0.722-0.445-1.792,0.556-2.793c5.902-5.902,7.652-14.698,4.458-22.409c-3.194-7.711-10.651-12.694-18.998-12.694h-10.856
		v-27.505c0-4.971-4.029-9-9-9s-9,4.029-9,9v94.044c0,4.971,4.029,9,9,9s9-4.029,9-9v-48.539h10.856c1.416,0,2.069,0.86,2.368,1.582
		c0.299,0.722,0.445,1.792-0.555,2.793c-5.902,5.902-7.652,14.698-4.458,22.409c3.194,7.711,10.651,12.694,18.998,12.694h17.488
		v9.061c0,4.971,4.029,9,9,9s9-4.029,9-9v-94.044C220.329,149.665,216.3,145.636,211.329,145.636z"
      />
      <path
        d="M298.728,145.636c-4.971,0-9,4.029-9,9v94.044c0,4.971,4.029,9,9,9s9-4.029,9-9v-94.044
		C307.728,149.665,303.698,145.636,298.728,145.636z"
      />
      <path
        d="M114.907,174.134H94.958c-4.971,0-9,4.029-9,9s4.029,9,9,9h19.949c4.971,0,9-4.029,9-9S119.878,174.134,114.907,174.134z"
      />
      <path
        d="M265.002,174.134h-19.948c-4.971,0-9,4.029-9,9s4.029,9,9,9h19.948c4.971,0,9-4.029,9-9S269.973,174.134,265.002,174.134z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>