<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 431.621 431.621"
    style="enable-background: new 0 0 431.621 431.621"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M374.187,261.322l16.104-1.945c10.548-1.273,18.503-10.247,18.503-20.872c0-11.593-9.431-21.024-21.023-21.024h-13.611
		c-7.711,0-14.446,4.185-18.105,10.39l-17.549-38.417c-7.28-15.946-21.827-27.805-38.913-31.722
		c-28.188-6.46-139.373-6.46-167.561,0c-17.086,3.917-31.633,15.775-38.912,31.719l-17.552,38.417
		c-3.659-6.204-10.394-10.387-18.104-10.387H43.851c-11.593,0-21.024,9.431-21.024,21.024c0,10.625,7.955,19.599,18.503,20.872
		l16.105,1.945l-16.241,16.241c-9.457,9.457-13.981,23.007-12.103,36.248l6.392,45.068c1.664,11.733,7.642,22.002,16.17,29.296
		v19.683c0,13.103,10.66,23.763,23.763,23.763h36.614c13.103,0,23.763-10.66,23.763-23.763V399.6h160.04v8.258
		c0,13.103,10.66,23.763,23.763,23.763h36.605c13.103,0,23.763-10.66,23.763-23.763v-19.68c8.53-7.293,14.509-17.564,16.174-29.298
		l6.393-45.068c1.878-13.24-2.646-26.791-12.103-36.249L374.187,261.322z M349.235,381.6H82.386
		c-13.287,0-24.868-9.069-28.322-21.617h323.492C374.102,372.531,362.521,381.6,349.235,381.6z M300.769,321.544l6.385,20.438
		h-6.605l-6.385-20.438H300.769z M275.305,321.544l6.385,20.438H149.927l6.385-20.438H275.305z M131.07,341.983h-6.604l6.385-20.438
		h6.604L131.07,341.983z M105.609,341.983H51.267l-2.899-20.438h63.626L105.609,341.983z M326.012,341.983l-6.385-20.438h63.626
		l-2.899,20.438H326.012z M371.135,238.505c0-1.667,1.356-3.024,3.023-3.024h13.611c1.667,0,3.023,1.356,3.023,3.024
		c0,1.528-1.144,2.819-2.661,3.002l-16.997,2.053V238.505z M346.15,249.504H178.247c4.979-8.962,6.475-19.321,4.2-29.378
		c-1.368-6.046-0.337-12.287,2.904-17.572l5.191-8.467c47.078-0.778,101.774,0.659,120.715,4.312
		c4.815,0.925,9.38,2.455,13.618,4.54L346.15,249.504z M147.042,220.126c-1.368-6.047-0.337-12.288,2.903-17.572l4.492-7.327
		c4.661-0.234,9.584-0.439,14.714-0.612c-5.026,8.986-6.546,19.387-4.261,29.484c1.368,6.046,0.337,12.286-2.902,17.57l-4.803,7.835
		h-14.343C147.821,240.542,149.317,230.183,147.042,220.126z M136.051,175.277c25.345-5.808,134.176-5.809,159.519,0
		c3.751,0.86,7.318,2.287,10.603,4.181c-39.616-4.727-141.133-4.727-180.726,0C128.732,177.564,132.3,176.137,136.051,175.277z
		 M106.745,202.943c4.24-2.087,8.809-3.619,13.632-4.545c3.152-0.608,7.31-1.154,12.245-1.639c-4.12,8.474-5.24,18.037-3.136,27.34
		c1.368,6.046,0.337,12.286-2.902,17.57l-4.803,7.835H85.474L106.745,202.943z M40.827,238.505c0-1.667,1.356-3.024,3.024-3.024
		h13.611c1.667,0,3.024,1.356,3.024,3.024v5.055l-16.999-2.053C41.971,241.324,40.827,240.033,40.827,238.505z M76.709,267.504
		h278.204l22.787,22.787c3.625,3.625,5.989,8.291,6.864,13.253H47.057c0.875-4.963,3.239-9.628,6.864-13.253L76.709,267.504z
		 M117.794,407.857c0,3.178-2.585,5.763-5.763,5.763H75.417c-3.178,0-5.763-2.585-5.763-5.763v-10.021
		c4.07,1.15,8.344,1.763,12.733,1.763h35.407V407.857z M361.964,407.857c0,3.178-2.585,5.763-5.763,5.763h-36.605
		c-3.178,0-5.763-2.585-5.763-5.763V399.6h35.401c4.388,0,8.661-0.614,12.729-1.762V407.857z"
      />
      <path
        d="M94.838,56.058h244.018c3.025,0,5.849-1.52,7.514-4.046c1.666-2.526,1.95-5.72,0.758-8.5
		C335.793,17.079,309.887,0,281.127,0h-128.56c-28.759,0-54.666,17.079-66,43.511c-1.192,2.781-0.908,5.975,0.757,8.5
		C88.99,54.537,91.813,56.058,94.838,56.058z M152.567,18h128.56c16.565,0,31.869,7.563,41.92,20.058h-212.4
		C120.698,25.563,136.001,18,152.567,18z"
      />
      <path
        d="M121.722,62.185c-3.259,0-6.263,1.762-7.854,4.605L98.23,94.738c-5.856,10.466-4.023,23.7,4.458,32.18
		c5.084,5.084,11.845,7.885,19.035,7.885s13.951-2.8,19.035-7.885c8.481-8.481,10.314-21.714,4.457-32.181L129.576,66.79
		C127.985,63.947,124.981,62.185,121.722,62.185z M128.03,114.19c-3.479,3.478-9.137,3.477-12.615,0v-0.001
		c-2.81-2.81-3.417-7.194-1.477-10.662l7.784-13.912l7.784,13.912C131.447,106.995,130.84,111.38,128.03,114.19z"
      />
      <path
        d="M185.138,62.185c-3.259,0-6.263,1.762-7.854,4.605l-15.638,27.947c-5.857,10.466-4.024,23.7,4.457,32.181
		c5.084,5.084,11.844,7.885,19.035,7.885c7.19,0,13.951-2.8,19.035-7.885c8.481-8.481,10.314-21.714,4.457-32.181L192.992,66.79
		C191.401,63.946,188.397,62.185,185.138,62.185z M191.446,114.19c-3.478,3.477-9.136,3.478-12.615,0v-0.001
		c-2.81-2.81-3.417-7.194-1.477-10.663l7.784-13.911l7.784,13.911C194.864,106.995,194.256,111.38,191.446,114.19z"
      />
      <path
        d="M248.555,62.185c-3.259,0-6.263,1.762-7.854,4.605l-15.637,27.947c-5.857,10.466-4.024,23.7,4.456,32.181
		c5.085,5.084,11.845,7.885,19.036,7.885c7.19,0,13.95-2.8,19.034-7.885c8.481-8.481,10.314-21.714,4.457-32.181L256.408,66.79
		C254.818,63.946,251.814,62.185,248.555,62.185z M254.863,114.19c-3.477,3.477-9.138,3.478-12.614,0c0,0,0-0.001-0.001-0.001
		c-2.81-2.81-3.417-7.194-1.476-10.663l7.783-13.911l7.785,13.912C258.281,106.995,257.673,111.38,254.863,114.19z"
      />
      <path
        d="M319.826,66.791c-1.592-2.844-4.596-4.605-7.854-4.605s-6.263,1.762-7.854,4.605l-15.64,27.948
		c-5.855,10.466-4.022,23.699,4.458,32.18c5.085,5.084,11.845,7.885,19.035,7.885s13.95-2.8,19.034-7.885
		c8.481-8.481,10.314-21.714,4.458-32.18L319.826,66.791z M318.279,114.19c-3.477,3.478-9.137,3.478-12.613,0l-0.001,0
		c-2.81-2.81-3.417-7.195-1.478-10.663l7.785-13.912l7.784,13.912C321.697,106.995,321.089,111.38,318.279,114.19z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>