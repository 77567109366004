<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 454.756 454.756"
    style="enable-background: new 0 0 454.756 454.756"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M388.158,66.597C345.212,23.651,288.112,0,227.378,0S109.544,23.651,66.597,66.597C23.651,109.543,0,166.643,0,227.378
		s23.651,117.834,66.597,160.781c42.946,42.946,100.045,66.597,160.78,66.597s117.834-23.651,160.781-66.597
		c42.946-42.946,66.597-100.046,66.597-160.781S431.104,109.543,388.158,66.597z M227.378,436.756
		C111.926,436.756,18,342.829,18,227.378C18,111.927,111.926,18,227.378,18s209.378,93.927,209.378,209.378
		C436.755,342.829,342.829,436.756,227.378,436.756z"
      />
      <path
        d="M227.378,56.935c-93.982,0-170.443,76.46-170.443,170.443c0,93.983,76.46,170.443,170.443,170.443
		c93.983,0,170.443-76.46,170.443-170.443C397.821,133.395,321.36,56.935,227.378,56.935z M227.378,379.821
		c-84.057,0-152.443-68.386-152.443-152.443S143.32,74.935,227.378,74.935c84.058,0,152.443,68.385,152.443,152.443
		S311.435,379.821,227.378,379.821z"
      />
      <path
        d="M259.015,227.378c0-17.445-14.192-31.637-31.638-31.637c-17.445,0-31.637,14.192-31.637,31.637
		s14.192,31.637,31.637,31.637C244.823,259.015,259.015,244.823,259.015,227.378z M213.74,227.378
		c0-7.52,6.118-13.637,13.637-13.637c7.52,0,13.638,6.118,13.638,13.637c0,7.52-6.118,13.637-13.638,13.637
		C219.858,241.015,213.74,234.897,213.74,227.378z"
      />
      <path
        d="M227.378,87.886c-76.916,0-139.491,62.575-139.491,139.491s62.576,139.491,139.491,139.491
		c76.916,0,139.492-62.575,139.492-139.491S304.294,87.886,227.378,87.886z M348.869,227.378c0,13.109-2.096,25.737-5.955,37.575
		l-49.216-32.354c-2.542-1.671-2.816-4.208-2.816-5.221s0.274-3.551,2.816-5.221l49.215-32.354
		C346.774,201.64,348.869,214.268,348.869,227.378z M317.628,146.135l-52.572,26.409c-2.719,1.366-5.052,0.334-5.93-0.172
		c-0.877-0.507-2.938-2.013-3.114-5.05l-3.411-58.795C278.148,113.943,300.753,127.407,317.628,146.135z M202.149,108.528
		l-3.411,58.803c-0.176,3.036-2.236,4.543-3.113,5.049c-0.878,0.507-3.213,1.537-5.93,0.172l-52.569-26.415
		C154,127.409,176.605,113.945,202.149,108.528z M105.886,227.378c0-13.109,2.096-25.736,5.955-37.574l49.212,32.352
		c2.542,1.671,2.816,4.208,2.816,5.221s-0.274,3.55-2.816,5.221l-49.212,32.352C107.982,253.113,105.886,240.486,105.886,227.378z
		 M137.128,308.621l52.567-26.411c2.719-1.365,5.052-0.334,5.93,0.172c0.877,0.507,2.938,2.013,3.114,5.049l3.411,58.796
		C176.606,340.811,154.003,327.348,137.128,308.621z M220.321,348.659l-3.612-62.27c-0.475-8.175-4.992-15.5-12.083-19.595
		c-7.092-4.095-15.695-4.344-23.011-0.668l-55.705,27.987c-2.587-3.92-4.954-7.997-7.081-12.215l52.112-34.259
		c6.843-4.498,10.928-12.073,10.928-20.262c0-8.189-4.085-15.764-10.928-20.263l-52.112-34.258
		c2.127-4.217,4.493-8.292,7.078-12.211l55.705,27.991c3.443,1.73,7.17,2.591,10.891,2.591c4.188,0,8.366-1.09,12.121-3.258
		c7.092-4.095,11.609-11.42,12.083-19.595l3.612-62.277c2.336-0.134,4.688-0.21,7.057-0.21c2.368,0,4.719,0.076,7.054,0.21
		l3.612,62.269c0.475,8.175,4.991,15.5,12.083,19.595c3.755,2.168,7.934,3.259,12.122,3.259c3.72,0,7.447-0.86,10.889-2.59
		l55.71-27.986c2.586,3.919,4.952,7.994,7.079,12.211l-52.115,34.26c-6.843,4.499-10.928,12.073-10.928,20.262
		c0,8.189,4.085,15.764,10.928,20.262l52.116,34.261c-2.127,4.217-4.493,8.292-7.078,12.21l-55.709-27.993
		c-7.317-3.677-15.92-3.427-23.012,0.667c-7.092,4.094-11.609,11.42-12.084,19.596l-3.612,62.279
		c-2.335,0.134-4.685,0.21-7.053,0.21C225.008,348.869,222.657,348.793,220.321,348.659z M252.602,346.228l3.411-58.805
		c0.176-3.037,2.236-4.543,3.114-5.05c0.877-0.506,3.212-1.538,5.93-0.172l52.573,26.417
		C300.754,327.347,278.148,340.812,252.602,346.228z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>