<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 458.726 458.726"
    style="enable-background: new 0 0 458.726 458.726"
    xml:space="preserve"
    fill="currentColor"
  >
    <path
      d="M377.135,129.954l-54.238-54.238l33.665-33.664c1.688-1.688,2.636-3.977,2.636-6.364c0-2.387-0.948-4.676-2.636-6.364
	L329.874,2.636c-3.515-3.515-9.213-3.515-12.728,0L283.482,36.3L271.5,24.318c-3.515-3.515-9.213-3.515-12.728,0
	c-3.431,3.431-3.503,8.937-0.236,12.467L108.894,186.428c-3.53-3.268-9.036-3.196-12.467,0.235c-3.515,3.515-3.515,9.213,0,12.728
	l33.109,33.109l-19.158,19.158c-9.391,9.391-14.563,21.876-14.563,35.157c0,13.281,5.171,25.767,14.563,35.158l32.426,32.426
	c0.055,0.055,0.223,0.223,0.223,0.533c0,0.305-0.162,0.467-0.215,0.521l-61.22,61.22c-3.515,3.515-3.515,9.213,0,12.728
	l26.688,26.688c1.757,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636l66.009-66.009
	c9.391-9.391,14.562-21.875,14.559-35.155c-0.002-13.278-5.175-25.762-14.566-35.152l-32.426-32.426
	c-0.297-0.298-0.297-0.764,0-1.062l14.369-14.369l33.111,33.111c1.757,1.758,4.06,2.636,6.364,2.636
	c2.303,0,4.607-0.879,6.364-2.636c3.431-3.431,3.503-8.937,0.236-12.467l149.643-149.644c1.722,1.594,3.909,2.401,6.103,2.401
	c2.303,0,4.607-0.879,6.364-2.636C380.651,139.167,380.651,133.469,377.135,129.954z M323.51,21.728l13.96,13.96l-27.3,27.3
	l-13.961-13.96L323.51,21.728z M141.855,273.558c-7.311,7.311-7.311,19.206,0,26.517l32.426,32.426
	c12.368,12.368,12.371,32.489,0.007,44.853l-59.646,59.645l-13.96-13.96l54.855-54.856c7.307-7.307,7.304-19.199-0.007-26.509
	l-32.426-32.426c-5.991-5.991-9.29-13.957-9.29-22.43c0-8.473,3.299-16.438,9.29-22.43l19.158-19.158l13.961,13.961L141.855,273.558
	z M202.31,279.82l-80.676-80.676L271.252,49.526l80.676,80.677L202.31,279.82z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>