<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 430 430"
    style="enable-background: new 0 0 430 430"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M368.251,0H61.749c-4.971,0-9,4.029-9,9v37.608c0,4.971,4.029,9,9,9h14.452v171.377c0,2.81,1.312,5.458,3.547,7.16
		l107.739,82.054V421c0,4.971,4.029,9,9,9h36.601c4.971,0,9-4.029,9-9V316.525l108.168-82.38c2.235-1.703,3.547-4.351,3.547-7.16
		V55.608h14.449c4.971,0,9-4.029,9-9V9C377.251,4.029,373.222,0,368.251,0z M224.087,412h-18.601v-91.258h18.601V412z
		 M335.802,222.526l-105.326,80.216h-30.949L94.201,222.526V55.608h241.602V222.526z M359.251,37.608H70.749V18h288.503V37.608z"
      />
      <path
        d="M311.931,207.214c4.971,0,9-4.029,9-9V74.174c0-4.971-4.029-9-9-9s-9,4.029-9,9v124.04
		C302.931,203.185,306.96,207.214,311.931,207.214z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>