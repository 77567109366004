<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 447.665 447.665"
    style="enable-background: new 0 0 447.665 447.665"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M193,190.131c-34.762,0-63.043,28.281-63.043,63.043s28.281,63.043,63.043,63.043s63.043-28.281,63.043-63.043
		S227.762,190.131,193,190.131z M193,298.217c-24.837,0-45.043-20.207-45.043-45.043s20.207-45.043,45.043-45.043
		s45.043,20.206,45.043,45.043S217.837,298.217,193,298.217z"
      />
      <path
        d="M193,161.854c-50.354,0-91.32,40.966-91.32,91.32s40.966,91.32,91.32,91.32s91.32-40.966,91.32-91.32
		S243.354,161.854,193,161.854z M193,326.494c-40.429,0-73.32-32.891-73.32-73.32c0-40.429,32.892-73.32,73.32-73.32
		s73.32,32.891,73.32,73.32C266.32,293.603,233.429,326.494,193,326.494z"
      />
      <path
        d="M438.665,108.341h-20.06c-2.069-6.551-4.703-12.907-7.876-19.005l14.19-14.19c3.515-3.515,3.515-9.213,0-12.728
		l-38.18-38.18c-3.515-3.515-9.213-3.515-12.728,0l-14.193,14.192c-6.104-3.175-12.456-5.809-18.993-7.876V10.491
		c0-4.971-4.029-9-9-9h-54c-4.971,0-9,4.029-9,9v20.061c-6.541,2.068-12.896,4.702-19.003,7.878l-14.193-14.192
		c-3.515-3.515-9.213-3.515-12.728,0l-35.937,35.937h-20.596c-4.971,0-9,4.029-9,9v23.942c-4.57,1.013-9.095,2.22-13.546,3.616
		l-11.964-20.722c-1.194-2.067-3.16-3.576-5.465-4.193c-2.305-0.617-4.762-0.294-6.83,0.899L73.436,99.346
		c-2.067,1.194-3.576,3.16-4.193,5.465c-0.618,2.306-0.294,4.762,0.899,6.83l11.989,20.765c-3.436,3.157-6.742,6.463-9.899,9.899
		l-20.765-11.989c-2.068-1.194-4.523-1.516-6.83-0.899c-2.305,0.618-4.271,2.126-5.465,4.193l-26.631,46.127
		c-2.485,4.305-1.01,9.809,3.294,12.294l20.722,11.964c-1.395,4.451-2.603,8.977-3.616,13.547H9c-4.971,0-9,4.029-9,9v53.263
		c0,4.971,4.029,9,9,9h23.941c1.013,4.57,2.221,9.096,3.616,13.547l-20.722,11.963c-2.067,1.194-3.576,3.16-4.193,5.465
		c-0.618,2.306-0.294,4.762,0.899,6.83l26.631,46.127c2.486,4.305,7.991,5.779,12.294,3.294l20.765-11.988
		c3.156,3.435,6.463,6.742,9.899,9.899l-11.989,20.765c-1.193,2.067-1.517,4.524-0.899,6.83c0.618,2.305,2.126,4.271,4.193,5.465
		l46.127,26.631c4.304,2.485,9.809,1.011,12.294-3.294l11.963-20.722c4.452,1.396,8.977,2.604,13.547,3.616v23.941
		c0,4.971,4.029,9,9,9h53.263c4.971,0,9-4.029,9-9v-23.941c4.57-1.013,9.096-2.221,13.547-3.616l11.963,20.722
		c2.487,4.305,7.991,5.779,12.294,3.294l46.127-26.631c2.067-1.194,3.576-3.16,4.193-5.465c0.618-2.306,0.294-4.763-0.899-6.83
		l-11.989-20.765c3.436-3.157,6.743-6.464,9.899-9.899l20.765,11.988c4.306,2.486,9.81,1.01,12.294-3.294l26.631-46.127
		c1.194-2.067,1.517-4.524,0.899-6.83c-0.618-2.305-2.126-4.271-4.193-5.465l-20.722-11.963c1.395-4.451,2.603-8.977,3.616-13.547
		H377c4.971,0,9-4.029,9-9v-14.705c0.256-0.205,0.505-0.422,0.739-0.656l38.18-38.19c3.514-3.515,3.514-9.213-0.001-12.727
		l-14.188-14.189c3.17-6.09,5.803-12.443,7.874-18.998h20.061c4.971,0,9-4.029,9-9v-54
		C447.665,112.37,443.635,108.341,438.665,108.341z M368,270.805h-22.337c-4.378,0-8.121,3.15-8.868,7.463
		c-1.483,8.56-3.737,17.001-6.699,25.091c-1.504,4.108,0.163,8.702,3.951,10.889l19.323,11.156l-17.632,30.539l-19.364-11.18
		c-3.786-2.184-8.596-1.334-11.402,2.018c-5.551,6.633-11.73,12.812-18.365,18.365c-3.353,2.806-4.204,7.616-2.018,11.402
		l11.18,19.364l-30.539,17.631l-11.156-19.322c-2.188-3.789-6.783-5.455-10.889-3.951c-8.09,2.962-16.532,5.216-25.09,6.698
		c-4.313,0.748-7.464,4.49-7.464,8.868v22.337h-35.263v-22.337c0-4.378-3.15-8.121-7.464-8.868c-8.558-1.482-17-3.736-25.09-6.698
		c-4.108-1.504-8.702,0.163-10.889,3.951l-11.156,19.322L90.23,395.912l11.18-19.364c2.186-3.786,1.335-8.596-2.018-11.402
		c-6.636-5.554-12.814-11.732-18.365-18.365c-2.807-3.353-7.616-4.204-11.402-2.018l-19.364,11.18L32.63,325.405l19.322-11.156
		c3.788-2.188,5.455-6.782,3.951-10.889c-2.962-8.09-5.216-16.532-6.698-25.09c-0.748-4.313-4.49-7.464-8.868-7.464H18v-35.263
		h22.337c4.378,0,8.121-3.15,8.868-7.464c1.482-8.559,3.736-17,6.698-25.09c1.504-4.107-0.163-8.702-3.951-10.889l-19.323-11.156
		l17.632-30.539l19.364,11.18c3.787,2.186,8.595,1.334,11.402-2.018c5.554-6.635,11.732-12.814,18.365-18.365
		c3.353-2.806,4.204-7.616,2.018-11.402l-11.18-19.364l30.539-17.632l11.156,19.322c2.187,3.788,6.779,5.455,10.889,3.952
		c8.091-2.962,16.533-5.216,25.091-6.699c4.313-0.747,7.463-4.49,7.463-8.868V78.174h35.263v22.337c0,4.378,3.15,8.121,7.463,8.868
		c8.559,1.483,17.001,3.737,25.091,6.699c4.106,1.504,8.702-0.163,10.889-3.951l11.156-19.322l30.539,17.632l-11.18,19.364
		c-2.186,3.786-1.334,8.596,2.018,11.402c6.634,5.552,12.813,11.731,18.365,18.365c2.806,3.353,7.616,4.202,11.402,2.018
		l19.365-11.18l17.631,30.539l-19.323,11.156c-3.788,2.188-5.455,6.782-3.951,10.889c2.962,8.089,5.216,16.531,6.699,25.091
		c0.747,4.313,4.49,7.463,8.868,7.463H368V270.805z M429.665,162.341h-17.86c-4.125,0-7.721,2.804-8.728,6.804
		c-2.406,9.56-6.188,18.685-11.243,27.121c-2.121,3.541-1.562,8.071,1.357,10.989l12.637,12.637L386,239.725v-13.182
		c0-4.971-4.029-9-9-9h-23.942c-1.013-4.57-2.221-9.096-3.616-13.547l20.722-11.964c2.067-1.194,3.576-3.16,4.193-5.465
		c0.618-2.305,0.294-4.762-0.899-6.829l-26.631-46.127c-2.486-4.305-7.99-5.778-12.294-3.294l-20.765,11.989
		c-3.156-3.436-6.463-6.743-9.899-9.899l11.989-20.765c1.193-2.067,1.517-4.524,0.899-6.83c-0.618-2.305-2.126-4.271-4.193-5.465
		l-46.127-26.632c-2.067-1.194-4.523-1.517-6.83-0.899c-2.305,0.618-4.271,2.126-5.465,4.193l-11.964,20.722
		c-4.451-1.396-8.977-2.603-13.546-3.616V69.174c0-4.971-4.029-9-9-9h-7.211l16.845-16.845l12.636,12.636
		c2.916,2.917,7.439,3.477,10.979,1.363c8.486-5.07,17.617-8.854,27.139-11.248c4.001-1.006,6.806-4.603,6.806-8.729v-17.86h36
		v17.86c0,4.125,2.804,7.721,6.804,8.728c9.518,2.395,18.646,6.18,27.13,11.249c3.54,2.115,8.065,1.554,10.979-1.363l12.636-12.636
		l25.452,25.452l-12.636,12.636c-2.917,2.917-3.478,7.445-1.359,10.985c5.061,8.457,8.844,17.585,11.244,27.132
		c1.006,4.001,4.603,6.806,8.729,6.806h17.86V162.341z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>