<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 458.34 458.34"
    style="enable-background: new 0 0 458.34 458.34"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M366.184,238.202c0-4.971-4.029-9-9-9h-23.955l-14.358-31.429c-5.519-12.087-16.545-21.077-29.497-24.046
		c-20.275-4.646-100.246-4.646-120.523,0c-12.951,2.97-23.978,11.958-29.495,24.043l-14.36,31.432h-23.839c-4.971,0-9,4.029-9,9
		s4.029,9,9,9h14.317l-12.581,12.582c-7.311,7.309-10.808,17.782-9.355,28.016l4.554,32.106c1.217,8.575,5.44,16.128,11.494,21.661
		v12.866c0,10.762,8.755,19.517,19.517,19.517h26.083c10.762,0,19.517-8.755,19.517-19.517v-3.294h108.83v3.294
		c0,10.762,8.755,19.517,19.517,19.517h26.076c10.762,0,19.518-8.755,19.518-19.517v-12.864
		c6.055-5.533,10.279-13.087,11.496-21.663l4.554-32.106c1.45-10.233-2.047-20.707-9.354-28.015l-12.582-12.583h14.43
		C362.155,247.202,366.184,243.173,366.184,238.202z M324.16,333.138H134.066c-7.083,0-13.376-4.137-16.41-10.222h222.916
		C337.537,329.001,331.244,333.138,324.16,333.138z M287.731,295.534l2.931,9.383H167.564l2.931-9.383H287.731z M148.705,304.917
		h-34.56l-1.331-9.383h38.822L148.705,304.917z M309.521,304.917l-2.931-9.383h38.823l-1.331,9.383H309.521z M112.334,277.534
		c0.749-1.857,1.842-3.579,3.285-5.022l15.475-15.475h196.04l15.474,15.475c1.443,1.442,2.536,3.165,3.285,5.022H112.334z
		 M155.73,205.248c3.207-7.026,9.616-12.25,17.144-13.977c17.87-4.095,94.609-4.095,112.479,0
		c7.527,1.726,13.937,6.951,17.146,13.979l15.437,33.787H140.294L155.73,205.248z M156.7,354.432c0,0.836-0.681,1.517-1.517,1.517
		h-26.083c-0.836,0-1.517-0.68-1.517-1.517v-3.885c2.11,0.387,4.276,0.592,6.481,0.592H156.7V354.432z M330.641,354.432
		c0,0.836-0.681,1.517-1.518,1.517h-26.076c-0.836,0-1.517-0.68-1.517-1.517v-3.294h22.63c2.205,0,4.371-0.204,6.48-0.591V354.432z"
      />
      <path
        d="M76.46,173.525l-26.178-8.921v-26.255c0-4.971-4.029-9-9-9s-9,4.029-9,9v20.121l-20.377-6.944
		c-4.703-1.601-9.818,0.911-11.422,5.616s0.91,9.819,5.615,11.422l64.555,22c0.962,0.328,1.941,0.483,2.904,0.483
		c3.745,0,7.242-2.356,8.518-6.099C83.679,180.242,81.165,175.128,76.46,173.525z"
      />
      <path
        d="M76.46,310.726l-64.555-22c-4.703-1.602-9.818,0.911-11.422,5.616s0.91,9.819,5.615,11.422l64.555,22
		c0.962,0.328,1.941,0.483,2.904,0.483c3.745,0,7.242-2.356,8.518-6.099C83.679,317.443,81.165,312.329,76.46,310.726z"
      />
      <path
        d="M76.46,338.166l-64.555-22c-4.703-1.603-9.818,0.911-11.422,5.616s0.91,9.819,5.615,11.422l64.555,22
		c0.962,0.328,1.941,0.483,2.904,0.483c3.745,0,7.242-2.356,8.518-6.099C83.679,344.883,81.165,339.769,76.46,338.166z"
      />
      <path
        d="M76.46,365.606l-64.555-22c-4.703-1.602-9.818,0.911-11.422,5.616c-1.604,4.705,0.91,9.819,5.615,11.422l26.184,8.923
		v25.08c0,4.971,4.029,9,9,9s9-4.029,9-9v-18.946l20.371,6.942c0.962,0.328,1.941,0.483,2.904,0.483
		c3.745,0,7.242-2.356,8.518-6.099C83.679,372.324,81.165,367.21,76.46,365.606z"
      />
      <path
        d="M76.46,200.965l-64.555-22c-4.703-1.603-9.818,0.911-11.422,5.616c-1.604,4.705,0.91,9.819,5.615,11.422l64.555,22
		c0.962,0.328,1.941,0.483,2.904,0.483c3.745,0,7.242-2.356,8.518-6.099C83.679,207.682,81.165,202.569,76.46,200.965z"
      />
      <path
        d="M76.46,228.405l-64.555-22c-4.703-1.602-9.818,0.911-11.422,5.616s0.91,9.819,5.615,11.422l64.555,22
		c0.962,0.328,1.941,0.483,2.904,0.483c3.745,0,7.242-2.356,8.518-6.099C83.679,235.122,81.165,230.009,76.46,228.405z"
      />
      <path
        d="M76.46,255.845l-64.555-22c-4.703-1.601-9.818,0.911-11.422,5.616s0.91,9.819,5.615,11.422l64.555,22
		c0.962,0.328,1.941,0.483,2.904,0.483c3.745,0,7.242-2.356,8.518-6.099C83.679,262.563,81.165,257.449,76.46,255.845z"
      />
      <path
        d="M76.46,283.285l-64.555-22c-4.703-1.602-9.818,0.911-11.422,5.616c-1.604,4.705,0.91,9.819,5.615,11.422l64.555,22
		c0.962,0.328,1.941,0.483,2.904,0.483c3.745,0,7.242-2.356,8.518-6.099C83.679,290.003,81.165,284.889,76.46,283.285z"
      />
      <path
        d="M381.88,173.525c-4.705,1.604-7.219,6.717-5.615,11.422c1.275,3.743,4.772,6.099,8.518,6.099
		c0.963,0,1.942-0.156,2.904-0.483l64.555-22c4.705-1.604,7.219-6.717,5.615-11.422c-1.603-4.705-6.72-7.217-11.422-5.616
		l-20.378,6.945v-20.122c0-4.971-4.029-9-9-9s-9,4.029-9,9v26.256L381.88,173.525z"
      />
      <path
        d="M446.435,288.726l-64.555,22c-4.705,1.604-7.219,6.717-5.615,11.422c1.275,3.743,4.772,6.099,8.518,6.099
		c0.963,0,1.942-0.156,2.904-0.483l64.555-22c4.705-1.604,7.219-6.717,5.615-11.422
		C456.254,289.637,451.137,287.124,446.435,288.726z"
      />
      <path
        d="M446.435,316.166l-64.555,22c-4.705,1.604-7.219,6.717-5.615,11.422c1.275,3.743,4.772,6.099,8.518,6.099
		c0.963,0,1.942-0.156,2.904-0.483l64.555-22c4.705-1.604,7.219-6.717,5.615-11.422
		C456.254,317.076,451.137,314.563,446.435,316.166z"
      />
      <path
        d="M446.435,343.606l-64.555,22c-4.705,1.604-7.219,6.717-5.615,11.422c1.275,3.743,4.772,6.099,8.518,6.099
		c0.963,0,1.942-0.156,2.904-0.483l20.37-6.942v18.946c0,4.971,4.029,9,9,9s9-4.029,9-9v-25.08l26.185-8.924
		c4.705-1.604,7.219-6.717,5.615-11.422C456.254,344.517,451.137,342.004,446.435,343.606z"
      />
      <path
        d="M446.435,178.965l-64.555,22c-4.705,1.604-7.219,6.717-5.615,11.422c1.275,3.743,4.772,6.099,8.518,6.099
		c0.963,0,1.942-0.156,2.904-0.483l64.555-22c4.705-1.604,7.219-6.717,5.615-11.422
		C456.254,179.876,451.137,177.362,446.435,178.965z"
      />
      <path
        d="M446.435,206.405l-64.555,22c-4.705,1.604-7.219,6.717-5.615,11.422c1.275,3.743,4.772,6.099,8.518,6.099
		c0.963,0,1.942-0.156,2.904-0.483l64.555-22c4.705-1.604,7.219-6.717,5.615-11.422
		C456.254,207.316,451.137,204.803,446.435,206.405z"
      />
      <path
        d="M446.435,233.845l-64.555,22c-4.705,1.604-7.219,6.717-5.615,11.422c1.275,3.743,4.772,6.099,8.518,6.099
		c0.963,0,1.942-0.156,2.904-0.483l64.555-22c4.705-1.604,7.219-6.717,5.615-11.422
		C456.254,234.756,451.137,232.244,446.435,233.845z"
      />
      <path
        d="M446.435,261.285l-64.555,22c-4.705,1.604-7.219,6.717-5.615,11.422c1.275,3.743,4.772,6.099,8.518,6.099
		c0.963,0,1.942-0.156,2.904-0.483l64.555-22c4.705-1.604,7.219-6.717,5.615-11.422
		C456.254,262.196,451.137,259.683,446.435,261.285z"
      />
      <path
        d="M365.229,104.969h18.942c4.971,0,9-4.029,9-9s-4.029-9-9-9h-25.076l-8.921-26.177c-1.604-4.705-6.719-7.217-11.422-5.616
		c-4.705,1.604-7.219,6.717-5.615,11.422l22,64.555c1.275,3.743,4.772,6.099,8.518,6.099c0.963,0,1.942-0.156,2.904-0.483
		c4.705-1.604,7.219-6.717,5.615-11.422L365.229,104.969z"
      />
      <path
        d="M327.696,131.153c1.275,3.743,4.772,6.099,8.518,6.099c0.963,0,1.942-0.156,2.904-0.483
		c4.705-1.604,7.219-6.717,5.615-11.422l-22-64.555c-1.604-4.705-6.719-7.217-11.422-5.616c-4.705,1.604-7.219,6.717-5.615,11.422
		L327.696,131.153z"
      />
      <path
        d="M300.255,131.153c1.275,3.743,4.772,6.099,8.518,6.099c0.963,0,1.942-0.156,2.904-0.483
		c4.705-1.604,7.219-6.717,5.615-11.422l-22-64.555c-1.604-4.705-6.718-7.217-11.422-5.616c-4.705,1.604-7.219,6.717-5.615,11.422
		L300.255,131.153z"
      />
      <path
        d="M163.055,131.153c1.275,3.743,4.772,6.099,8.518,6.099c0.963,0,1.942-0.156,2.904-0.483
		c4.705-1.604,7.219-6.717,5.615-11.422l-22-64.555c-1.604-4.705-6.718-7.217-11.422-5.616c-4.705,1.604-7.219,6.717-5.615,11.422
		L163.055,131.153z"
      />
      <path
        d="M135.615,131.153c1.275,3.743,4.772,6.099,8.518,6.099c0.963,0,1.942-0.156,2.904-0.483
		c4.705-1.604,7.219-6.717,5.615-11.422l-22-64.555c-1.604-4.705-6.719-7.217-11.422-5.616c-4.705,1.604-7.219,6.717-5.615,11.422
		L135.615,131.153z"
      />
      <path
        d="M272.816,131.153c1.275,3.743,4.772,6.099,8.518,6.099c0.963,0,1.942-0.156,2.904-0.483
		c4.705-1.604,7.219-6.717,5.615-11.422l-22-64.555c-1.604-4.705-6.719-7.217-11.422-5.616c-4.705,1.604-7.219,6.717-5.615,11.422
		L272.816,131.153z"
      />
      <path
        d="M245.375,131.153c1.275,3.743,4.772,6.099,8.518,6.099c0.963,0,1.942-0.156,2.904-0.483
		c4.705-1.604,7.219-6.717,5.615-11.422l-22-64.555c-1.604-4.705-6.72-7.217-11.422-5.616c-4.705,1.604-7.219,6.717-5.615,11.422
		L245.375,131.153z"
      />
      <path
        d="M217.935,131.153c1.275,3.743,4.772,6.099,8.518,6.099c0.963,0,1.942-0.156,2.904-0.483
		c4.705-1.604,7.219-6.717,5.615-11.422l-22-64.555c-1.604-4.705-6.718-7.217-11.422-5.616c-4.705,1.604-7.219,6.717-5.615,11.422
		L217.935,131.153z"
      />
      <path
        d="M190.494,131.153c1.275,3.743,4.772,6.099,8.518,6.099c0.963,0,1.942-0.156,2.904-0.483
		c4.705-1.604,7.219-6.717,5.615-11.422l-22-64.555c-1.604-4.705-6.719-7.217-11.422-5.616c-4.705,1.604-7.219,6.717-5.615,11.422
		L190.494,131.153z"
      />
      <path
        d="M74.17,104.969h25.08l8.923,26.184c1.275,3.743,4.772,6.099,8.518,6.099c0.963,0,1.942-0.156,2.904-0.483
		c4.705-1.604,7.219-6.717,5.615-11.422l-22-64.555c-1.604-4.705-6.72-7.217-11.422-5.616c-4.705,1.604-7.219,6.717-5.615,11.422
		l6.942,20.371H74.17c-4.971,0-9,4.029-9,9S69.2,104.969,74.17,104.969z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>