<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 422.948 422.948"
    style="enable-background: new 0 0 422.948 422.948"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M377.728,227.722h-22.089c0.228-1.265,0.354-2.565,0.354-3.895v-16.669c7.443-6.523,12.648-15.604,14.116-25.951
		l5.583-39.364c1.69-11.918-2.382-24.115-10.894-32.626l-12.53-12.53l11.742-1.418c9.785-1.182,17.163-9.506,17.163-19.362
		c0-10.754-8.749-19.502-19.502-19.502h-11.889c-6.397,0-12.067,3.11-15.626,7.883l-14.48-31.697
		c-6.505-14.248-19.503-24.844-34.771-28.344c-24.706-5.662-122.157-5.662-146.864,0c-15.267,3.5-28.265,14.096-34.769,28.342
		L88.792,64.286c-3.559-4.772-9.229-7.881-15.625-7.881H61.277c-10.753,0-19.502,8.749-19.502,19.502
		c0,9.856,7.379,18.179,17.164,19.362l11.741,1.418l-12.531,12.53c-8.511,8.511-12.583,20.708-10.894,32.626l5.584,39.364
		c1.467,10.346,6.671,19.426,14.112,25.948v16.672c0,1.33,0.126,2.63,0.354,3.895H45.221c-4.971,0-9,4.029-9,9v35.028
		c0,4.971,4.029,9,9,9h124.255v25.68c0,4.971,4.029,9,9,9h9.836v98.518c0,4.971,4.029,9,9,9s9-4.029,9-9V315.43h10.33v98.518
		c0,4.971,4.029,9,9,9s9-4.029,9-9V315.43h9.831c4.971,0,9-4.029,9-9v-25.68h124.255c4.971,0,9-4.029,9-9v-35.028
		C386.728,231.751,382.698,227.722,377.728,227.722z M337.992,223.827c0,2.147-1.747,3.895-3.895,3.895h-31.973
		c-2.147,0-3.895-1.747-3.895-3.895v-6.074h29.782c3.423,0,6.765-0.422,9.979-1.208V223.827z M71.743,183.15h279.464
		c-3.34,9.72-12.62,16.603-23.194,16.603H94.936C84.361,199.753,75.082,192.871,71.743,183.15z M90.448,102.376H332.5l19.57,19.57
		c2.672,2.672,4.514,6.028,5.398,9.631H65.481c0.884-3.604,2.726-6.96,5.398-9.631L90.448,102.376z M308.567,165.15l-4.865-15.574
		h52.712l-2.209,15.574H308.567z M284.843,149.577l4.865,15.574H133.24l4.866-15.574H284.843z M119.247,149.577l-4.866,15.574
		H68.744l-2.209-15.574H119.247z M142.723,217.753H280.23v6.074c0,1.33,0.126,2.63,0.354,3.895H142.369
		c0.228-1.265,0.354-2.565,0.354-3.895V217.753z M348.28,75.907c0-0.828,0.674-1.502,1.502-1.502h11.889
		c0.828,0,1.502,0.674,1.502,1.502c0,0.759-0.568,1.4-1.321,1.491l-13.571,1.639V75.907z M119.647,40.066
		c4.193-9.187,12.574-16.019,22.416-18.275c22.057-5.055,116.767-5.054,138.821,0c9.843,2.257,18.224,9.088,22.418,18.277
		l20.244,44.307H99.405L119.647,40.066z M59.775,75.907c0-0.828,0.674-1.502,1.502-1.502h11.889c0.829,0,1.503,0.674,1.503,1.502
		v3.131l-13.571-1.639C60.344,77.308,59.775,76.667,59.775,75.907z M84.953,223.827v-7.283c3.215,0.786,6.559,1.209,9.982,1.209
		h29.787v6.074c0,2.147-1.747,3.895-3.895,3.895H88.849C86.7,227.722,84.953,225.975,84.953,223.827z M235.473,297.43h-47.997
		v-16.68h47.997V297.43z M368.728,262.75H54.221v-17.028h314.507V262.75z"
      />
      <path
        d="M337.523,293.605c-1.607-2.785-4.578-4.5-7.794-4.5c-3.215,0-6.187,1.715-7.794,4.5l-34.281,59.375
		c-1.608,2.785-1.608,6.215,0,9c1.607,2.785,4.578,4.5,7.794,4.5h8.782v47.468c0,4.971,4.029,9,9,9s9-4.029,9-9v-56.468
		c0-4.971-4.029-9-9-9h-2.193l18.692-32.375l18.691,32.375h-2.192c-4.971,0-9,4.029-9,9v56.468c0,4.971,4.029,9,9,9s9-4.029,9-9
		v-47.468h8.781c3.216,0,6.187-1.715,7.794-4.5c1.608-2.785,1.608-6.215,0-9L337.523,293.605z"
      />
      <path
        d="M102.035,293.605c-1.607-2.785-4.578-4.5-7.794-4.5s-6.187,1.715-7.794,4.5l-34.28,59.375c-1.608,2.785-1.608,6.215,0,9
		c1.607,2.785,4.578,4.5,7.794,4.5h8.781v47.468c0,4.971,4.029,9,9,9s9-4.029,9-9v-56.468c0-4.971-4.029-9-9-9H75.55l18.691-32.375
		l18.691,32.375h-2.192c-4.971,0-9,4.029-9,9v56.468c0,4.971,4.029,9,9,9s9-4.029,9-9v-47.468h8.781c3.216,0,6.187-1.715,7.794-4.5
		c1.608-2.785,1.608-6.215,0-9L102.035,293.605z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>