<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 455.568 455.568"
    style="enable-background: new 0 0 455.568 455.568"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M56.468,126.213c0,3.215,1.716,6.187,4.5,7.794c1.393,0.804,2.946,1.206,4.5,1.206s3.107-0.402,4.5-1.206l59.376-34.281
		c2.784-1.608,4.5-4.579,4.5-7.794s-1.716-6.187-4.5-7.794L69.968,49.858c-2.785-1.608-6.215-1.608-9,0
		c-2.784,1.608-4.5,4.579-4.5,7.794v8.781H9c-4.971,0-9,4.03-9,9v32.999c0,4.97,4.029,9,9,9h47.468V126.213z M18,99.432V84.433
		h47.468c4.971,0,9-4.03,9-9V73.24l32.376,18.692l-32.376,18.692v-2.193c0-4.97-4.029-9-9-9H18z"
      />
      <path
        d="M446.568,338.136h-47.468v-8.781c0-3.215-1.716-6.187-4.5-7.794c-2.785-1.607-6.215-1.607-9,0l-59.376,34.281
		c-2.784,1.608-4.5,4.579-4.5,7.794s1.716,6.187,4.5,7.794l59.376,34.28c1.393,0.804,2.946,1.206,4.5,1.206s3.107-0.402,4.5-1.206
		c2.784-1.608,4.5-4.579,4.5-7.794v-8.781h47.468c4.971,0,9-4.029,9-9v-32.999C455.568,342.166,451.539,338.136,446.568,338.136z
		 M437.568,371.135h-47.468c-4.971,0-9,4.029-9,9v2.193l-32.376-18.692l32.376-18.692v2.193c0,4.971,4.029,9,9,9h47.468V371.135z"
      />
      <path
        d="M331.275,294.534c4.971,0,9-4.029,9-9v-33.75h64.5c4.971,0,9-4.029,9-9v-30c0-4.971-4.029-9-9-9h-64.5v-33.75
		c0-4.971-4.029-9-9-9H311.78V56.195c0-27.791-22.609-50.4-50.4-50.4h-67.191c-27.791,0-50.4,22.609-50.4,50.4v104.839h-19.503
		c-4.971,0-9,4.029-9,9v33.75H50.796c-4.971,0-9,4.029-9,9v30c0,4.971,4.029,9,9,9h64.489v33.75c0,4.971,4.029,9,9,9h19.503v104.839
		c0,27.791,22.609,50.4,50.4,50.4h67.191c27.791,0,50.4-22.609,50.4-50.4V294.534H331.275z M395.775,221.784v12h-55.5v-12H395.775z
		 M59.796,233.784v-12h55.489v12H59.796z M133.285,276.534v-97.5h10.503v97.5H133.285z M198.83,406.54
		c0.018-0.03,0.035-0.061,0.053-0.092c8.26-14.664,8.26-32.884-0.001-47.549c-0.008-0.013-0.016-0.027-0.024-0.042
		c-5.169-9.2-5.17-20.619-0.001-29.82c0.008-0.014,0.016-0.028,0.023-0.042c8.263-14.666,8.263-32.886,0-47.551
		c-0.01-0.017-0.02-0.035-0.03-0.053c-5.153-9.184-5.159-20.575-0.017-29.764c0.016-0.027,0.031-0.055,0.046-0.082
		c8.264-14.667,8.263-32.889-0.002-47.556c-5.193-9.216-5.193-20.667,0-29.884c8.256-14.65,8.266-32.847,0.029-47.506
		c-0.011-0.019-0.021-0.038-0.032-0.056c-5.191-9.216-5.191-20.666,0.005-29.889c8.249-14.641,8.262-32.827,0.041-47.479
		c-0.015-0.026-0.029-0.052-0.044-0.078c-4.362-7.742-5.044-17.055-2.079-25.301h18.079c-2.486,11.473-0.813,23.703,5.066,34.137
		c0.014,0.024,0.027,0.049,0.042,0.073c5.153,9.204,5.141,20.62-0.043,29.821c-8.253,14.648-8.262,32.845-0.028,47.502
		c0.011,0.02,0.021,0.039,0.032,0.058c5.192,9.216,5.192,20.666-0.001,29.883c-8.265,14.667-8.265,32.89,0,47.558
		c5.193,9.217,5.193,20.667,0.001,29.883c-0.01,0.016-0.019,0.033-0.028,0.05c-8.224,14.631-8.23,32.791-0.021,47.428
		c0.017,0.031,0.033,0.061,0.051,0.092c5.186,9.205,5.191,20.639,0.018,29.85c-0.008,0.014-0.016,0.028-0.023,0.042
		c-8.263,14.666-8.263,32.886,0,47.551c0.008,0.014,0.017,0.029,0.025,0.044c5.173,9.209,5.167,20.642-0.019,29.846
		c-0.015,0.027-0.031,0.057-0.048,0.086c-5.849,10.421-7.508,22.625-5.024,34.074h-18.077
		C193.828,423.528,194.504,414.222,198.83,406.54z M235.62,406.467c0.016-0.027,0.031-0.056,0.048-0.085
		c8.224-14.654,8.211-32.841-0.039-47.483c-0.007-0.013-0.016-0.028-0.024-0.043c-5.167-9.197-5.168-20.613-0.003-29.812
		c0.01-0.016,0.019-0.032,0.028-0.049c8.245-14.637,8.26-32.816,0.046-47.465c-0.018-0.032-0.035-0.064-0.053-0.095
		c-5.192-9.216-5.192-20.665,0-29.881c0.009-0.016,0.019-0.032,0.027-0.049c8.241-14.659,8.231-32.86-0.025-47.514
		c-5.192-9.217-5.192-20.668,0.001-29.885c8.256-14.651,8.265-32.851,0.027-47.509c-0.01-0.018-0.021-0.035-0.03-0.053
		c-5.191-9.216-5.191-20.666,0.004-29.889c8.264-14.666,8.264-32.888,0-47.554c-0.014-0.025-0.027-0.049-0.042-0.074
		c-4.327-7.728-4.997-17.01-2.04-25.232h18.08c-2.48,11.446-0.82,23.645,5.025,34.063c0.015,0.026,0.029,0.052,0.044,0.078
		c5.177,9.188,5.192,20.597,0.048,29.799c-0.018,0.03-0.035,0.06-0.052,0.091c-8.253,14.648-8.262,32.845-0.028,47.502
		c0.011,0.02,0.021,0.039,0.032,0.058c5.192,9.216,5.192,20.667-0.001,29.883c-8.265,14.667-8.265,32.89,0,47.559
		c5.193,9.216,5.193,20.666,0.001,29.882c-0.01,0.018-0.021,0.036-0.03,0.054c-8.236,14.658-8.228,32.855,0.026,47.505
		c0.01,0.018,0.02,0.036,0.03,0.054c5.16,9.196,5.159,20.606-0.005,29.802c-0.009,0.016-0.018,0.031-0.026,0.047
		c-8.262,14.665-8.262,32.885-0.001,47.551c0.008,0.013,0.017,0.028,0.025,0.043c5.174,9.209,5.169,20.643,0.025,29.775
		c-0.018,0.031-0.035,0.062-0.053,0.092c-5.878,10.436-7.55,22.667-5.062,34.141h-18.078
		C230.577,423.526,231.258,414.21,235.62,406.467z M161.788,399.373V56.195c0-11.696,6.231-21.963,15.546-27.66
		c-1.156,10.015,0.774,20.333,5.818,29.323c0.015,0.026,0.029,0.052,0.044,0.078c5.192,9.216,5.192,20.666-0.003,29.889
		c-8.254,14.648-8.264,32.846-0.029,47.503c0.011,0.02,0.021,0.039,0.032,0.058c5.192,9.216,5.192,20.667-0.001,29.883
		c-8.265,14.667-8.265,32.891,0.001,47.558c5.178,9.189,5.193,20.601,0.046,29.803c-0.018,0.029-0.034,0.058-0.05,0.087
		c-8.263,14.666-8.263,32.886,0,47.552c0.01,0.018,0.02,0.036,0.03,0.054c5.16,9.196,5.159,20.606-0.005,29.801
		c-0.009,0.016-0.019,0.032-0.027,0.048c-8.261,14.666-8.261,32.886,0.002,47.551c0.007,0.012,0.015,0.026,0.022,0.04
		c5.176,9.21,5.17,20.646,0.026,29.777c-0.018,0.031-0.035,0.062-0.053,0.092c-5.074,9.008-7.017,19.356-5.857,29.399
		C168.018,421.334,161.788,411.068,161.788,399.373z M293.78,399.373c0,14.917-10.136,27.504-23.879,31.255
		c-2.511-7.953-1.701-16.761,2.426-24.088c0.018-0.031,0.035-0.062,0.053-0.092c8.26-14.666,8.259-32.885-0.003-47.549
		c-0.007-0.013-0.016-0.028-0.024-0.042c-5.167-9.199-5.168-20.616-0.002-29.814c0.009-0.016,0.018-0.032,0.026-0.047
		c8.263-14.666,8.263-32.886,0-47.551c-0.01-0.017-0.02-0.035-0.03-0.053c-5.167-9.208-5.159-20.637,0.024-29.838
		c0.011-0.018,0.021-0.036,0.03-0.054c8.237-14.658,8.229-32.858-0.027-47.509c-5.193-9.216-5.193-20.667,0-29.884
		c8.256-14.65,8.266-32.847,0.029-47.506c-0.011-0.019-0.021-0.038-0.032-0.056c-5.176-9.187-5.191-20.595-0.048-29.796
		c0.018-0.031,0.036-0.062,0.053-0.093c8.249-14.641,8.262-32.827,0.041-47.479c-0.015-0.026-0.029-0.052-0.044-0.078
		c-4.16-7.385-4.978-16.201-2.472-24.156c13.743,3.752,23.879,16.339,23.879,31.255V399.373z M322.275,179.034v97.5H311.78v-97.5
		H322.275z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>