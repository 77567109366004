<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 466.478 466.478"
    style="enable-background: new 0 0 466.478 466.478"
    xml:space="preserve"
    fill="currentColor"
  >
    <path
      d="M343.131,131.994h36.701c10.559,0,19.148-8.59,19.148-19.148V19.148C398.981,8.59,390.391,0,379.833,0h-36.701
	c-10.558,0-19.147,8.59-19.147,19.148v37.854h-59.002c-3.927-13.836-16.666-24.003-31.743-24.003s-27.816,10.168-31.743,24.003
	h-59.002V19.148C142.494,8.59,133.904,0,123.345,0H86.644C76.086,0,67.497,8.59,67.497,19.148v93.698
	c0,10.558,8.59,19.148,19.147,19.148h36.701c10.559,0,19.148-8.59,19.148-19.148V75.002h59.005
	c3.117,10.968,11.772,19.622,22.741,22.737v149.85c-13.994,5.085-24,21.817-24,42.237c0,20.419,10.006,37.151,24,42.236v36.678
	c-10.971,3.115-19.627,11.771-22.743,22.743h-59.003v-37.851c0-10.558-8.59-19.148-19.148-19.148H86.644
	c-10.558,0-19.147,8.59-19.147,19.148v93.698c0,10.558,8.59,19.148,19.147,19.148h36.701c10.559,0,19.148-8.59,19.148-19.148
	v-37.848h59.004c3.929,13.833,16.666,23.998,31.741,23.998s27.812-10.165,31.741-23.998h59.004v37.848
	c0,10.558,8.59,19.148,19.147,19.148h36.701c10.559,0,19.148-8.59,19.148-19.148v-93.698c0-10.558-8.59-19.148-19.148-19.148
	h-36.701c-10.558,0-19.147,8.59-19.147,19.148v37.851H264.98c-3.115-10.971-11.771-19.626-22.741-22.742v-36.68
	c13.993-5.085,23.998-21.817,23.998-42.235c0-20.419-10.005-37.15-23.998-42.236V97.738c10.968-3.115,19.623-11.768,22.739-22.736
	h59.005v37.844C323.984,123.404,332.574,131.994,343.131,131.994z M124.494,112.846c0,0.633-0.516,1.148-1.148,1.148H86.644
	c-0.633,0-1.147-0.515-1.147-1.148V19.148c0-0.633,0.515-1.148,1.147-1.148h36.701c0.633,0,1.148,0.515,1.148,1.148V112.846z
	 M124.494,447.33c0,0.633-0.516,1.148-1.148,1.148H86.644c-0.633,0-1.147-0.515-1.147-1.148v-93.698
	c0-0.633,0.515-1.148,1.147-1.148h36.701c0.633,0,1.148,0.515,1.148,1.148V447.33z M341.984,353.631
	c0-0.633,0.515-1.148,1.147-1.148h36.701c0.633,0,1.148,0.515,1.148,1.148v93.698c0,0.633-0.516,1.148-1.148,1.148h-36.701
	c-0.633,0-1.147-0.515-1.147-1.148V353.631z M248.238,400.48c0,8.271-6.729,14.999-14.999,14.999s-14.999-6.729-14.999-14.999
	s6.729-14.999,14.999-14.999S248.238,392.21,248.238,400.48z M233.239,315.661c-7.095,0-14.999-10.611-14.999-25.836
	c0-15.226,7.904-25.837,14.999-25.837s14.999,10.611,14.999,25.837C248.238,305.05,240.333,315.661,233.239,315.661z
	 M233.239,80.996c-8.271,0-14.999-6.729-14.999-14.999c0-8.27,6.729-14.999,14.999-14.999s14.999,6.729,14.999,14.999
	C248.238,74.268,241.509,80.996,233.239,80.996z M341.984,19.148c0-0.633,0.515-1.148,1.147-1.148h36.701
	c0.633,0,1.148,0.515,1.148,1.148v93.698c0,0.633-0.516,1.148-1.148,1.148h-36.701c-0.633,0-1.147-0.515-1.147-1.148V19.148z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>