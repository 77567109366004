<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 466.96 466.96"
    style="enable-background: new 0 0 466.96 466.96"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M310.597,170.701l44.245,15.889c1.005,0.361,2.032,0.532,3.042,0.532c3.69,0,7.152-2.288,8.471-5.96l10.694-29.78
		c0.807-2.247,0.688-4.722-0.33-6.88c-1.018-2.159-2.852-3.825-5.098-4.632l-44.245-15.889c-4.68-1.683-9.833,0.751-11.512,5.428
		l-10.694,29.78c-0.807,2.247-0.688,4.722,0.33,6.88C306.517,168.228,308.351,169.894,310.597,170.701z M329.762,143.963
		l27.304,9.805l-4.611,12.839l-27.304-9.805L329.762,143.963z"
      />
      <circle cx="110.073" cy="397.876" r="9.436" />
      <path
        d="M307.478,248.329l2.505-2.505c11.811-11.811,25.606-21.083,41.003-27.559l58.987-24.811c0,0,16.019-6.908,19.598-8.936
		c4.795-2.717,9.546-6.458,14.526-11.439c29.145-29.145,30.608-76.139,3.332-106.987c-2.095-2.37-5.282-3.468-8.392-2.886
		c-3.11,0.58-5.687,2.75-6.788,5.716l-21.495,57.906l-16.041-5.938l22.604-61.516c1.091-2.97,0.543-6.294-1.444-8.756
		c-1.987-2.462-5.121-3.701-8.254-3.26c-30.424,4.268-55.149,25.782-63.882,54.662l-37.125-13.743
		c-2.491-0.922-5.262-0.696-7.571,0.616c-2.309,1.313-3.921,3.578-4.403,6.189l-8.543,46.231
		c-3.997,21.634-13.356,41.589-27.243,58.383l-79.013-79.005l5.618-5.618c3.515-3.515,3.515-9.213,0-12.728l-5.937-5.937
		c18.098-22.217,41.25-23.313,58.085-19.976c3.711,0.737,7.49-0.93,9.449-4.17c1.959-3.239,1.683-7.357-0.692-10.305
		c-7.947-9.863-17.833-17.551-29.379-22.847c-27.911-12.823-60.836-8.599-84.6,10.161l-4.914-4.914
		c-3.515-3.515-9.213-3.515-12.728,0l-54.649,54.65c-3.49,3.49-3.519,9.139-0.063,12.664c3.116,3.179,1.64,10.176-3.158,14.974
		c-4.798,4.798-11.795,6.274-14.974,3.158c-3.525-3.456-9.175-3.427-12.664,0.063L2.636,156.462c-3.515,3.515-3.515,9.213,0,12.728
		l57.988,57.988c1.757,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636l26.597-26.597c3.479-3.479,3.52-9.107,0.091-12.636
		c-3.093-3.183-1.608-10.171,3.177-14.956c4.784-4.784,11.772-6.27,14.955-3.177c3.528,3.43,9.157,3.389,12.636-0.09l4.969-4.969
		l79.433,79.433L84.705,374.689c-6.438,6.438-9.984,14.998-9.984,24.104c0,9.105,3.546,17.665,9.984,24.103
		c6.645,6.646,15.374,9.968,24.104,9.968c8.729,0,17.458-3.323,24.103-9.968l130.503-130.503L403.61,432.588
		c1.757,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636l31.34-31.34c1.688-1.688,2.636-3.977,2.636-6.364
		c0-2.387-0.948-4.676-2.636-6.364L307.478,248.329z M394.373,69.628l-16.54,45.013l-17.156-6.351
		C365.615,90.834,378.082,76.74,394.373,69.628z M209.474,55.468c1.446,0.663,2.857,1.374,4.235,2.132
		c-18.285,2.306-34.32,11.162-46.96,26.036l-21.525-21.525C163.59,48.584,188.366,45.771,209.474,55.468z M121.106,63.448
		l45.26,45.26l-40.958,40.958l-45.26-45.26L121.106,63.448z M66.988,208.086l-45.26-45.26l13.869-13.869l45.26,45.26L66.988,208.086
		z M90.488,160.261c-3.251,3.251-5.714,6.912-7.407,10.725l-24.205-24.204c3.81-1.694,7.469-4.158,10.721-7.41
		c3.636-3.636,6.303-7.776,7.989-12.072l24.978,24.978C98.265,153.962,94.123,156.627,90.488,160.261z M148.503,152.025
		l18.608-18.608l79.437,79.429l-18.612,18.612L148.503,152.025z M120.184,410.168c-3.039,3.039-7.079,4.712-11.376,4.712
		c-4.297,0-8.337-1.673-11.375-4.712c-6.272-6.273-6.272-16.479,0-22.751L263.36,221.49c21.046-21.045,35.028-47.64,40.436-76.907
		l6.6-35.713l102.542,37.961c4.657,1.725,9.833-0.652,11.562-5.309l18.515-49.879c10.851,22.482,6.816,50.247-11.646,68.709
		c-3.817,3.817-7.309,6.599-10.674,8.506c-2.09,1.185-18.849,8.487-18.849,8.487l-57.84,24.328
		c-17.556,7.384-33.285,17.956-46.752,31.423L120.184,410.168z M409.974,413.496L276.143,279.665l18.607-18.607l133.836,133.827
		L409.974,413.496z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>