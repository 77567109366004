var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"day",class:{ moving: _vm.weekMoving }},[_c('div',{staticClass:"day-label",class:{
      'is-selected': _vm.isDaySelected,
      'is-disabled': _vm.isDayInThePast || _vm.isDayUnavailable,
    }},[_vm._v(" "+_vm._s(_vm.day.label)+" ")]),_c('div',{staticClass:"day-numeric",class:{
      'is-disabled': _vm.isDayInThePast || _vm.isDayUnavailable,
      'is-selected': _vm.isDaySelected,
    }},[_vm._v(" "+_vm._s(_vm.day.numeric)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }