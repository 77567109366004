<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 469.861 469.861"
    style="enable-background: new 0 0 469.861 469.861"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M393.715,213.517h28.358c4.971,0,9-4.03,9-9v-33.56c0-4.97-4.029-9-9-9H300.912v-7.782
		c0-18.84-15.328-34.168-34.167-34.168h-47.949v-11.365h11.241c4.971,0,9-4.03,9-9V72.375c0-4.97-4.029-9-9-9h-62.925
		c-4.971,0-9,4.03-9,9v27.267c0,4.97,4.029,9,9,9h11.241v11.365h-47.949c-14.092,0-26.217,8.577-31.434,20.783
		c-9.945-5.41-20.908-8.251-31.981-8.251c-17.388,0-34.002,6.948-46.786,19.566c-22.664,22.386-26.662,55.647-9.947,82.767
		l85.98,139.452v23.163c0,4.971,4.029,9,9,9h186.677c4.971,0,9-4.029,9-9v-22.879L393.715,213.517z M413.073,179.957v15.56h-24.56
		c-3.218,0-6.192,1.719-7.798,4.507l-79.802,138.525V179.957H413.073z M176.112,81.375h44.925v9.267h-44.925V81.375z
		 M196.352,108.642h4.443v11.365h-4.443V108.642z M96.235,280.075l-43.874-71.17c-6.589-10.68-0.38-18.664,2.601-21.605
		c3.439-3.4,7.861-5.35,12.13-5.35c3.131,0,6.333,1.021,9.504,3.025l19.639,12.461V280.075z M25.578,225.426
		c-12.221-19.828-9.298-44.147,7.271-60.513c9.391-9.269,21.515-14.374,34.139-14.374c9.2,0,18.346,2.72,26.451,7.87l2.796,1.775
		v15.934l-10.008-6.35c-6.107-3.861-12.545-5.818-19.135-5.818c-8.969,0-18.002,3.845-24.78,10.544
		c-9.27,9.149-16.213,26.127-5.272,43.86l59.195,96.023v25.649L25.578,225.426z M282.912,388.487H114.235V154.175
		c0-8.915,7.253-16.168,16.168-16.168h136.342c8.915,0,16.167,7.253,16.167,16.168V388.487z"
      />
      <path
        d="M457.939,256.249l-22.418-22.418c-1.688-1.688-3.977-2.636-6.364-2.636c-2.387,0-4.676,0.948-6.364,2.636l-22.418,22.418
		c-7.688,7.688-11.922,17.91-11.922,28.782c0,10.872,4.234,21.094,11.922,28.782c7.935,7.935,18.358,11.903,28.782,11.903
		c10.424,0,20.847-3.968,28.782-11.903c7.688-7.688,11.922-17.91,11.922-28.782C469.861,274.159,465.627,263.937,457.939,256.249z
		 M445.211,301.085c-8.853,8.852-23.256,8.852-32.109,0c-4.288-4.288-6.65-9.99-6.65-16.054s2.362-11.766,6.65-16.054l16.055-16.054
		l16.054,16.054c4.288,4.288,6.65,9.99,6.65,16.054S449.499,296.797,445.211,301.085z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>