<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 443.36 443.36"
    style="enable-background: new 0 0 443.36 443.36"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M346.369,208.955h2.523c4.971,0,9-4.029,9-9s-4.029-9-9-9h-11.424l-3.825-8.374c-3.548-7.771-10.638-13.55-18.965-15.46
		c-11.742-2.69-56.167-2.69-67.909,0c-8.326,1.91-15.416,7.689-18.963,15.458l-3.826,8.375h-11.424c-4.971,0-9,4.029-9,9
		s4.029,9,9,9h2.522l-6.446,6.446c-4.907,4.907-7.254,11.938-6.279,18.808l2.497,17.605c0.71,5.01,2.982,9.485,6.263,12.982v7.382
		c0,4.971,4.029,9,9,9h25.837c4.884,0,8.849-3.892,8.987-8.742h51.577c0.138,4.85,4.103,8.742,8.987,8.742h25.834
		c4.971,0,9-4.029,9-9v-7.382c3.281-3.497,5.552-7.972,6.264-12.982l2.497-17.606c0.974-6.872-1.374-13.903-6.28-18.807
		L346.369,208.955z M228.603,254.436c-2.965,0-5.515-2.213-5.931-5.149l-1.041-7.342h15.16c4.971,0,9-4.029,9-9s-4.029-9-9-9
		h-11.247l3.112-3.112h104.136l3.111,3.112h-11.115c-4.971,0-9,4.029-9,9s4.029,9,9,9h15.03l-1.041,7.341
		c-0.416,2.936-2.966,5.149-5.931,5.149H228.603z M250.791,184.666c9.23-2.115,50.634-2.116,59.864,0
		c2.903,0.666,5.376,2.681,6.613,5.393l5.836,12.774h-84.762l5.837-12.776C245.416,187.347,247.889,185.332,250.791,184.666z"
      />
      <path
        d="M297.733,234.019h-34.02c-4.971,0-9,4.029-9,9s4.029,9,9,9h34.02c4.971,0,9-4.029,9-9S302.704,234.019,297.733,234.019z"
      />
      <path
        d="M390.037,91.725H127.846c-21.648,0-40.008,15.449-43.973,36.424H30.69c-16.923,0-30.69,13.768-30.69,30.69v24.11
		c0,16.922,13.768,30.69,30.69,30.69h43.119l-5.005,43.056c-2.801,24.099,4.846,48.312,20.98,66.43
		c16.135,18.119,39.303,28.51,63.563,28.51h236.688c29.402,0,53.323-23.921,53.323-53.324V145.049
		C443.36,115.646,419.439,91.725,390.037,91.725z M30.69,195.639c-6.997,0-12.69-5.693-12.69-12.69v-24.11
		c0-6.997,5.693-12.69,12.69-12.69h50.963l-5.752,49.49H30.69z M425.36,298.311c0,19.478-15.846,35.324-35.323,35.324H153.349
		c-19.13,0-37.398-8.194-50.121-22.48c-12.722-14.287-18.752-33.379-16.544-52.381l14.572-125.37
		c1.569-13.499,13-23.678,26.59-23.678h262.191c19.478,0,35.323,15.846,35.323,35.323V298.311z"
      />
      <path
        d="M371.589,132.725H194.988c-15.621,0-28.762,11.701-30.565,27.219L150.896,276.31c-1.013,8.713,1.752,17.467,7.586,24.018
		c5.833,6.55,14.209,10.307,22.979,10.307h190.127c16.968,0,30.771-13.804,30.771-30.771V163.497
		C402.36,146.53,388.557,132.725,371.589,132.725z M384.36,279.864c0,7.042-5.729,12.771-12.771,12.771H181.462
		c-3.693,0-7.081-1.519-9.537-4.278c-2.457-2.759-3.575-6.299-3.149-9.969l13.526-116.366c0.749-6.44,6.203-11.297,12.687-11.297
		h176.601c7.042,0,12.771,5.729,12.771,12.771V279.864z"
      />
      <path
        d="M128.992,191.143c13.233,0,23.999-10.766,23.999-23.999c0-13.233-10.766-24-23.999-24s-23.999,10.766-23.999,24
		C104.993,180.377,115.759,191.143,128.992,191.143z M128.992,161.144c3.308,0,5.999,2.691,5.999,6s-2.691,5.999-5.999,5.999
		s-5.999-2.691-5.999-5.999S125.685,161.144,128.992,161.144z"
      />
      <path
        d="M122.593,204.641c-13.233,0-24,10.766-24,23.999c0,13.233,10.767,24,24,24s23.999-10.766,23.999-24
		C146.592,215.407,135.826,204.641,122.593,204.641z M122.593,234.64c-3.309,0-6-2.691-6-6s2.691-5.999,6-5.999
		c3.308,0,5.999,2.691,5.999,5.999S125.9,234.64,122.593,234.64z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>