<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 432.138 432.138"
    style="enable-background: new 0 0 432.138 432.138"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M92.093,274.582c-36.172,0-65.6,29.428-65.6,65.599c0,36.172,29.428,65.6,65.6,65.6c36.171,0,65.599-29.428,65.599-65.6
		C157.692,304.009,128.264,274.582,92.093,274.582z M92.093,387.78c-26.247,0-47.6-21.353-47.6-47.6
		c0-26.246,21.353-47.599,47.6-47.599c26.246,0,47.599,21.353,47.599,47.599C139.692,366.427,118.339,387.78,92.093,387.78z"
      />
      <path
        d="M92.093,316.684c-12.956,0-23.497,10.541-23.497,23.497s10.541,23.497,23.497,23.497s23.497-10.541,23.497-23.497
		S105.049,316.684,92.093,316.684z M92.093,345.677c-3.031,0-5.497-2.466-5.497-5.497s2.466-5.497,5.497-5.497
		s5.497,2.466,5.497,5.497S95.124,345.677,92.093,345.677z"
      />
      <path
        d="M144.976,107.529c18.55,0,33.642-15.092,33.642-33.642c0-18.55-15.091-33.642-33.642-33.642s-33.642,15.092-33.642,33.642
		C111.334,92.437,126.426,107.529,144.976,107.529z M144.976,58.245c8.625,0,15.642,7.017,15.642,15.642
		s-7.017,15.642-15.642,15.642s-15.642-7.017-15.642-15.642S136.351,58.245,144.976,58.245z"
      />
      <circle cx="374.542" cy="189.021" r="13.707" />
      <path
        d="M404.55,237.99c16.452-10.118,27.451-28.278,27.451-48.968c0-20.441-10.738-38.411-26.86-48.599L182.014,9.944l0.021,0.063
		C171.132,3.657,158.476,0,144.976,0c-32.441,0-60.047,21.024-69.955,50.156c-0.368,1.083-72.058,267.258-72.55,269.392
		l-0.004,0.016l0.001-0.001c-1.525,6.63-2.332,13.531-2.332,20.617c0,50.705,41.252,91.957,91.957,91.957
		c17.403,0,33.69-4.862,47.58-13.295C140.869,418.117,404.231,238.186,404.55,237.99z M362.096,245.108L179.038,370.13
		c3.245-9.393,5.012-19.468,5.012-29.949c0-50.705-41.252-91.957-91.957-91.957c-21.426,0-41.163,7.367-56.814,19.699
		l43.234-161.784c11.998,24.625,37.279,41.635,66.463,41.635c40.742,0,73.887-33.146,73.887-73.887
		c0-8.354-1.414-16.379-3.981-23.875l143.47,83.886c-23.824,7.01-41.271,29.061-41.271,55.123
		C317.082,216.431,336.378,239.405,362.096,245.108z M374.542,228.481c-21.758,0-39.46-17.702-39.46-39.46
		c0-21.759,17.702-39.46,39.46-39.46s39.46,17.702,39.46,39.46C414.002,210.78,396.3,228.481,374.542,228.481z M144.976,18
		c30.816,0,55.887,25.071,55.887,55.887s-25.071,55.887-55.887,55.887s-55.887-25.071-55.887-55.887S114.159,18,144.976,18z
		 M18.136,340.181c0-40.78,33.177-73.957,73.957-73.957s73.957,33.177,73.957,73.957s-33.177,73.957-73.957,73.957
		S18.136,380.961,18.136,340.181z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>