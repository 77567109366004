<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 426 426"
    style="enable-background: new 0 0 426 426"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M417,12.554c-4.971,0-9,4.029-9,9v22.385H18V21.554c0-4.971-4.029-9-9-9s-9,4.029-9,9v382.893c0,4.971,4.029,9,9,9
		s9-4.029,9-9v-22.385h390v22.385c0,4.971,4.029,9,9,9s9-4.029,9-9V21.554C426,16.583,421.971,12.554,417,12.554z M18,318.036h108.3
		c4.971,0,9-4.029,9-9s-4.029-9-9-9H18v-14.01h97.152c4.971,0,9-4.029,9-9s-4.029-9-9-9H18v-14.01h123.099c4.971,0,9-4.029,9-9
		s-4.029-9-9-9H18v-14.02h64.95c4.971,0,9-4.029,9-9s-4.029-9-9-9H18v-14.01h68.98c4.971,0,9-4.029,9-9s-4.029-9-9-9H18v-14.01
		h82.08c4.971,0,9-4.029,9-9s-4.029-9-9-9H18v-14.01h165c4.971,0,9-4.029,9-9s-4.029-9-9-9H18V93.951h390v14.016H295.71
		c-4.971,0-9,4.029-9,9s4.029,9,9,9H408v14.01H306.858c-4.971,0-9,4.029-9,9s4.029,9,9,9H408v14.01H280.911c-4.971,0-9,4.029-9,9
		s4.029,9,9,9H408v14.01h-68.95c-4.971,0-9,4.029-9,9s4.029,9,9,9H408v14.02h-72.99c-4.971,0-9,4.029-9,9s4.029,9,9,9H408v14.01
		h-86.09c-4.971,0-9,4.029-9,9s4.029,9,9,9H408v14.01H236c-4.971,0-9,4.029-9,9s4.029,9,9,9h172v14.013H18V318.036z M408,61.938
		v14.012H18V61.938H408z M18,364.062v-14.012h390v14.012H18z"
      />
      <path
        d="M230.058,244.782l46.222,46.222c1.757,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636
		c20.833-20.833,32.306-48.535,32.306-78.004c0-4.971-4.029-9-9-9h-65.35c-0.881-3.549-2.285-6.936-4.171-10.069l46.211-46.211
		c3.515-3.515,3.515-9.213,0-12.728c-20.833-20.833-48.535-32.306-78.003-32.306c-4.971,0-9,4.029-9,9v65.376
		c-3.497,0.87-6.89,2.256-10.059,4.156l-46.222-46.222c-1.688-1.688-3.977-2.636-6.364-2.636c-2.387,0-4.676,0.948-6.364,2.636
		c-20.833,20.833-32.306,48.535-32.306,78.004c0,4.971,4.029,9,9,9h65.376c0.87,3.497,2.256,6.889,4.155,10.058l-46.222,46.222
		c-3.515,3.515-3.515,9.213,0,12.728c20.833,20.833,48.535,32.306,78.004,32.306c4.971,0,9-4.029,9-9v-65.376
		C223.497,248.068,226.889,246.682,230.058,244.782z M282.339,271.608l-39.544-39.543c1.885-3.131,3.287-6.517,4.168-10.064h55.92
		C301.126,240.288,294.024,257.436,282.339,271.608z M269.607,141.66l-39.554,39.555c-3.168-1.898-6.558-3.283-10.054-4.153v-55.946
		C238.288,122.874,255.436,129.975,269.607,141.66z M211,193.94c5.091,0,9.877,1.983,13.477,5.583c3.6,3.6,5.582,8.386,5.582,13.478
		s-1.982,9.877-5.582,13.478c-3.6,3.6-8.386,5.583-13.477,5.583c-5.091,0-9.877-1.983-13.478-5.583
		c-3.6-3.6-5.583-8.386-5.583-13.478s1.983-9.877,5.583-13.478C201.123,195.923,205.909,193.94,211,193.94z M139.66,154.392
		l39.555,39.555c-1.898,3.167-3.283,6.558-4.152,10.053h-55.946C120.874,185.712,127.975,168.564,139.66,154.392z M152.392,284.34
		l39.554-39.555c3.168,1.898,6.559,3.283,10.054,4.153v55.946C183.712,303.126,166.564,296.025,152.392,284.34z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>