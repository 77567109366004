<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 452.263 452.263"
    style="enable-background: new 0 0 452.263 452.263"
    xml:space="preserve"
    fill="currentColor"
  >
    <path
      d="M405.02,161.533c-4.971,0-9,4.029-9,9v53.407h-18.298v-45.114c0-4.971-4.029-9-9-9h-41.756l-7.778-25.93
	c-1.142-3.807-4.646-6.414-8.621-6.414h-63.575v-22.77h40.032c4.971,0,9-4.029,9-9V75.666c0-4.971-4.029-9-9-9H158.064
	c-4.971,0-9,4.029-9,9v30.047c0,4.971,4.029,9,9,9h40.031v22.77h-92.714c-3.297,0-6.33,1.802-7.905,4.698l-15.044,27.646H47.227
	c-4.971,0-9,4.029-9,9v45.114H18v-64.342c0-4.971-4.029-9-9-9s-9,4.029-9,9v186.008c0,4.971,4.029,9,9,9s9-4.029,9-9v-64.342h20.227
	v47.64c0,4.971,4.029,9,9,9h74.936l34.26,44.207c1.705,2.2,4.331,3.487,7.114,3.487h205.185c4.971,0,9-4.029,9-9v-95.333h18.298
	v53.407c0,4.971,4.029,9,9,9c26.05,0,47.243-21.193,47.243-47.243v-87.651C452.263,182.727,431.07,161.533,405.02,161.533z
	 M18,263.264V241.94h20.227v21.324H18z M167.064,84.666h110.959v12.047H167.064V84.666z M216.096,114.713h12.896v22.77h-12.896
	V114.713z M56.227,187.826h6.395v132.078h-6.395V187.826z M133.688,323.391c-1.705-2.2-4.331-3.487-7.114-3.487H80.622V187.826
	h7.159c3.297,0,6.33-1.802,7.905-4.698l15.044-27.646h193.14l7.778,25.93c1.142,3.807,4.646,6.414,8.621,6.414h16.151v179.771
	H167.948L133.688,323.391z M359.722,367.597h-5.302V187.826h5.302V367.597z M377.722,263.264V241.94h18.298v21.324H377.722z
	 M434.263,296.428c0,12.986-8.508,24.022-20.243,27.827V180.95c11.735,3.804,20.243,14.84,20.243,27.827V296.428z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>