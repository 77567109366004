<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 433.001 433.001"
    style="enable-background: new 0 0 433.001 433.001"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M428.204,192.538c-11.473-51.494-42.312-95.436-86.835-123.731c-27.493-17.471-58.783-27.834-91.005-30.26
		c-0.28-3.159-0.939-6.324-2.039-9.428c-4.908-13.854-17.038-23.412-31.655-24.943l-35.797-3.752
		c-19.693-2.064-39.013,4.782-53.014,18.781L19.143,127.921C5.144,141.92-1.702,161.242,0.361,180.934l3.752,35.797
		c1.532,14.617,11.09,26.747,24.943,31.655c3.108,1.101,6.276,1.781,9.439,2.06c4.745,62.002,38.275,118.231,90.993,151.733
		c32.771,20.827,69.392,30.763,105.617,30.76c65.369-0.005,129.419-32.372,167.009-91.506
		C430.411,296.909,439.676,244.03,428.204,192.538z M85.659,222.2l44.156-44.155c1.315,0.689,2.736,1.041,4.149,1.041
		c2.96,0,5.86-1.46,7.58-4.13c-0.01,0-0.01,0-0.01,0c1.811-2.822,1.847-6.266,0.405-9.036l28.438-28.438
		c1.598,1.266,3.578,1.954,5.606,1.954c1.49,0,3.011-0.37,4.41-1.16c4.33-2.45,5.86-7.94,3.41-12.27
		c-0.234-0.416-0.504-0.797-0.791-1.159l39.124-39.124c33.202-2.996,65.623,4.886,93.938,22.879
		c69.958,44.469,90.702,137.57,46.243,207.536c-21.543,33.892-54.995,57.366-94.194,66.098
		c-39.198,8.732-79.451,1.677-113.343-19.866c-24.146-15.347-43.305-37.081-55.401-62.851
		C88.039,275.363,83.331,248.696,85.659,222.2z M22.015,214.854l-3.752-35.797c-1.495-14.267,3.465-28.266,13.608-38.409
		L140.587,31.932c10.143-10.143,24.138-15.102,38.408-13.608l35.797,3.752c7.766,0.814,13.958,5.693,16.565,13.052
		c2.606,7.359,0.867,15.048-4.653,20.569L55.636,226.766c-5.521,5.521-13.214,7.259-20.568,4.653
		C27.709,228.812,22.829,222.619,22.015,214.854z M386.922,331.778c-34.167,53.75-92.37,83.168-151.788,83.166
		c-32.919-0.001-66.213-9.031-95.992-27.957c-48.32-30.707-78.912-82.428-82.812-139.355c3.905-1.657,7.565-3.994,10.818-6.979
		c0.717,22.929,6.104,45.567,15.938,66.515c13.551,28.869,35.005,53.21,62.04,70.394c27.334,17.375,58.354,26.333,89.977,26.332
		c12.28,0,24.658-1.353,36.936-4.088c43.892-9.778,81.349-36.062,105.473-74.013c49.781-78.344,26.553-182.59-51.78-232.383
		c-25.567-16.247-55.144-25.244-85.18-26.182c3-3.262,5.347-6.931,7.009-10.851c29.787,1.996,58.749,11.476,84.155,27.621
		c40.465,25.717,68.493,65.654,78.92,112.454C421.062,243.252,412.64,291.312,386.922,331.778z"
      />
      <path
        d="M186.957,311.749c14.671,9.324,31.321,14.132,48.293,14.132c6.594,0,13.237-0.726,19.829-2.195
		c23.559-5.249,43.663-19.358,56.609-39.729c12.947-20.37,17.187-44.563,11.938-68.122c-5.249-23.559-19.358-43.663-39.729-56.61
		c-42.052-26.727-98.005-14.259-124.732,27.792C132.439,229.069,144.906,285.023,186.957,311.749z M174.356,196.673
		c13.772-21.669,37.233-33.527,61.187-33.527c13.271,0,26.693,3.642,38.699,11.272c16.313,10.368,27.611,26.468,31.814,45.333
		s0.809,38.239-9.56,54.552c-10.367,16.313-26.467,27.611-45.333,31.814c-18.863,4.204-38.238,0.809-54.553-9.559
		C162.937,275.156,152.954,230.347,174.356,196.673z"
      />
      <path
        d="M346.623,283.286c0.97,0.34,1.96,0.5,2.939,0.5c3.73,0,7.221-2.34,8.5-6.06c1.63-4.7-0.859-9.83-5.56-11.45
		c-4.7-1.63-9.82,0.87-11.45,5.57C339.433,276.536,341.923,281.666,346.623,283.286z"
      />
      <path
        d="M332.623,172.886c1.859,0,3.72-0.57,5.33-1.75c4-2.95,4.859-8.58,1.92-12.59c-2.95-4-8.58-4.86-12.59-1.91
		c-4,2.94-4.86,8.57-1.91,12.58C327.133,171.616,329.864,172.886,332.623,172.886z"
      />
      <path
        d="M354.743,226.246c0.449,0,0.91-0.03,1.37-0.1c4.92-0.76,8.29-5.35,7.529-10.26c-0.75-4.92-5.35-8.29-10.26-7.53
		c-4.91,0.75-8.28,5.35-7.53,10.26C346.543,223.066,350.373,226.246,354.743,226.246z"
      />
      <path
        d="M212.093,344.756c-4.86-1.05-9.65,2.05-10.689,6.91c-1.04,4.86,2.05,9.64,6.909,10.69c0.641,0.13,1.271,0.2,1.9,0.2
		c4.15,0,7.89-2.89,8.79-7.12C220.043,350.576,216.953,345.796,212.093,344.756z"
      />
      <path
        d="M265.513,343.056c-4.79,1.34-7.58,6.31-6.24,11.1c1.12,3.97,4.73,6.57,8.66,6.57c0.811,0,1.62-0.11,2.44-0.33
		c4.78-1.34,7.58-6.31,6.229-11.1C275.263,344.516,270.303,341.716,265.513,343.056z"
      />
      <path
        d="M312.074,316.776c-3.61,3.41-3.78,9.11-0.37,12.73c1.77,1.87,4.16,2.82,6.55,2.82c2.21,0,4.43-0.81,6.17-2.45
		c3.62-3.41,3.79-9.1,0.38-12.72C321.393,313.536,315.693,313.366,312.074,316.776z"
      />
      <path
        d="M163.983,321.386c-3.829-3.17-9.5-2.65-12.68,1.18c-3.17,3.82-2.64,9.5,1.18,12.67c1.681,1.4,3.721,2.08,5.74,2.08
		c2.59,0,5.15-1.11,6.931-3.25C168.333,330.236,167.803,324.566,163.983,321.386z"
      />
      <path
        d="M284.133,134.966c0.05,0.02,0.1,0.05,0.14,0.07c0.011,0,0.011,0,0.011,0c1.26,0.62,2.6,0.91,3.92,0.91
		c3.34,0,6.55-1.86,8.109-5.07c2.17-4.47,0.311-9.85-4.159-12.03c-0.03-0.01-0.061-0.03-0.091-0.04c-0.02-0.01-0.029-0.01-0.04-0.02
		c-4.479-2.17-9.859-0.3-12.029,4.17C277.833,127.416,279.683,132.786,284.133,134.966z"
      />
      <path
        d="M120.513,273.506c-4.59,1.9-6.76,7.17-4.859,11.76c1.439,3.46,4.789,5.55,8.319,5.55c1.15,0,2.32-0.22,3.45-0.69
		c4.58-1.91,6.76-7.17,4.86-11.76C130.373,273.766,125.103,271.596,120.513,273.506z"
      />
      <path
        d="M114.373,233.676c0.27,0.03,0.54,0.04,0.81,0.04c4.61,0,8.54-3.52,8.96-8.2c0.44-4.95-3.22-9.32-8.17-9.77
		c-4.95-0.44-9.319,3.22-9.76,8.17C105.763,228.866,109.423,233.236,114.373,233.676z"
      />
      <path
        d="M231.583,123.826c0.1,0,0.2,0,0.3-0.01c4.97-0.16,8.87-4.32,8.7-9.29c-0.16-4.96-4.311-8.86-9.28-8.7
		c-4.97,0.16-8.87,4.32-8.7,9.29C222.753,119.986,226.753,123.826,231.583,123.826z"
      />
      <path
        d="M202.236,287.709c10.047,6.385,21.448,9.677,33.069,9.677c4.515,0,9.064-0.497,13.578-1.503
		c16.132-3.594,29.898-13.256,38.764-27.204c8.865-13.949,11.769-30.515,8.175-46.647c-3.595-16.132-13.256-29.899-27.204-38.764
		c-0.001,0-0.001,0-0.001,0c-28.795-18.303-67.111-9.763-85.411,19.031C164.905,231.092,173.442,269.408,202.236,287.709z
		 M198.397,211.953c8.351-13.139,22.576-20.329,37.1-20.329c8.047,0,16.187,2.208,23.465,6.834
		c9.892,6.286,16.742,16.048,19.291,27.488s0.49,23.186-5.797,33.077c-6.286,9.891-16.048,16.742-27.487,19.291
		c-11.438,2.549-23.186,0.49-33.077-5.796C191.473,259.54,185.42,232.371,198.397,211.953z"
      />
      <path
        d="M67.312,183.42c6.216,0,12.06-2.42,16.455-6.816c4.396-4.396,6.815-10.239,6.815-16.455c0-6.216-2.421-12.06-6.815-16.455
		c-4.396-4.395-10.239-6.816-16.455-6.816c-6.217,0-12.061,2.421-16.455,6.816c-4.395,4.395-6.815,10.239-6.815,16.455
		c0,6.216,2.42,12.06,6.815,16.455C55.252,181,61.095,183.42,67.312,183.42z M63.586,156.422c0.995-0.995,2.318-1.543,3.727-1.543
		c1.407,0,2.731,0.548,3.727,1.544c0.996,0.996,1.544,2.319,1.544,3.727s-0.548,2.731-1.544,3.727
		c-0.995,0.995-2.319,1.544-3.727,1.544c-1.408,0-2.731-0.548-3.727-1.544c-0.996-0.996-1.544-2.319-1.544-3.727
		S62.589,157.418,63.586,156.422z"
      />
      <path
        d="M160.088,90.644c6.216,0,12.06-2.42,16.455-6.816c4.396-4.396,6.815-10.239,6.815-16.455c0-6.216-2.421-12.06-6.815-16.455
		c-4.396-4.395-10.239-6.816-16.455-6.816c-6.217,0-12.061,2.421-16.455,6.816c-4.395,4.395-6.815,10.239-6.815,16.455
		c0,6.216,2.42,12.06,6.815,16.455C148.028,88.223,153.872,90.644,160.088,90.644z M156.362,63.646
		c0.995-0.995,2.318-1.543,3.727-1.543c1.407,0,2.731,0.548,3.727,1.544c0.996,0.996,1.544,2.319,1.544,3.727
		s-0.548,2.731-1.544,3.727c-0.995,0.995-2.319,1.544-3.727,1.544c-1.408,0-2.731-0.548-3.727-1.544
		c-0.996-0.996-1.544-2.319-1.544-3.727S155.366,64.642,156.362,63.646z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>