<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 466.852 466.852"
    style="enable-background: new 0 0 466.852 466.852"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M227.381,207.835c-17.445,0-31.637,14.192-31.637,31.637s14.192,31.637,31.637,31.637
		c17.445,0,31.638-14.193,31.638-31.637S244.827,207.835,227.381,207.835z M227.381,253.109c-7.52,0-13.637-6.118-13.637-13.637
		c0-7.52,6.118-13.637,13.637-13.637s13.638,6.118,13.638,13.637C241.019,246.992,234.901,253.109,227.381,253.109z"
      />
      <path
        d="M466.852,107.27c0-59.148-48.12-107.269-107.269-107.269c-24.499,0-47.101,8.261-65.18,22.136
		c-21.642-6.667-44.165-10.047-67.024-10.047c-60.736,0-117.837,23.651-160.783,66.597C23.651,121.634,0,178.734,0,239.471
		c0,60.736,23.651,117.837,66.597,160.783c42.946,42.946,100.046,66.597,160.783,66.597c60.737,0,117.836-23.651,160.783-66.597
		c42.946-42.946,66.598-100.046,66.598-160.783c0-22.89-3.379-45.412-10.042-67.024C458.592,154.368,466.852,131.767,466.852,107.27
		z M448.852,107.27c0,49.223-40.046,89.269-89.269,89.269s-89.269-40.046-89.269-89.269s40.046-89.269,89.269-89.269
		S448.852,58.047,448.852,107.27z M379.82,239.471c0,84.056-68.384,152.44-152.44,152.44c-84.056,0-152.44-68.384-152.44-152.44
		s68.384-152.44,152.44-152.44c8.901,0,17.762,0.766,26.446,2.283c-0.717,4.234-1.187,8.551-1.39,12.937
		c-2.783-0.504-5.579-0.943-8.382-1.275c-5.445-0.66-11.056-0.995-16.674-0.995c-22.317,0-43.644,5.115-63.387,15.203
		c-18.824,9.618-35.498,23.659-48.219,40.602c-18.242,24.289-27.884,53.227-27.884,83.685c0,76.915,62.575,139.49,139.49,139.49
		c22.318,0,43.645-5.115,63.388-15.203c18.823-9.618,35.497-23.658,48.219-40.602c18.241-24.288,27.883-53.226,27.883-83.685
		c0-8.432-0.773-16.817-2.266-25.053c4.385-0.203,8.7-0.673,12.934-1.39C379.054,221.709,379.82,230.57,379.82,239.471z
		 M346.113,213.695c1.82,8.431,2.756,17.073,2.756,25.776c0,12.959-2.017,25.596-5.917,37.604l-49.249-32.385
		c-2.539-1.668-2.813-4.205-2.813-5.219c0-1.013,0.274-3.55,2.814-5.22l36.297-23.863
		C335.221,211.888,340.603,213.002,346.113,213.695z M283.817,219.21c-6.842,4.497-10.927,12.071-10.927,20.261
		s4.085,15.764,10.925,20.26l52.146,34.29c-2.109,4.18-4.471,8.25-7.079,12.199l-55.74-28.008
		c-7.316-3.676-15.918-3.428-23.012,0.667c-7.093,4.095-11.609,11.42-12.084,19.595l-3.613,62.286c-2.338,0.132-4.69,0.2-7.053,0.2
		c-2.369,0-4.72-0.076-7.056-0.21l-3.612-62.268c-0.475-8.175-4.992-15.5-12.083-19.595s-15.694-4.344-23.011-0.668l-55.704,27.987
		c-2.587-3.92-4.954-7.997-7.082-12.216l52.112-34.258c6.843-4.499,10.928-12.073,10.928-20.262c0-8.189-4.085-15.764-10.928-20.262
		l-52.147-34.281c2.11-4.182,4.473-8.254,7.081-12.204l55.738,28.008c3.443,1.73,7.17,2.591,10.892,2.591
		c4.187,0,8.366-1.09,12.12-3.258c7.092-4.094,11.609-11.42,12.084-19.596l3.613-62.286c2.339-0.132,4.691-0.201,7.055-0.201
		c2.36,0,4.715,0.071,7.055,0.206l3.611,62.28c0.479,8.172,4.997,15.494,12.088,19.587c3.754,2.167,7.932,3.257,12.119,3.257
		c3.719,0,7.445-0.86,10.888-2.588l19.427-9.761c5.317,4.266,11.046,8.035,17.118,11.24L283.817,219.21z M317.699,320.745
		c-17.359,19.364-40.021,32.383-65.096,37.621l3.414-58.849c0.176-3.037,2.235-4.543,3.113-5.05c0.878-0.507,3.213-1.538,5.93-0.172
		L317.699,320.745z M202.153,358.319c-25.543-5.416-48.146-18.879-65.02-37.605l52.566-26.41c2.72-1.366,5.053-0.334,5.93,0.172
		c0.877,0.507,2.938,2.013,3.114,5.05L202.153,358.319z M105.89,239.471c0-12.957,2.017-25.593,5.916-37.598l49.25,32.377
		c2.542,1.671,2.816,4.208,2.816,5.221c0,1.013-0.274,3.55-2.816,5.221l-49.212,32.352
		C107.986,265.206,105.89,252.58,105.89,239.471z M137.061,158.197c17.358-19.364,40.021-32.383,65.094-37.621l-3.413,58.849
		c-0.176,3.037-2.236,4.543-3.114,5.05c-0.876,0.506-3.21,1.538-5.93,0.172L137.061,158.197z M265.062,184.638
		c-2.715,1.364-5.053,0.334-5.931-0.173c-0.878-0.507-2.939-2.013-3.116-5.045l-3.408-58.796c0.184,0.039,0.368,0.077,0.552,0.117
		c2.726,21.663,11.939,41.333,25.624,57.003L265.062,184.638z M227.38,448.851C111.927,448.851,18,354.923,18,239.471
		c0-115.453,93.927-209.38,209.38-209.38c17.533,0,34.843,2.165,51.648,6.428c-9.006,10.241-16.092,22.206-20.696,35.331
		c-10.157-1.863-20.531-2.819-30.952-2.819c-93.981,0-170.44,76.459-170.44,170.44s76.459,170.44,170.44,170.44
		s170.44-76.459,170.44-170.44c0-10.421-0.956-20.794-2.818-30.948c13.127-4.605,25.094-11.693,35.337-20.7
		c4.259,16.784,6.422,34.095,6.422,51.649C436.76,354.923,342.833,448.851,227.38,448.851z"
      />
      <path
        d="M359.583,185.506c43.139,0,78.236-35.096,78.236-78.236c0-43.14-35.097-78.236-78.236-78.236s-78.236,35.096-78.236,78.236
		C281.347,150.409,316.444,185.506,359.583,185.506z M359.583,167.351c-30.07,0-55.048-22.206-59.405-51.08h4.458
		c4.971,0,9-4.03,9-9s-4.029-9-9-9h-4.458c0.676-4.485,1.863-8.801,3.477-12.906l3.834,2.213c1.417,0.818,2.964,1.207,4.49,1.207
		c3.111,0,6.137-1.615,7.804-4.502c2.484-4.305,1.009-9.809-3.296-12.294l-3.81-2.199c2.788-3.482,5.953-6.646,9.435-9.433
		l2.208,3.823c1.668,2.887,4.692,4.5,7.803,4.5c1.527,0,3.074-0.389,4.492-1.208c4.305-2.486,5.778-7.99,3.293-12.295l-2.218-3.841
		c4.1-1.611,8.411-2.796,12.89-3.472v4.463c0,4.97,4.029,9,9,9s9-4.03,9-9v-4.464c4.484,0.676,8.801,1.863,12.905,3.476
		l-2.222,3.852c-2.484,4.305-1.009,9.809,3.297,12.293c1.417,0.818,2.964,1.206,4.489,1.206c3.111,0,6.138-1.615,7.804-4.503
		l2.208-3.827c3.481,2.787,6.646,5.951,9.433,9.433L402.671,72c-4.305,2.486-5.778,7.99-3.293,12.295
		c1.668,2.887,4.692,4.5,7.803,4.5c1.527,0,3.075-0.389,4.492-1.208l3.841-2.218c1.612,4.103,2.799,8.419,3.475,12.902h-4.466
		c-4.971,0-9,4.03-9,9s4.029,9,9,9h4.466C414.632,145.145,389.654,167.351,359.583,167.351z"
      />
      <path
        d="M349.431,84.389c-3.516-3.515-9.213-3.515-12.729,0c-3.515,3.515-3.515,9.213,0,12.728l16.517,16.517
		c1.758,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636c3.515-3.515,3.515-9.213,0-12.728L349.431,84.389z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>