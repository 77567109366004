<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 463.478 463.478"
    style="enable-background: new 0 0 463.478 463.478"
    xml:space="preserve"
    fill="currentColor"
  >
    <path
      d="M463.391,333.138L436.349,139.6c-0.565-4.044-3.782-7.201-7.835-7.689c-130.57-15.754-262.98-15.754-393.55,0
	c-4.054,0.489-7.271,3.646-7.835,7.689L0.087,333.138c-0.383,2.744,0.519,5.512,2.445,7.503s4.66,2.985,7.417,2.692
	c147.168-15.601,296.41-15.601,443.58,0c0.317,0.034,0.634,0.05,0.949,0.05c2.425,0,4.764-0.98,6.468-2.743
	C462.873,338.649,463.775,335.882,463.391,333.138z M270.209,173.058v10.499h-76.941v-10.499H270.209z M327.444,315.792l33.08-9.707
	h43.698c4.971,0,9-4.029,9-9s-4.029-9-9-9H276.729c-4.971,0-9,4.029-9,9s4.029,9,9,9h19.867l-26.895,7.892
	c-12.653-0.226-25.308-0.343-37.963-0.343c-32.145,0-64.288,0.735-96.39,2.191l33.18-9.739h43.704c4.971,0,9-4.029,9-9s-4.029-9-9-9
	H84.738c-4.971,0-9,4.029-9,9s4.029,9,9,9h19.533l-51.837,15.128c-10.987,0.93-21.965,1.936-32.932,3.038l24.492-175.285
	c23.692-2.755,47.444-4.984,71.232-6.692l-10.189,20.906l-44.785,14.339c-4.734,1.516-7.343,6.582-5.827,11.315
	c1.224,3.822,4.76,6.258,8.569,6.258c0.909,0,1.834-0.139,2.746-0.431l28.383-9.087l-12.219,25.072
	c-1.204,2.47-1.213,5.354-0.026,7.833s3.442,4.278,6.122,4.887l18,4.091l-18.179,20.093c-3.335,3.686-3.05,9.377,0.636,12.712
	c1.722,1.558,3.882,2.326,6.035,2.326c2.455,0,4.9-0.999,6.677-2.962l28.499-31.499c2.145-2.37,2.871-5.701,1.908-8.749
	c-0.963-3.048-3.47-5.357-6.587-6.065l-21.93-4.984l32.85-67.404c28.912-1.705,57.869-2.636,86.83-2.796v16.93h-32.866
	c-8.053,0-14.604,6.552-14.604,14.604v17.29c0,8.053,6.552,14.604,14.604,14.604h83.732c8.053,0,14.604-6.551,14.604-14.604v-17.29
	c0-8.053-6.552-14.604-14.604-14.604h-32.866v-16.93c59.718,0.33,119.418,3.938,178.745,10.838l24.491,175.285
	C405.24,320.359,366.372,317.544,327.444,315.792z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>