<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 441.861 441.861"
    style="enable-background: new 0 0 441.861 441.861"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M175.683,94.93h-56.969C53.255,94.93,0,148.185,0,213.644s53.255,118.713,118.714,118.713h56.969
		c4.042,0,7.589-2.695,8.671-6.59c20.387-73.352,20.387-150.895,0-224.248C183.271,97.625,179.725,94.93,175.683,94.93z M18,213.644
		C18,158.11,63.18,112.93,118.714,112.93h12.135c8.542,32.878,12.87,66.724,12.87,100.712c0,33.992-4.327,67.839-12.868,100.715
		h-12.136C63.18,314.357,18,269.177,18,213.644z M168.783,314.357h-19.376c8.165-32.932,12.312-66.753,12.312-100.715
		c0-33.959-4.147-67.779-12.313-100.712l19.377,0C185.931,178.969,185.931,248.318,168.783,314.357z"
      />
      <path
        d="M219.662,143.424l211.312,45.265c0.635,0.136,1.269,0.202,1.894,0.202c4.153,0,7.887-2.892,8.792-7.117
		c1.041-4.86-2.056-9.645-6.916-10.686l-211.312-45.265c-4.854-1.042-9.644,2.055-10.685,6.915
		C211.706,137.599,214.801,142.383,219.662,143.424z"
      />
      <path
        d="M434.743,223.769l-211.312-45.265c-4.858-1.042-9.644,2.056-10.685,6.915c-1.041,4.86,2.055,9.645,6.915,10.686
		l211.312,45.265c0.635,0.136,1.269,0.202,1.894,0.202c4.153,0,7.887-2.892,8.792-7.117
		C442.7,229.594,439.604,224.81,434.743,223.769z"
      />
      <path
        d="M434.743,276.448l-211.312-45.265c-4.858-1.044-9.644,2.056-10.685,6.915c-1.041,4.86,2.055,9.645,6.915,10.686
		l211.312,45.265c0.635,0.136,1.269,0.202,1.894,0.202c4.153,0,7.887-2.892,8.792-7.117
		C442.7,282.274,439.604,277.489,434.743,276.448z"
      />
      <path
        d="M434.743,329.129l-211.312-45.265c-4.854-1.039-9.644,2.055-10.685,6.915s2.055,9.645,6.915,10.686l211.312,45.265
		c0.635,0.136,1.269,0.202,1.894,0.202c4.153,0,7.887-2.892,8.792-7.117C442.7,334.954,439.604,330.17,434.743,329.129z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>