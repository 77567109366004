<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 417.225 417.225"
    style="enable-background: new 0 0 417.225 417.225"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M384.891,32.361C364.023,11.493,336.277,0,306.764,0c-29.512,0-57.258,11.493-78.127,32.361l-42.271,42.272
		c-24.621,24.62-24.621,64.681,0,89.301l0.535,0.535l-27.794,27.794c-0.453,0.453-0.856,0.953-1.203,1.491l-14.565,22.618
		l-22.618,14.565c-1.08,0.695-1.999,1.614-2.694,2.694l-14.565,22.618l-22.617,14.565c-1.08,0.696-1.999,1.614-2.694,2.694
		l-14.565,22.618l-22.617,14.565c-1.08,0.696-1.999,1.614-2.694,2.694l-14.469,22.47L4.437,347.33
		c-2.846,1.687-4.538,4.799-4.405,8.104l2.055,51.098c0.188,4.687,3.944,8.443,8.631,8.631l51.098,2.054
		c0.121,0.005,0.241,0.007,0.362,0.007c2.381,0,4.672-0.945,6.363-2.636l184.24-184.239l0.537,0.537
		c12.311,12.31,28.48,18.465,44.65,18.465c16.171,0,32.341-6.155,44.651-18.465l42.272-42.272
		C427.97,145.535,427.97,75.44,384.891,32.361z M34.808,350.257c1.202-0.713,2.222-1.695,2.979-2.87l14.564-22.618l22.618-14.565
		c1.08-0.696,1.999-1.614,2.694-2.694l14.565-22.618l22.617-14.565c1.08-0.696,1.999-1.614,2.694-2.694l14.565-22.618l22.617-14.565
		c1.08-0.695,1.999-1.614,2.694-2.694l15.099-23.446l27.113-27.113l21.16,21.16L21.56,397.585l-1.822-0.073l-1.505-37.434
		L34.808,350.257z M58.601,399.074l-12.569-0.505l187.484-187.483l6.536,6.536L58.601,399.074z M372.163,175.887l-42.272,42.272
		c-17.601,17.601-46.242,17.603-63.845,0l-66.953-66.953c-8.526-8.527-13.223-19.864-13.223-31.922
		c0-12.059,4.696-23.396,13.223-31.923l42.272-42.272C258.834,27.62,282.06,18,306.764,18c24.705,0,47.931,9.62,65.398,27.089
		l6.365-6.364l-6.364,6.364C408.224,81.15,408.224,139.826,372.163,175.887z"
      />
      <path
        d="M355.155,63.573c-19.228-19.823-49.255-25.904-74.719-15.132c-2.746,1.162-4.738,3.609-5.32,6.533s0.323,5.948,2.416,8.072
		l76.065,77.202c1.707,1.733,4.023,2.684,6.411,2.684c0.549,0,1.102-0.05,1.651-0.153c2.94-0.549,5.416-2.523,6.606-5.268
		C379.262,112.143,374.116,83.121,355.155,63.573z M355.556,116.588l-54.333-55.144c14.956-1.627,30.279,3.596,41.013,14.661
		C352.772,86.969,357.459,101.872,355.556,116.588z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>