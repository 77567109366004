<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 476.977 476.977"
    style="enable-background: new 0 0 476.977 476.977"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M208.872,420.405c0,16.331,13.286,29.616,29.616,29.616c16.33,0,29.616-13.286,29.616-29.616s-13.286-29.616-29.616-29.616
		C222.158,390.789,208.872,404.074,208.872,420.405z M250.104,420.405c0,6.405-5.211,11.616-11.616,11.616
		c-6.405,0-11.616-5.211-11.616-11.616s5.211-11.616,11.616-11.616C244.893,408.789,250.104,414,250.104,420.405z"
      />
      <path
        d="M238.489,93.7c-14.847,0-26.926,12.079-26.926,26.926s12.079,26.926,26.926,26.926s26.925-12.079,26.925-26.926
		S253.335,93.7,238.489,93.7z M238.489,129.552c-4.922,0-8.926-4.004-8.926-8.926s4.004-8.926,8.926-8.926
		c4.921,0,8.925,4.004,8.925,8.926S243.41,129.552,238.489,129.552z"
      />
      <path
        d="M323.671,0H153.306c-4.971,0-9,4.03-9,9v185.749c0,4.971,4.029,9,9,9h38.187c4.971,0,9-4.029,9-9
		c0-10.304,6.216-19.179,15.092-23.086l-5.063,185.778c-0.267,9.281-7.749,16.551-17.034,16.551c-4.971,0-9,4.029-9,9v39.984
		c0,29.776,24.225,54,54.001,54c29.776,0,54-24.225,54-54v-39.984c0-4.971-4.029-9-9-9c-9.285,0-16.767-7.27-17.034-16.551
		l-5.063-185.778c8.876,3.907,15.092,12.782,15.092,23.086c0,4.971,4.029,9,9,9h38.187c4.971,0,9-4.029,9-9V9
		C332.671,4.03,328.642,0,323.671,0z M314.671,18v9.529H162.306V18H314.671z M314.671,45.529v11.058H162.306V45.529H314.671z
		 M274.489,390.846v32.13c0,19.851-16.15,36-36.001,36c-19.851,0-36-16.15-36-36l0-32.131c14.717-3.841,25.566-16.918,26.026-32.9
		l5.135-188.41h9.679l5.135,188.41c0,0.004,0,0.009,0,0.014C248.922,373.929,259.772,387.005,274.489,390.846z M293.542,185.749
		c-4.151-19.524-21.528-34.214-42.271-34.214h-25.564c-20.744,0-38.12,14.689-42.271,34.214h-21.129V74.587h152.366v111.162H293.542
		z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>