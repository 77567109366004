<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 446 446"
    style="enable-background: new 0 0 446 446"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M415.983,211.561c0-11.593-9.431-21.024-21.024-21.024h-13.611c-7.71,0-14.446,4.185-18.105,10.39l-17.549-38.417
		c-7.28-15.946-21.827-27.805-38.914-31.722c-28.188-6.459-139.373-6.459-167.561,0c-17.086,3.917-31.633,15.776-38.911,31.719
		l-17.552,38.417c-3.659-6.204-10.394-10.387-18.104-10.387H51.041c-11.593,0-21.024,9.431-21.024,21.024
		c0,10.625,7.955,19.598,18.503,20.872l16.105,1.945l-16.241,16.241c-9.457,9.457-13.981,23.007-12.103,36.248l6.392,45.068
		c1.664,11.733,7.641,22.002,16.17,29.296v19.683c0,13.103,10.66,23.763,23.763,23.763h36.614c13.103,0,23.763-10.66,23.763-23.763
		v-8.258h160.04v8.258c0,13.103,10.66,23.763,23.763,23.763h36.605c13.103,0,23.763-10.66,23.763-23.763v-19.68
		c8.53-7.294,14.509-17.564,16.174-29.298l6.392-45.068c1.878-13.241-2.646-26.791-12.103-36.248l-16.241-16.241l16.104-1.945
		C408.028,231.159,415.983,222.186,415.983,211.561z M143.24,148.333c25.344-5.808,134.175-5.808,159.518,0
		c3.751,0.86,7.319,2.287,10.604,4.182c-39.615-4.727-141.132-4.726-180.726,0C135.922,150.62,139.489,149.192,143.24,148.333z
		 M113.933,175.999c4.241-2.088,8.81-3.619,13.633-4.545c31.213-6.022,159.651-6.022,190.88,0.001
		c4.815,0.925,9.381,2.455,13.619,4.541l21.274,46.565H92.663L113.933,175.999z M48.017,211.561c0-1.667,1.356-3.024,3.024-3.024
		h13.611c1.667,0,3.024,1.356,3.024,3.024v5.055l-16.998-2.053C49.161,214.379,48.017,213.089,48.017,211.561z M356.424,354.655
		H89.576c-13.287,0-24.868-9.068-28.322-21.616h323.493C381.291,345.587,369.71,354.655,356.424,354.655z M333.201,315.039
		l-6.385-20.438h63.627l-2.899,20.438H333.201z M307.959,294.6l6.385,20.438h-6.607l-6.385-20.438H307.959z M282.494,294.6
		l6.385,20.438H157.117l6.385-20.438H282.494z M138.259,315.039h-6.604l6.385-20.438h6.604L138.259,315.039z M112.798,315.039
		H58.456L55.557,294.6h63.626L112.798,315.039z M124.983,380.913c0,3.178-2.585,5.763-5.763,5.763H82.606
		c-3.178,0-5.763-2.585-5.763-5.763v-10.021c4.07,1.149,8.344,1.763,12.733,1.763h35.407V380.913z M369.153,380.913
		c0,3.178-2.585,5.763-5.763,5.763h-36.605c-3.178,0-5.763-2.585-5.763-5.763v-8.258h35.401c4.388,0,8.661-0.614,12.729-1.763
		V380.913z M391.753,276.6H54.247c0.875-4.963,3.239-9.628,6.864-13.253l22.788-22.787h278.204l22.787,22.787
		C388.513,266.972,390.877,271.637,391.753,276.6z M395.322,214.563l-16.998,2.053v-5.055c0-1.667,1.356-3.024,3.024-3.024h13.611
		c1.667,0,3.024,1.356,3.024,3.024C397.983,213.089,396.839,214.379,395.322,214.563z"
      />
      <path
        d="M439.378,71.961l-214-58.641c-1.557-0.427-3.2-0.427-4.757,0l-214,58.641C2.711,73.032,0,76.586,0,80.641V424
		c0,4.97,4.029,9,9,9h428c4.971,0,9-4.03,9-9V80.641C446,76.586,443.289,73.032,439.378,71.961z M223,31.332l205,56.174v16.802
		L225.378,48.785c-1.557-0.427-3.2-0.427-4.757,0L18,104.308V87.506L223,31.332z M18,415V122.971l205-56.174l205,56.174V415H18z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>