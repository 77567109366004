<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 470.641 470.641"
    style="enable-background: new 0 0 470.641 470.641"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M425.667,241.03l24.035-2.903c11.938-1.442,20.938-11.596,20.938-23.619c0-13.119-10.673-23.791-23.791-23.791h-16.743
		c-10.232,0-18.976,6.494-22.328,15.577l-23.417-51.261c-8.688-19.039-26.052-33.194-46.45-37.867
		c-0.942-0.215-1.968-0.426-3.06-0.631c-0.84-23.525-9.876-45.768-25.911-63.364c-17.918-19.665-42.422-31.175-68.998-32.409
		c-26.585-1.243-52.041,7.954-71.705,25.872c-19.665,17.918-31.175,42.422-32.409,68.998c-0.014,0.301-0.02,0.601-0.031,0.902
		c-1.098,0.207-2.13,0.418-3.074,0.634c-20.396,4.675-37.76,18.831-46.449,37.871l-23.415,51.245
		c-3.355-9.077-12.095-15.566-22.324-15.566H23.791C10.673,190.717,0,201.389,0,214.508c0,12.023,9.001,22.177,20.938,23.619
		l24.031,2.903l-22.988,22.989c-11.175,11.187-16.519,27.201-14.294,42.833l7.86,55.443c1.976,13.904,9.154,26.575,19.911,35.457
		v25.08c0,14.976,12.184,27.16,27.16,27.16h45.04c14.977,0,27.16-12.184,27.16-27.16v-12.232h201.009v12.232
		c0,14.976,12.184,27.16,27.16,27.16h45.028c14.977,0,27.16-12.184,27.16-27.16v-25.079c10.759-8.883,17.937-21.553,19.912-35.457
		l7.871-55.446c2.214-15.649-3.135-31.662-14.308-42.834L425.667,241.03z M421.075,385.886c-6.38,4.392-13.858,6.713-21.627,6.713
		H71.188c-7.769,0-15.247-2.321-21.63-6.715c-8.2-5.64-13.883-14.307-15.827-24.015h403.173
		C434.961,371.577,429.278,380.244,421.075,385.886z M341.351,314.585l9.149,29.284h-12.465l-9.148-29.284H341.351z
		 M310.028,314.585l9.148,29.284H151.46l9.148-29.284H310.028z M132.602,343.869h-12.461l9.149-29.284h12.461L132.602,343.869z
		 M101.282,343.869H31.115l-4.151-29.284h83.467L101.282,343.869z M369.359,343.869l-9.149-29.284h83.471l-4.156,29.284H369.359z
		 M424.315,214.508c0-3.193,2.598-5.791,5.791-5.791h16.743c3.193,0,5.791,2.598,5.791,5.791c0,2.926-2.191,5.398-5.097,5.749
		l-23.229,2.806V214.508z M398.877,230.109h-28.958l-50.879-55.836c2.906-4.487,5.44-9.189,7.574-14.072
		c11.68,0.828,20.774,1.832,26.511,2.936c6.35,1.222,12.371,3.27,17.939,6.094L398.877,230.109z M302.403,193.873
		c1.859-1.694,3.644-3.447,5.356-5.254l69.1,75.833c2.785,3.057,2.564,7.81-0.492,10.595s-7.811,2.564-10.596-0.492l-69.117-75.852
		C298.616,197.165,300.542,195.569,302.403,193.873z M354.717,145.133c-5.555-0.962-13.021-1.816-22.311-2.551
		c0.591-2.594,1.085-5.22,1.469-7.874c0.006,0.001,0.012,0.002,0.018,0.004C341.621,136.482,348.746,140.119,354.717,145.133z
		 M153.809,116.466c1.012-21.772,10.441-41.848,26.552-56.528c15.152-13.807,34.508-21.287,54.87-21.287
		c1.288,0,2.581,0.03,3.877,0.09c21.772,1.011,41.848,10.441,56.528,26.552c30.305,33.258,27.902,84.97-5.355,115.275
		c-16.11,14.68-36.974,22.202-58.747,21.197c-21.772-1.012-41.848-10.441-56.528-26.552
		C160.325,159.102,152.797,138.239,153.809,116.466z M136.741,134.713c0.003-0.001,0.007-0.001,0.01-0.002
		c0.383,2.649,0.868,5.274,1.46,7.87c-9.304,0.741-16.765,1.596-22.292,2.554C121.891,140.12,129.015,136.483,136.741,134.713z
		 M99.587,169.224c5.563-2.819,11.584-4.865,17.941-6.088c5.7-1.099,14.793-2.105,26.503-2.941
		c4.308,9.822,10.234,18.982,17.669,27.141c17.919,19.665,42.423,31.174,68.998,32.409c1.583,0.074,3.157,0.11,4.731,0.11
		c16.182,0,31.835-3.895,45.899-11.248l19.593,21.502H71.771L99.587,169.224z M18,214.508c0-3.193,2.598-5.791,5.791-5.791h16.743
		c3.193,0,5.791,2.598,5.791,5.791v8.555l-23.229-2.806C20.191,219.906,18,217.434,18,214.508z M63.346,248.109h253.978
		l35.144,38.568c5.023,5.514,11.93,8.313,18.859,8.313c6.133,0,12.285-2.193,17.164-6.64c10.394-9.471,11.144-25.631,1.674-36.024
		l-3.844-4.218h20.971l28.633,28.634c5.35,5.35,8.622,12.424,9.364,19.842H25.354c0.739-7.414,4.009-14.488,9.358-19.842
		L63.346,248.109z M116.819,422.831c0,5.051-4.109,9.16-9.16,9.16h-45.04c-5.051,0-9.16-4.109-9.16-9.16v-15.098
		c5.671,1.885,11.639,2.866,17.729,2.866h45.631V422.831z M408.017,431.991h-45.028c-5.051,0-9.16-4.109-9.16-9.16v-12.232h45.62
		c6.091,0,12.058-0.98,17.729-2.865v15.098C417.177,427.882,413.067,431.991,408.017,431.991z"
      />
      <path
        d="M231.913,193.6c1.167,0.055,2.328,0.082,3.488,0.082c18.321-0.001,35.74-6.732,49.373-19.155
		c29.927-27.269,32.089-73.801,4.819-103.728c-13.209-14.498-31.274-22.982-50.866-23.893c-19.604-0.922-38.365,5.864-52.861,19.073
		c-29.927,27.269-32.089,73.801-4.819,103.728C194.256,184.205,212.321,192.69,231.913,193.6z M197.989,79.284
		c10.292-9.378,23.438-14.459,37.27-14.459c0.875,0,1.754,0.021,2.634,0.062c14.789,0.687,28.425,7.092,38.396,18.035
		c20.584,22.59,18.952,57.715-3.638,78.3c-10.942,9.972-25.116,15.09-39.903,14.397c-14.789-0.687-28.425-7.092-38.396-18.035
		C173.768,134.994,175.399,99.869,197.989,79.284z"
      />
      <path
        d="M255.184,142.051c-3.674,3.348-3.938,9.04-0.591,12.714c1.775,1.949,4.211,2.938,6.655,2.938
		c2.163,0,4.334-0.775,6.059-2.348c9.377-8.544,14.864-20.228,15.453-32.899c0.589-12.672-3.793-24.814-12.337-34.19
		c-3.349-3.673-9.041-3.938-12.714-0.59c-3.675,3.348-3.938,9.041-0.591,12.714c5.306,5.822,8.026,13.362,7.661,21.231
		S261.006,136.746,255.184,142.051z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>