<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 470.64 470.64"
    style="enable-background: new 0 0 470.64 470.64"
    xml:space="preserve"
    fill="currentColor"
  >
    <path
      d="M425.666,195.738l24.037-2.903c11.936-1.442,20.938-11.596,20.938-23.619c0-13.118-10.672-23.791-23.791-23.791h-16.744
	c-10.231,0-18.973,6.492-22.326,15.573l-23.417-51.26c-8.689-19.033-26.052-33.188-46.446-37.863
	c-34.518-7.91-170.674-7.911-205.194,0c-20.394,4.676-37.756,18.83-46.444,37.861l-23.419,51.259
	c-3.354-9.079-12.095-15.569-22.325-15.569H23.791C10.672,145.425,0,156.098,0,169.216c0,12.023,9.001,22.177,20.938,23.619
	l24.036,2.903l-22.988,22.989C10.811,229.901,5.465,245.914,7.684,261.56l7.863,55.439c2.021,14.249,9.408,26.675,19.912,35.36
	v25.179c0,14.976,12.184,27.16,27.16,27.16h45.039c14.976,0,27.16-12.184,27.16-27.16V365.31h201.009v12.229
	c0,14.976,12.184,27.16,27.16,27.16h45.028c14.976,0,27.16-12.184,27.16-27.16v-25.176c10.506-8.685,17.895-21.113,19.916-35.364
	l7.863-55.439c2.219-15.647-3.127-31.659-14.301-42.834L425.666,195.738z M399.447,347.31H71.193
	c-18.192,0-33.927-13.07-37.458-30.733h403.17C433.374,334.239,417.639,347.31,399.447,347.31z M341.352,269.293l9.149,29.284
	h-12.466l-9.149-29.284H341.352z M310.028,269.293l9.149,29.284H151.46l9.149-29.284H310.028z M132.602,298.577H120.14l9.149-29.284
	h12.462L132.602,298.577z M101.282,298.577H31.114l-4.154-29.284h83.47L101.282,298.577z M369.358,298.577l-9.149-29.284h83.47
	l-4.154,29.284H369.358z M424.315,169.216c0-3.193,2.598-5.791,5.791-5.791h16.744c3.193,0,5.791,2.598,5.791,5.791
	c0,2.927-2.191,5.398-5.096,5.749l-23.229,2.805V169.216z M136.744,89.419c31.324-7.178,165.829-7.178,197.151,0
	c7.728,1.772,14.852,5.409,20.825,10.425c-42.882-7.43-195.963-7.429-238.802,0.002C121.891,94.829,129.016,91.191,136.744,89.419z
	 M99.582,123.932c5.565-2.818,11.589-4.863,17.951-6.086c38.524-7.432,197.046-7.433,235.587,0.001
	c6.354,1.221,12.373,3.265,17.936,6.08l27.819,60.89H71.768L99.582,123.932z M18,169.216c0-3.193,2.598-5.791,5.791-5.791h16.744
	c3.193,0,5.791,2.598,5.791,5.791v8.555l-23.228-2.805C20.191,174.614,18,172.142,18,169.216z M63.351,202.817h343.938
	l28.638,28.638c5.349,5.349,8.619,12.422,9.36,19.838H25.353c0.741-7.416,4.011-14.489,9.36-19.838L63.351,202.817z
	 M116.819,377.539c0,5.051-4.109,9.16-9.16,9.16H62.62c-5.051,0-9.16-4.109-9.16-9.16v-15.138
	c5.604,1.886,11.573,2.909,17.734,2.909h45.625V377.539z M417.177,377.539c0,5.051-4.109,9.16-9.16,9.16h-45.028
	c-5.051,0-9.16-4.109-9.16-9.16V365.31h45.619c6.16,0,12.127-1.022,17.73-2.907V377.539z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>