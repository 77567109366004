<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 487.52 487.52"
    style="enable-background: new 0 0 487.52 487.52"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M243.757,143.887c-69.016,0-125.164,56.148-125.164,125.163c0,69.016,56.148,125.164,125.164,125.164
		s125.164-56.148,125.164-125.164C368.921,200.035,312.773,143.887,243.757,143.887z M243.757,376.117
		c-46.298,0-85.822-29.54-100.725-70.761l10.338-2.77c4.801-1.287,7.651-6.222,6.364-11.022c-1.287-4.802-6.223-7.65-11.022-6.364
		l-10.348,2.772c-1.1-6.144-1.674-12.467-1.674-18.922c0-6.456,0.574-12.78,1.675-18.924l10.347,2.772
		c0.78,0.209,1.564,0.309,2.335,0.309c3.973,0,7.61-2.652,8.688-6.673c1.287-4.801-1.563-9.736-6.364-11.023l-10.338-2.77
		c4.361-12.061,10.831-23.118,18.961-32.731l7.528,7.528c1.757,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636
		c3.515-3.515,3.515-9.213,0-12.728l-7.527-7.527c9.613-8.129,20.671-14.599,32.732-18.959l2.769,10.335
		c1.078,4.021,4.714,6.673,8.688,6.673c0.771,0,1.555-0.1,2.335-0.309c4.801-1.287,7.651-6.222,6.364-11.022l-2.771-10.344
		c6.143-1.1,12.465-1.674,18.919-1.674c6.456,0,12.78,0.574,18.925,1.675L259.911,174c-1.287,4.801,1.563,9.736,6.364,11.023
		c0.78,0.209,1.564,0.309,2.335,0.309c3.973,0,7.61-2.652,8.688-6.673l2.769-10.333c12.06,4.361,23.117,10.831,32.73,18.96
		l-7.525,7.524c-3.515,3.515-3.515,9.213,0,12.728c1.757,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636l7.524-7.524
		c8.129,9.613,14.599,20.67,18.959,32.73l-10.333,2.769c-4.801,1.287-7.65,6.222-6.364,11.023c1.078,4.021,4.714,6.673,8.688,6.673
		c0.771,0,1.555-0.1,2.335-0.309l10.341-2.771c1.1,6.144,1.674,12.467,1.674,18.922c0,6.455-0.574,12.778-1.674,18.921
		l-10.342-2.771c-4.799-1.286-9.736,1.563-11.022,6.364c-1.287,4.801,1.563,9.736,6.364,11.022l10.333,2.769
		C329.58,346.576,290.056,376.117,243.757,376.117z"
      />
      <path
        d="M218.374,262.699c-1.696,6.78-0.65,13.815,2.944,19.809c3.595,5.994,9.308,10.229,16.088,11.926
		c2.121,0.53,4.266,0.793,6.397,0.793c1.64,0,3.27-0.165,4.875-0.473l2.835,4.728c1.688,2.813,4.668,4.373,7.727,4.373
		c1.575,0,3.172-0.414,4.621-1.283c4.263-2.557,5.646-8.084,3.089-12.347l-2.833-4.723c2.363-2.921,4.084-6.346,5.023-10.1
		c1.696-6.78,0.65-13.815-2.944-19.81v0c-5.794-9.663-16.885-14.236-27.359-12.242l-26.161-43.62
		c-2.557-4.263-8.084-5.646-12.347-3.089s-5.646,8.084-3.089,12.347l26.156,43.612C221.033,255.521,219.313,258.945,218.374,262.699
		z M245.739,261.129c2.116,0.529,3.899,1.851,5.021,3.721v0c1.122,1.871,1.448,4.066,0.919,6.182
		c-0.529,2.116-1.851,3.899-3.722,5.021c-1.87,1.122-4.064,1.449-6.182,0.919c-2.116-0.529-3.899-1.851-5.021-3.722
		c-1.122-1.87-1.448-4.066-0.919-6.182c0.529-2.116,1.851-3.899,3.722-5.021c1.286-0.771,2.725-1.166,4.186-1.166
		C244.408,260.881,245.078,260.963,245.739,261.129z"
      />
      <path
        d="M357.84,67.796c-40.437,0-78.565,18.97-103.046,50.986c-3.645-0.265-7.324-0.406-11.036-0.406
		c-3.71,0-7.388,0.141-11.031,0.406c-24.482-32.015-62.613-50.986-103.056-50.986C58.17,67.796,0,125.97,0,197.476
		c0,30.985,11.104,60.977,31.265,84.45c18.801,21.89,44.353,36.882,72.402,42.6c22.139,55.709,76.588,95.197,140.09,95.197
		c63.502,0,117.952-39.489,140.09-95.198c28.049-5.719,53.599-20.711,72.402-42.599c20.165-23.474,31.271-53.466,31.271-84.451
		C487.52,125.97,429.346,67.796,357.84,67.796z M44.92,270.198C27.561,249.988,18,224.161,18,197.476
		c0-61.581,50.095-111.68,111.67-111.68c31.356,0,61.098,13.246,82.107,36.007c-3.545,0.769-7.044,1.662-10.492,2.677
		c-19.236-18.882-44.626-29.274-71.615-29.274c-27.315,0-52.996,10.639-72.313,29.956C38.039,144.48,27.4,170.161,27.4,197.476
		c0,22.51,7.174,43.864,20.748,61.751c11.947,15.745,28.47,27.824,46.965,34.485c0.594,3.594,1.315,7.146,2.16,10.651
		C77.081,298.242,58.852,286.419,44.92,270.198z M127.684,173.084l-17.548-25.103c-2.848-4.074-8.46-5.068-12.533-2.22
		c-4.074,2.848-5.068,8.459-2.22,12.533l16.97,24.277c-3.451,4.007-5.544,9.215-5.544,14.906c0,2.261,0.347,4.498,0.998,6.643
		c-9.434,19.673-14.724,41.695-14.724,64.931c0,1.458,0.026,2.909,0.067,4.357C81.185,267.623,70.57,259,62.487,248.347
		c-4.817-6.348-8.649-13.229-11.457-20.485l6.099-1.634c4.801-1.287,7.651-6.222,6.364-11.022c-1.287-4.802-6.224-7.65-11.022-6.364
		l-6.073,1.627c-0.655-4.261-0.997-8.598-0.997-12.992c0-4.395,0.345-8.736,1.002-12.999l6.069,1.626
		c0.78,0.209,1.563,0.309,2.335,0.309c3.973,0,7.61-2.652,8.688-6.673c1.287-4.801-1.563-9.736-6.364-11.022l-6.079-1.629
		c3.119-8.077,7.483-15.657,13.016-22.495l4.432,4.432c1.757,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636
		c3.515-3.515,3.515-9.213,0-12.728l-4.431-4.431c6.838-5.532,14.418-9.895,22.495-13.013l1.628,6.076
		c1.078,4.021,4.714,6.673,8.688,6.673c0.771,0,1.555-0.1,2.335-0.309c4.801-1.287,7.651-6.222,6.364-11.022l-1.625-6.064
		c4.261-0.656,8.599-1,12.991-1c4.394,0,8.734,0.345,12.996,1.001l-1.625,6.063c-1.287,4.801,1.563,9.736,6.364,11.023
		c0.78,0.209,1.564,0.309,2.335,0.309c3.973,0,7.61-2.652,8.688-6.673l1.628-6.074c7.921,3.059,15.361,7.32,22.094,12.702
		C160.95,141.093,142.337,155.391,127.684,173.084z M243.757,401.724c-73.157,0-132.674-59.517-132.674-132.674
		s59.517-132.674,132.674-132.674s132.674,59.517,132.674,132.674S316.914,401.724,243.757,401.724z M327.464,118.854l1.628,6.075
		c1.078,4.021,4.714,6.673,8.688,6.673c0.771,0,1.555-0.1,2.335-0.309c4.801-1.287,7.651-6.222,6.364-11.022l-1.625-6.064
		c4.259-0.656,8.596-1,12.987-1c4.396,0,8.737,0.345,13,1.001l-1.625,6.063c-1.287,4.801,1.563,9.736,6.364,11.023
		c0.78,0.209,1.564,0.309,2.335,0.309c3.973,0,7.61-2.652,8.688-6.673l1.628-6.074c8.076,3.119,15.655,7.484,22.489,13.018
		l-4.425,4.425c-3.515,3.515-3.515,9.213,0,12.728c1.757,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636l4.423-4.423
		c5.532,6.836,9.896,14.415,13.015,22.488l-6.068,1.626c-4.801,1.287-7.651,6.222-6.364,11.022c1.078,4.021,4.714,6.673,8.688,6.673
		c0.771,0,1.555-0.1,2.335-0.309l6.059-1.624c0.657,4.263,1.002,8.603,1.002,12.997c0,4.393-0.343,8.729-0.997,12.99l-6.064-1.625
		c-4.798-1.285-9.736,1.563-11.022,6.364c-1.287,4.801,1.563,9.736,6.364,11.022l6.09,1.632c-2.809,7.258-6.641,14.14-11.458,20.488
		c-8.082,10.651-18.695,19.273-30.659,25.057c0.041-1.447,0.067-2.898,0.067-4.355c0-23.234-5.29-45.255-14.722-64.926
		c0.653-2.144,1.001-4.383,1.001-6.647c0-5.819-2.188-11.135-5.78-15.175l15.41-24.072c2.68-4.187,1.458-9.752-2.728-12.433
		c-4.187-2.679-9.752-1.458-12.433,2.728l-15.562,24.309c-14.62-17.575-33.152-31.784-54.25-41.275
		C312.102,126.175,319.543,121.913,327.464,118.854z M442.596,270.198c-13.934,16.22-32.162,28.043-52.354,34.165
		c0.845-3.505,1.566-7.057,2.16-10.651c18.494-6.662,35.014-18.74,46.961-34.484c13.573-17.888,20.748-39.241,20.748-61.751
		c0-27.202-10.558-52.784-29.729-72.071c-0.075-0.081-0.143-0.167-0.222-0.246c-0.079-0.079-0.165-0.147-0.246-0.223
		c-19.276-19.173-44.856-29.73-72.073-29.73c-26.997,0-52.396,10.403-71.607,29.275c-3.448-1.015-6.946-1.908-10.491-2.677
		c21.008-22.762,50.747-36.008,82.098-36.008c61.581,0,111.68,50.1,111.68,111.68C469.52,224.16,459.958,249.987,442.596,270.198z"
      />
      <path
        d="M267.257,332.137h-47c-4.971,0-9,4.029-9,9s4.029,9,9,9h47c4.971,0,9-4.029,9-9S272.228,332.137,267.257,332.137z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>