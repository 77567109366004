<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 451.043 451.043"
    style="enable-background: new 0 0 451.043 451.043"
    xml:space="preserve"
		fill="currentColor"
  >
    <g>
      <path
        d="M384.99,66.054C342.394,23.459,285.761,0,225.521,0C165.283,0,108.649,23.459,66.054,66.054S0,165.283,0,225.522
		S23.458,342.394,66.054,384.99s99.229,66.054,159.468,66.054c60.239,0,116.873-23.458,159.468-66.054
		c42.595-42.595,66.054-99.229,66.054-159.468S427.585,108.649,384.99,66.054z M225.521,433.043
		C111.094,433.043,18,339.949,18,225.522S111.094,18,225.521,18c114.428,0,207.522,93.094,207.522,207.521
		S339.95,433.043,225.521,433.043z"
      />
      <path
        d="M225.521,38.13c-103.329,0-187.392,84.063-187.392,187.392s84.063,187.393,187.392,187.393s187.393-84.064,187.393-187.393
		S328.85,38.13,225.521,38.13z M247.834,359.302h-44.641c-16.87-56.333-59.586-101.839-114.825-122.151l15.428-65.49
		c39.619-9.641,80.54-14.541,121.728-14.541c41.202,0,82.119,4.902,121.724,14.551l15.425,65.474
		C307.427,257.452,264.705,302.961,247.834,359.302z M56.129,225.522c0-14.331,1.796-28.25,5.163-41.55
		c7.531-2.541,15.146-4.912,22.784-7.099l-12.931,54.889c-4.915-1.282-9.909-2.375-14.978-3.261
		C56.15,227.509,56.129,226.518,56.129,225.522z M379.898,231.759l-12.929-54.881c7.645,2.187,15.26,4.558,22.784,7.1
		c3.365,13.298,5.161,27.215,5.161,41.544c0,0.995-0.021,1.986-0.038,2.977C389.807,229.385,384.813,230.477,379.898,231.759z
		 M225.521,56.13c71.27,0,132.395,44.246,157.365,106.709c-8.598-2.664-17.266-5.112-25.917-7.308
		c-42.686-10.888-86.91-16.409-131.445-16.409c-44.517,0-88.744,5.518-131.45,16.398c-8.646,2.2-17.313,4.651-25.917,7.317
		C93.128,100.374,154.252,56.13,225.521,56.13z M57.492,247.061c70.855,14.342,125.07,72.703,134.195,144.456
		C121.328,377.198,66.678,319.13,57.492,247.061z M210.102,394.205c-0.572-5.71-1.397-11.349-2.472-16.903h35.769
		c-1.076,5.554-1.9,11.194-2.472,16.905c-5.076,0.46-10.211,0.708-15.405,0.708C220.323,394.914,215.182,394.666,210.102,394.205z
		 M259.341,391.52c9.125-71.766,63.347-130.129,134.211-144.462C384.366,319.134,329.708,377.205,259.341,391.52z"
      />
      <path
        d="M225.521,174.343c-28.22,0-51.179,22.959-51.179,51.179c0,28.221,22.959,51.18,51.179,51.18
		c28.221,0,51.18-22.959,51.18-51.18C276.701,197.302,253.742,174.343,225.521,174.343z M225.521,258.701
		c-18.295,0-33.179-14.884-33.179-33.18c0-18.295,14.884-33.179,33.179-33.179c18.295,0,33.18,14.884,33.18,33.179
		C258.701,243.817,243.817,258.701,225.521,258.701z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>