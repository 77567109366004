var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select-vehicle-custom-dropdown",class:{
    'is-disabled': _vm.disabled,
    'is-expanded': _vm.isExpanded,
    'is-scaled': _vm.isScaled,
  }},[(!_vm.selectedOption)?_c('div',{staticClass:"dropdown-header",on:{"click":_vm.expandStep}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('chevron-down-svg')],1):_c('div',{staticClass:"dropdown-header option-selected"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.optionsEntity))]),_c('div',{staticClass:"selected-option-val-wrapper",on:{"click":_vm.deselectOption}},[_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(["make", "model"].includes(_vm.optionsEntity) ? _vm.selectedOption.name : _vm.selectedOption)+" ")]),_c('times-svg')],1)]),(_vm.isExpanded && _vm.withSearch)?_c('div',{staticClass:"search-input-wrapper"},[_c('input',{staticClass:"el-input__inner search-input",attrs:{"placeholder":`Search ${_vm.optionsEntity}`,"prefix-icon":"el-icon-search"},domProps:{"value":_vm.searchedOption},on:{"input":_vm.handleSearchInput}}),_c('i',{staticClass:"el-icon-search"})]):_vm._e(),_c('el-collapse-transition',[(_vm.isExpanded)?_c('div',[(['make', 'model'].includes(_vm.optionsEntity))?_c('div',{staticClass:"dropdown-content"},_vm._l((_vm.displayedOptions),function(option,optionIdx){return _c('div',{key:optionIdx,staticClass:"option",class:{
            'is-selected':
              _vm.componentInternalSelectedOption &&
              _vm.componentInternalSelectedOption.id === option.id,
          },on:{"click":function($event){return _vm.selectOption(option)}}},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(option.name))])])}),0):_c('div',{staticClass:"dropdown-content"},_vm._l((_vm.displayedOptions),function(option,optionIdx){return _c('div',{key:optionIdx,staticClass:"option",class:{
            'is-selected': _vm.componentInternalSelectedOption === option,
            'is-disabled': _vm.minValue !== null && parseInt(option) < _vm.minValue,
            'is-info-text': _vm.minValue !== null && option === _vm.minValueErrorText,
          },on:{"click":function($event){return _vm.selectOption(option)}}},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(option))])])}),0)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }