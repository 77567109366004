<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 424.513 424.513"
    style="enable-background: new 0 0 424.513 424.513"
    xml:space="preserve"
  >
    <path
      d="M424.513,45.469c0-2.387-0.948-4.676-2.636-6.364l-24.724-24.724c-1.688-1.688-3.978-2.636-6.364-2.636
	s-4.676,0.948-6.364,2.636l-32.044,32.044c-4.66-1.795-9.654-2.745-14.798-2.745c-10.983,0-21.308,4.277-29.073,12.042
	c-0.667,0.667-1.226,1.436-1.654,2.276l-5.143,10.088c-2.495,4.896-6.402,8.803-11.298,11.298
	c-8.303,4.232-14.929,10.858-19.162,19.161c-2.495,4.896-6.402,8.802-11.299,11.299c-7.535,3.841-14.388,8.821-20.37,14.803
	l-52.816,52.816l-13.496-13.496c-3.515-3.515-9.213-3.515-12.728,0l-25.903,25.903c-3.515,3.515-3.515,9.213,0,12.728l7.96,7.96
	l-6.867,6.867l-7.96-7.96c-1.688-1.688-3.977-2.636-6.364-2.636c-2.387,0-4.676,0.948-6.364,2.636l-35.919,35.919
	c-3.515,3.515-3.515,9.213,0,12.728l7.96,7.96l-76.9,76.899c-13.585,13.586-13.585,35.692,0,49.278l16.909,16.909
	c1.757,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636c3.515-3.515,3.515-9.213,0-12.728l-16.909-16.909
	c-6.568-6.568-6.568-17.255,0-23.823l9.573-9.573l22.457,22.457l-6.833,6.833c-3.515,3.515-3.515,9.213,0,12.728
	c1.757,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636l6.833-6.833l28.821,28.821c1.688,1.688,3.977,2.636,6.364,2.636
	c2.387,0,4.676-0.948,6.364-2.636l60.962-60.962l7.96,7.96c1.688,1.688,3.977,2.636,6.364,2.636c2.387,0,4.676-0.948,6.364-2.636
	l35.919-35.919c3.515-3.515,3.515-9.213,0-12.728l-7.96-7.96l6.867-6.866l7.959,7.959c1.758,1.757,4.061,2.636,6.364,2.636
	s4.606-0.879,6.364-2.636l25.903-25.903c3.515-3.515,3.515-9.213,0-12.728l-13.496-13.496l52.815-52.816
	c5.982-5.982,10.963-12.836,14.804-20.37c2.496-4.896,6.403-8.804,11.299-11.3c8.304-4.232,14.93-10.858,19.161-19.16
	c2.496-4.897,6.403-8.804,11.298-11.299l10.088-5.142c0.841-0.429,1.609-0.987,2.277-1.654c7.766-7.766,12.042-18.09,12.042-29.073
	c0-5.144-0.949-10.138-2.745-14.798l32.044-32.044C423.565,50.145,424.513,47.856,424.513,45.469z M102.859,391.04l-57.641-57.641
	l54.598-54.598l57.641,57.641L102.859,391.04z M184.508,338.038L98.22,251.75l23.191-23.191l86.288,86.288L184.508,338.038z
	 M206.104,287.795l-57.641-57.641l6.867-6.867l57.641,57.641L206.104,287.795z M240.022,282.524l-86.288-86.288l13.175-13.175
	l86.289,86.288L240.022,282.524z M353.927,68.45l13.88,13.88c4.366,4.366,6.771,10.17,6.771,16.345
	c0,5.178-1.705,10.087-4.827,14.115l-46.283-46.283c4.027-3.121,8.937-4.826,14.115-4.826
	C343.757,61.68,349.562,64.085,353.927,68.45z M285.203,110.338l40.717,40.717c-6.317,4.007-11.449,9.52-15.031,16.156
	l-41.843-41.842C275.684,121.787,281.197,116.655,285.203,110.338z M246.066,236.762l-46.569-46.569l52.815-52.816
	c0.548-0.548,1.108-1.084,1.675-1.61l46.505,46.505c-0.526,0.567-1.062,1.126-1.611,1.675L246.066,236.762z M340.836,137.669
	c-0.318,0.624-0.66,1.231-1.023,1.822l-43.045-43.045c0.591-0.363,1.198-0.705,1.822-1.023c6.126-3.123,11.33-7.556,15.342-12.996
	l39.901,39.9C348.392,126.338,343.959,131.542,340.836,137.669z M379.594,68.66l-11.996-11.996l23.192-23.191l11.996,11.996
	L379.594,68.66z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>