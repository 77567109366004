<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 412 412"
    style="enable-background: new 0 0 412 412"
    xml:space="preserve"
    fill="currentColor"
  >
    <path
      d="M347,344h-28.752L227.115,6.653C226.055,2.727,222.493,0,218.427,0h-24.853c-4.066,0-7.628,2.727-8.688,6.653L93.752,344H65
	c-4.971,0-9,4.029-9,9v50c0,4.971,4.029,9,9,9h282c4.971,0,9-4.029,9-9v-50C356,348.029,351.971,344,347,344z M245.352,143.18
	h-78.704l13.948-51.63h50.809L245.352,143.18z M250.215,161.18l13.95,51.64h-116.33l13.95-51.64H250.215z M269.028,230.82
	l13.948,51.632H129.024l13.948-51.632H269.028z M200.465,18h11.071l15.007,55.55h-41.084L200.465,18z M124.162,300.452h163.677
	L299.603,344H112.397L124.162,300.452z M338,394H74v-32h264V394z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>