<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 474.547 474.547"
    style="enable-background: new 0 0 474.547 474.547"
    xml:space="preserve"
    fill="currentColor"
  >
    <path
      d="M469.094,196.424v-7.005c0-8.438-3.659-16.445-10.04-21.967l-43.217-37.399c-5.293-4.579-12.062-7.101-19.06-7.101H294.063
	c-6.333,0-12.597,2.112-17.637,5.947l-42.243,32.141l-55.835,9.907c-7.892,1.396-14.92,6.059-19.286,12.8l-2.91,4.502
	c-3.671,5.668-5.611,12.235-5.611,18.991c0,4.958,1.03,9.773,3.067,14.328c2.035,4.526,4.949,8.496,8.668,11.804l3.924,3.484
	c5.959,5.298,13.631,8.215,21.603,8.215h8.933c2.986,5.69,7.369,10.534,12.696,14.071H144v-131.99c0-4.971-4.029-9-9-9H37.5
	c-4.063,0-7.622,2.722-8.686,6.643l-28.5,105C0.105,230.563,0,231.356,0,232.152v71.99c0,4.971,4.029,9,9,9h26.891
	c-0.24,1.724-0.374,3.482-0.374,5.271c0,20.943,17.039,37.981,37.982,37.981c20.943,0,37.981-17.039,37.981-37.981
	c0-1.789-0.134-3.547-0.374-5.271h152.842c-0.24,1.724-0.374,3.482-0.374,5.271c0,20.943,17.039,37.981,37.982,37.981
	s37.981-17.039,37.981-37.981c0-1.789-0.134-3.547-0.374-5.271h11.268c-0.24,1.724-0.374,3.482-0.374,5.271
	c0,20.943,17.039,37.981,37.982,37.981s37.981-17.039,37.981-37.981c0-1.789-0.134-3.547-0.374-5.271h15.332c4.971,0,9-4.029,9-9
	v-36c0-4.971-4.029-9-9-9h-16.923c5.327-3.537,9.71-8.381,12.696-14.071h4.53c10.509,0,20.623-4.966,27.053-13.283
	c4.006-5.179,6.212-11.639,6.212-18.188C474.547,207.397,472.632,201.421,469.094,196.424z M423.536,160.52h-17.647l-4.806-18.69
	c1.073,0.451,2.082,1.061,2.975,1.834L423.536,160.52z M387.303,160.52h-47.948l4.473-19.569h38.443L387.303,160.52z
	 M287.326,143.223c1.925-1.465,4.317-2.272,6.737-2.272h31.301l-4.473,19.569h-56.299L287.326,143.223z M210.405,227.483
	c0-11.018,8.964-19.981,19.981-19.981s19.981,8.964,19.981,19.981c0,11.018-8.964,19.982-19.981,19.982
	S210.405,238.501,210.405,227.483z M44.383,136.152h33.615v72.201l-56.453,11.942L44.383,136.152z M73.499,338.395
	c-11.018,0-19.982-8.964-19.982-19.981c0-11.018,8.964-19.982,19.982-19.982c11.018,0,19.981,8.964,19.981,19.982
	C93.48,329.431,84.516,338.395,73.499,338.395z M73.499,280.431c-12.18,0-23.031,5.769-29.986,14.711H18v-25.496h15
	c4.971,0,9-4.029,9-9s-4.029-9-9-9H18v-12.203l70.86-14.99c4.161-0.88,7.137-4.552,7.137-8.805v-79.496H126l-0.005,158.99h-22.511
	C96.529,286.2,85.678,280.431,73.499,280.431z M301.557,338.395c-11.019,0-19.982-8.964-19.982-19.981
	c0-11.018,8.964-19.982,19.982-19.982c11.018,0,19.981,8.964,19.981,19.982C321.538,329.431,312.574,338.395,301.557,338.395z
	 M388.04,338.395c-11.019,0-19.982-8.964-19.982-19.981c0-11.018,8.964-19.982,19.982-19.982c11.018,0,19.981,8.964,19.981,19.982
	C408.021,329.431,399.058,338.395,388.04,338.395z M431.979,295.142h-13.954c-6.955-8.942-17.806-14.711-29.986-14.711
	s-23.031,5.769-29.986,14.711h-26.511c-6.955-8.942-17.806-14.711-29.986-14.711s-23.031,5.769-29.986,14.711H143.995v-18h287.984
	V295.142z M251.339,259.142c5.327-3.537,9.709-8.381,12.696-14.071h105.42c2.986,5.69,7.369,10.534,12.696,14.071H251.339z
	 M383.122,227.483c0-11.018,8.964-19.981,19.981-19.981c11.019,0,19.982,8.964,19.982,19.981c0,11.018-8.964,19.982-19.982,19.982
	C392.086,247.465,383.122,238.501,383.122,227.483z M454.096,220.776c-3.047,3.941-7.837,6.294-12.813,6.294h-0.207
	c-0.223-20.753-17.167-37.569-37.972-37.569s-37.748,16.816-37.971,37.569h-96.775c-0.223-20.753-17.166-37.569-37.971-37.569
	c-20.804,0-37.748,16.816-37.971,37.569h-4.611c-3.558,0-6.983-1.303-9.647-3.671l-3.922-3.482c-1.217-1.083-2.24-2.323-3.09-3.678
	h7.347c4.971,0,9-4.029,9-9s-4.029-9-9-9h-7.355c0.044-0.07,0.082-0.144,0.128-0.213l2.91-4.502
	c1.655-2.555,4.317-4.324,7.312-4.853l57.203-10.15h205.645l2.938,2.543c2.428,2.1,3.819,5.146,3.819,8.355v10.157
	c0,2.252,0.845,4.423,2.367,6.083c1.99,2.169,3.086,4.989,3.086,7.94C456.547,216.221,455.699,218.703,454.096,220.776z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>