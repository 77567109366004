<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 394.481 394.481"
    style="enable-background: new 0 0 394.481 394.481"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M349.949,99.962H145.623V80.964c3.853-1.019,6.697-4.518,6.697-8.691V38.254c0-4.971-4.029-9-9-9H46.969
		c-4.971,0-9,4.029-9,9v34.019c0,4.173,2.844,7.672,6.696,8.691v18.999h-0.132C19.978,99.962,0,119.94,0,144.495v176.2
		c0,24.555,19.978,44.532,44.533,44.532h305.416c24.555,0,44.532-19.977,44.532-44.532v-176.2
		C394.481,119.94,374.504,99.962,349.949,99.962z M55.969,47.254h78.352v16.019H55.969V47.254z M62.665,81.272h64.958v18.69H62.665
		V81.272z M376.481,320.695c0,14.63-11.902,26.532-26.532,26.532H44.533C29.902,347.228,18,335.325,18,320.695v-176.2
		c0-14.63,11.902-26.533,26.533-26.533h305.416c14.63,0,26.532,11.902,26.532,26.533V320.695z"
      />
      <path
        d="M361.716,196.792c-19.36-7.199-40.686-7.2-60.06,0.005c-15.316,5.694-32.186,5.694-47.506-0.003
		c-19.36-7.2-40.689-7.201-60.052,0c-0.026,0.01-0.052,0.02-0.078,0.029c-15.299,5.669-32.141,5.658-47.432-0.028
		c-19.364-7.201-40.691-7.199-60.054,0c-15.317,5.697-32.188,5.697-47.505,0c-4.659-1.734-9.84,0.64-11.572,5.298
		s0.64,9.84,5.299,11.573c19.363,7.202,40.69,7.201,60.052,0c15.319-5.697,32.19-5.695,47.508,0
		c9.682,3.601,19.853,5.401,30.026,5.4c10.171,0,20.345-1.8,30.025-5.4c0.027-0.01,0.056-0.021,0.083-0.031
		c15.295-5.667,32.135-5.657,47.43,0.031c19.361,7.2,40.687,7.201,60.063-0.004c15.314-5.694,32.184-5.694,47.498,0
		c4.657,1.732,9.84-0.64,11.572-5.299S366.375,198.525,361.716,196.792z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>