<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 470.64 470.64"
    style="enable-background: new 0 0 470.64 470.64"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M425.665,241.028l24.036-2.903c11.937-1.441,20.938-11.595,20.938-23.619c0-13.118-10.672-23.791-23.791-23.791h-16.744
		c-10.232,0-18.975,6.493-22.327,15.575l-23.418-51.259c-8.688-19.039-26.052-33.194-46.452-37.867
		c-0.944-0.216-1.973-0.427-3.066-0.633c-1.964-53.201-45.85-95.879-99.523-95.879c-53.673,0-97.559,42.677-99.523,95.879
		c-1.098,0.207-2.129,0.417-3.075,0.634c-20.397,4.675-37.76,18.831-46.448,37.87l-23.417,51.247
		c-3.355-9.077-12.095-15.566-22.324-15.566H23.791C10.672,190.715,0,201.387,0,214.505c0,12.024,9.001,22.178,20.938,23.619
		l24.037,2.903l-22.989,22.989C10.811,275.191,5.465,291.203,7.684,306.85l7.863,55.439c2.021,14.249,9.408,26.675,19.912,35.361
		v25.179c0,14.976,12.184,27.16,27.16,27.16h45.039c14.976,0,27.16-12.184,27.16-27.16v-12.229h201.009v12.229
		c0,14.976,12.184,27.16,27.16,27.16h45.028c14.976,0,27.16-12.184,27.16-27.16v-25.176c10.506-8.685,17.895-21.113,19.916-35.364
		l7.863-55.439c2.219-15.646-3.127-31.659-14.302-42.833L425.665,241.028z M399.447,392.599H71.193
		c-18.192,0-33.927-13.07-37.458-30.733h403.17C433.374,379.529,417.639,392.599,399.447,392.599z M341.352,314.583l9.149,29.284
		h-12.466l-9.149-29.284H341.352z M310.028,314.583l9.149,29.284H151.46l9.149-29.284H310.028z M132.602,343.866H120.14
		l9.149-29.284h12.462L132.602,343.866z M101.282,343.866H31.114l-4.154-29.284h83.47L101.282,343.866z M369.358,343.866
		l-9.149-29.284h83.47l-4.154,29.284H369.358z M424.315,214.505c0-3.193,2.598-5.791,5.791-5.791h16.744
		c3.193,0,5.791,2.598,5.791,5.791c0,2.927-2.191,5.398-5.096,5.749l-23.229,2.806V214.505z M354.719,145.132
		c-5.558-0.963-13.028-1.817-22.324-2.552c0.595-2.588,1.084-5.216,1.473-7.875c0.008,0.002,0.018,0.004,0.026,0.006
		C341.622,136.48,348.747,140.117,354.719,145.132z M235.32,38.651c44.994,0,81.6,36.605,81.6,81.599s-36.605,81.6-81.6,81.6
		s-81.6-36.605-81.6-81.6S190.326,38.651,235.32,38.651z M136.739,134.71c0.011-0.002,0.023-0.005,0.034-0.007
		c0.388,2.659,0.877,5.286,1.472,7.873c-9.321,0.741-16.792,1.597-22.326,2.557C121.89,140.118,129.014,136.481,136.739,134.71z
		 M99.586,169.222c5.565-2.82,11.585-4.865,17.942-6.088c5.711-1.102,14.823-2.108,26.561-2.946
		c15.422,35.092,50.508,59.661,91.231,59.661c40.721,0,75.806-24.568,91.229-59.656c11.71,0.829,20.827,1.834,26.574,2.941
		c6.349,1.221,12.369,3.269,17.94,6.093l27.814,60.879H71.77L99.586,169.222z M18,214.505c0-3.193,2.598-5.791,5.791-5.791h16.744
		c3.193,0,5.791,2.598,5.791,5.791v8.555l-23.229-2.806C20.191,219.903,18,217.432,18,214.505z M63.351,248.107h343.938
		l28.638,28.637c5.349,5.35,8.619,12.422,9.36,19.838H25.353c0.741-7.416,4.011-14.489,9.36-19.838L63.351,248.107z
		 M116.819,422.829c0,5.051-4.109,9.16-9.16,9.16H62.62c-5.051,0-9.16-4.109-9.16-9.16V407.69
		c5.604,1.886,11.573,2.909,17.734,2.909h45.625V422.829z M417.177,422.829c0,5.051-4.109,9.16-9.16,9.16h-45.028
		c-5.051,0-9.16-4.109-9.16-9.16v-12.229h45.619c6.16,0,12.127-1.022,17.73-2.907V422.829z"
      />
      <path
        d="M220.22,173.022c1.604,2.852,4.614,4.588,7.842,4.588c0.292,0,0.585-0.014,0.879-0.043c3.551-0.347,6.562-2.76,7.674-6.149
		l32.999-100.495c1.551-4.722-1.021-9.808-5.743-11.358c-4.721-1.551-9.808,1.021-11.358,5.743l-26.623,81.078L208.91,116.2
		c-2.437-4.332-7.925-5.869-12.256-3.432c-4.333,2.437-5.869,7.924-3.432,12.257L220.22,173.022z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>